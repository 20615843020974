import React, { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';
import { SubmissionDetails } from '../SubmissionDetails';
import { StatusBadge } from './StatusBadge';
import { StatusSelect } from './StatusSelect';
import { Copy, ExternalLink, Eye, Trash2 } from 'lucide-react';
import { prepareLeadExport } from '../../../lib/utils/leadExport';
import { formatDate } from '../../../utils/date';

interface Submission {
  id: string;
  created_at: string;
  path_type: 'existing' | 'new';
  contact_info: {
    name: string;
    email: string;
    phone: string;
    country: string;
    contactMethod: string;
  };
  status: string;
  status_history: Array<{
    status: string;
    timestamp: string;
  }>;
  answers: Array<{
    stepId: string;
    answer: any;
  }>;
}

export function SubmissionsList() {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubmission, setSelectedSubmission] = useState<Submission | null>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSubmissions();
  }, []);

  async function fetchSubmissions() {
    try {
      setLoading(true);
      setError(null);
      const { data, error } = await supabase
        .from('funnel_submissions')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSubmissions(data || []);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setError('Failed to load submissions. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  }

  const handleCopy = async (submission: Submission) => {
    try {
      const exportData = prepareLeadExport(submission);
      await navigator.clipboard.writeText(exportData);
      setCopiedId(submission.id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this submission?')) return;

    try {
      const { error } = await supabase
        .from('funnel_submissions')
        .delete()
        .eq('id', id);

      if (error) throw error;
      
      setSubmissions(prev => prev.filter(sub => sub.id !== id));
    } catch (error) {
      console.error('Error deleting submission:', error);
      alert('Failed to delete submission');
    }
  };

  const getInstagramUsername = (submission: Submission) => {
    const socialAnswer = submission.answers.find(answer => answer.stepId === 'social_media');
    if (socialAnswer && typeof socialAnswer.answer === 'object') {
      const instagram = socialAnswer.answer.instagram;
      if (instagram) {
        return instagram.startsWith('@') ? instagram.slice(1) : instagram;
      }
    }
    return null;
  };

  const getLatestStatusChange = (submission: Submission) => {
    if (!submission.status_history?.length) return null;
    return submission.status_history[submission.status_history.length - 1];
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="animate-pulse space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-200 rounded" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-700">{error}</p>
        <button
          onClick={fetchSubmissions}
          className="mt-2 text-red-600 hover:text-red-800 underline"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <div className="overflow-x-auto touch-pan-x">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Date
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Name
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Instagram
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Last Status Change
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {submissions.map((submission) => {
              const instagramUsername = getInstagramUsername(submission);
              const latestStatusChange = getLatestStatusChange(submission);
              
              return (
                <tr key={submission.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(submission.created_at)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {submission.contact_info.name}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <StatusBadge status={submission.path_type === 'existing' ? 'Existing Creator' : 'New Creator'} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {instagramUsername && (
                      <a
                        href={`https://instagram.com/${instagramUsername}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-indigo-600 hover:text-indigo-900"
                      >
                        @{instagramUsername}
                        <ExternalLink className="w-4 h-4 ml-1" />
                      </a>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <StatusSelect
                      id={submission.id}
                      currentStatus={submission.status}
                      onStatusChange={(newStatus) => {
                        setSubmissions(prev =>
                          prev.map(sub =>
                            sub.id === submission.id
                              ? { 
                                  ...sub, 
                                  status: newStatus,
                                  status_history: [
                                    ...(sub.status_history || []),
                                    { status: newStatus, timestamp: new Date().toISOString() }
                                  ]
                                }
                              : sub
                          )
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {latestStatusChange ? formatDate(latestStatusChange.timestamp) : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => setSelectedSubmission(submission)}
                        className="text-indigo-600 hover:text-indigo-900"
                        title="View Details"
                      >
                        <Eye className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleCopy(submission)}
                        className={`${
                          copiedId === submission.id
                            ? 'text-green-600'
                            : 'text-gray-600 hover:text-gray-900'
                        }`}
                        title="Copy Lead Data"
                      >
                        <Copy className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(submission.id)}
                        className="text-red-600 hover:text-red-900"
                        title="Delete Lead"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedSubmission && (
        <SubmissionDetails
          submission={selectedSubmission}
          onClose={() => setSelectedSubmission(null)}
        />
      )}
    </div>
  );
}