import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../lib/supabase';
import { useLanguage } from '../../contexts/LanguageContext';

export function AboutMission() {
  const { language } = useLanguage();
  const { data: translations } = useQuery({
    queryKey: ['translations', 'about', language],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('translations')
        .select('*')
        .eq('section', 'about');
      
      if (error) throw error;
      return data || [];
    }
  });

  const getText = (key: string) => {
    const translation = translations?.find(t => t.key === `mission.${key}`);
    return translation?.[language] || '';
  };

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto space-y-12">
          {/* Challenge */}
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-8 border border-white/20">
            <h3 className="text-xl font-bold text-white mb-4">The Challenge</h3>
            <p className="text-white/80 leading-relaxed" data-translation="mission.challenge">
              {getText('challenge')}
            </p>
          </div>

          {/* Experience */}
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-8 border border-white/20">
            <h3 className="text-xl font-bold text-white mb-4">Our Experience</h3>
            <p className="text-white/80 leading-relaxed" data-translation="mission.experience">
              {getText('experience')}
            </p>
          </div>

          {/* Solution */}
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-8 border border-white/20">
            <h3 className="text-xl font-bold text-white mb-4">The Solution</h3>
            <p className="text-white/80 leading-relaxed" data-translation="mission.solution">
              {getText('solution')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}