import React from 'react';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';
import type { BlogPost } from './types';
import { useLanguage } from '../../contexts/LanguageContext';

export function BlogCard({ 
  slug, 
  title, 
  excerpt, 
  image_url, 
  created_at,
  read_time,
  category 
}: BlogPost) {
  const { language } = useLanguage();
  
  const date = new Date(created_at).toLocaleDateString(language === 'de' ? 'de-DE' : 'en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // Default image if none provided
  const defaultImage = 'https://images.unsplash.com/photo-1516387938699-a93567ec168e?auto=format&fit=crop&q=80';

  return (
    <Link 
      to={`${language === 'de' ? '/de' : ''}/blog/${slug}`}
      className="group block bg-white/10 backdrop-blur-md rounded-2xl border border-white/20 overflow-hidden hover:bg-white/20 transition-all"
    >
      <div className="grid md:grid-cols-3 gap-6">
        <div className="relative h-48 md:h-full">
          <img 
            src={image_url || defaultImage} 
            alt={title}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
            loading="eager"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = defaultImage;
            }}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
        </div>
        
        <div className="p-6 md:col-span-2">
          <h2 className="text-2xl font-bold text-white mb-3 group-hover:text-[#FFC0CB] transition-colors">
            {title}
          </h2>
          <p className="text-white/80 mb-4 line-clamp-2">{excerpt}</p>
          
          <div className="flex items-center space-x-4 text-white/60">
            <span>{date}</span>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              <span>{read_time} {language === 'de' ? 'Min. Lesezeit' : 'min read'}</span>
            </div>
            {category && (
              <>
                <span>•</span>
                <span>{category}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}