import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, X } from 'lucide-react';
import { IMAGES } from '../../constants/images';
import { useTranslation } from '../../hooks/useTranslation';
import { useLanguage } from '../../contexts/LanguageContext';
import { getLanguagePath } from '../../lib/utils/languageUtils';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onHomeClick: () => void;
  onSuccessStoriesClick: () => void;
}

export function MobileMenu({ 
  isOpen, 
  onClose, 
  onHomeClick,
  onSuccessStoriesClick 
}: MobileMenuProps) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const menuRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef<number>(0);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchCurrentX = e.touches[0].clientX;
    const diff = touchStartX.current - touchCurrentX;
    if (diff > 100) onClose();
  };

  const handleHomeClick = () => {
    onHomeClick();
    onClose();
  };

  const handleSuccessStoriesClick = () => {
    onSuccessStoriesClick();
    onClose();
  };

  return (
    <div 
      className={`fixed inset-0 z-50 md:hidden transition-all duration-400 ease-in-out ${
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
      aria-hidden={!isOpen}
      onClick={onClose}
    >
      {/* Backdrop with increased blur */}
      <div 
        className={`absolute inset-0 bg-black/30 backdrop-blur-[6px] transition-opacity duration-400 ease-in-out ${
          isOpen ? 'opacity-100' : 'opacity-0'
        }`} 
      />

      {/* Menu Panel with enhanced styling */}
      <div
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}
        className={`absolute inset-y-0 right-0 w-full max-w-sm transition-transform duration-400 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{
          background: 'linear-gradient(135deg, rgba(0, 51, 102, 0.85) 0%, rgba(255, 192, 203, 0.85) 100%)',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)',
          boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.2)'
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {/* Header with Logo and Close Button */}
        <div className="flex items-center justify-between px-6 py-4 border-b border-white/10">
          <img 
            src={IMAGES.logo} 
            alt="MatchMates Logo"
            className="h-8 w-auto"
          />
          <button
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center text-white hover:bg-white/10 rounded-lg transition-all duration-300"
            aria-label="Close menu"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Navigation Links */}
        <nav className="flex-1 px-6 py-6">
          <div className="space-y-3">
            {/* Home Button */}
            <button
              onClick={handleHomeClick}
              className="w-full text-left px-4 py-3 text-[16px] font-[500] text-white hover:bg-white/10 rounded-lg transition-all duration-300"
            >
              Home
            </button>

            {/* Regular Links */}
            <Link
              to={`${getLanguagePath(language)}/agencies`}
              className="block px-4 py-3 text-[16px] font-[500] text-white hover:bg-white/10 rounded-lg transition-all duration-300"
              onClick={onClose}
            >
              {t.nav.forAgencies}
            </Link>

            {/* Success Stories Button */}
            <button
              onClick={handleSuccessStoriesClick}
              className="w-full text-left px-4 py-3 text-[16px] font-[500] text-white hover:bg-white/10 rounded-lg transition-all duration-300"
            >
              {t.nav.successStories}
            </button>

            <Link
              to={`${getLanguagePath(language)}/about`}
              className="block px-4 py-3 text-[16px] font-[500] text-white hover:bg-white/10 rounded-lg transition-all duration-300"
              onClick={onClose}
            >
              {t.nav.aboutUs}
            </Link>
          </div>
        </nav>

        {/* Fixed CTA Button at bottom */}
        <div className="fixed bottom-6 left-1/2 -translate-x-1/2 w-[90%] px-6">
          <Link
            to={`${getLanguagePath(language)}/creator-quiz`}
            onClick={onClose}
            className="flex items-center justify-center w-full px-6 py-4 text-[16px] font-[600] text-white rounded-xl bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] hover:from-[#FFB0BE] hover:to-[#FF96AB] transition-all duration-300 shadow-[0px_4px_10px_rgba(255,255,255,0.3)]"
          >
            <span>Get Matched Today</span>
            <ArrowRight className="w-5 h-5 ml-2" />
          </Link>
        </div>
      </div>
    </div>
  );
}