import React from 'react';
import { ArrowRight } from 'lucide-react';

interface FunnelIntroProps {
  onSelect: (path: 'existing' | 'new') => void;
}

export function FunnelIntro({ onSelect }: FunnelIntroProps) {
  return (
    <div className="max-w-2xl mx-auto text-center">
      <h1 className="text-4xl font-bold text-white mb-4">Let's Get Started</h1>
      <p className="text-xl text-white/90 mb-12">
        Find the right agency for you in only 2 minutes!
      </p>

      <div className="space-y-4">
        <button
          onClick={() => onSelect('existing')}
          className="w-full p-6 bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-2xl border border-white/20 transition-all group"
        >
          <div className="flex items-center justify-between">
            <div className="text-left">
              <h3 className="text-xl font-semibold text-white mb-2">
                I'm already a creator and earning income
              </h3>
              <p className="text-white/80">
                Let's find an agency that can help you grow further
              </p>
            </div>
            <ArrowRight className="w-6 h-6 text-white group-hover:translate-x-2 transition-transform" />
          </div>
        </button>

        <button
          onClick={() => onSelect('new')}
          className="w-full p-6 bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-2xl border border-white/20 transition-all group"
        >
          <div className="flex items-center justify-between">
            <div className="text-left">
              <h3 className="text-xl font-semibold text-white mb-2">
                I don't have an OnlyFans account yet
              </h3>
              <p className="text-white/80">
                We'll guide you through getting started successfully
              </p>
            </div>
            <ArrowRight className="w-6 h-6 text-white group-hover:translate-x-2 transition-transform" />
          </div>
        </button>
      </div>
    </div>
  );
}