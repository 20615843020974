import { useEffect, useRef } from 'react';

export function useProcessAnimation() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const container = entry.target;
            
            // Mobile animations
            const steps = container.querySelectorAll('[data-process-step]');
            steps.forEach((step, index) => {
              setTimeout(() => {
                (step as HTMLElement).style.opacity = '1';
                (step as HTMLElement).style.transform = 'translateY(0) scale(1)';
              }, index * 150);
            });

            // Desktop animations
            const stepContainers = container.querySelectorAll('[data-step-container]');
            stepContainers.forEach((step, index) => {
              setTimeout(() => {
                (step as HTMLElement).style.opacity = '1';
                (step as HTMLElement).style.transform = 'translateY(0) scale(1)';
              }, index * 150);
            });
          }
        });
      },
      {
        threshold: 0.1, // Start when 10% is visible
        rootMargin: '0px',
      }
    );

    // Scroll progress animation
    const handleScroll = () => {
      if (!containerRef.current) return;

      const progressLine = containerRef.current.querySelector('[data-progress-line]') as HTMLElement;
      if (!progressLine) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const containerTop = containerRect.top;
      const containerHeight = containerRect.height;

      // Start animation as soon as the container is visible
      if (containerTop < windowHeight) {
        const steps = containerRef.current.querySelectorAll('[data-step-container]');
        const stepCount = steps.length;
        
        // Calculate progress based on container position relative to viewport center
        const viewportCenter = windowHeight / 2;
        const containerCenter = containerTop + (containerHeight / 2);
        const distanceToCenter = viewportCenter - containerCenter;
        const totalDistance = windowHeight / 2 + containerHeight / 2;
        
        // Calculate progress percentage
        let progress = (distanceToCenter + totalDistance) / (totalDistance * 2) * 100;
        
        // Ensure progress stays within bounds
        progress = Math.max(0, Math.min(100, progress));
        
        // Update progress line width
        progressLine.style.width = `${progress}%`;
      } else {
        progressLine.style.width = '0%';
      }
    };

    if (containerRef.current) {
      observer.observe(containerRef.current);
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial calculation
    }

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { containerRef };
}