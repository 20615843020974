import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../lib/supabase';

export function PageViewStats() {
  const { data: pageViews, isLoading } = useQuery({
    queryKey: ['page-views'],
    queryFn: async () => {
      // Get unique users (sessions)
      const { count: uniqueUsers, error: usersError } = await supabase
        .from('user_sessions')
        .select('*', { count: 'exact', head: true });

      if (usersError) throw usersError;

      // Get unique users per page
      const { data: usersByPage, error: pageError } = await supabase
        .from('page_views')
        .select('path, session_id')
        .select('path')
        .select('count(distinct session_id)')
        .not('path', 'ilike', '/blog%')
        .group('path')
        .order('count', { ascending: false });

      if (pageError) throw pageError;

      // Get total page views (excluding blog)
      const { count: totalViews, error: viewsError } = await supabase
        .from('page_views')
        .select('*', { count: 'exact', head: true })
        .not('path', 'ilike', '/blog%');

      if (viewsError) throw viewsError;

      return {
        uniqueUsers: uniqueUsers || 0,
        totalViews: totalViews || 0,
        usersByPage: usersByPage || []
      };
    }
  });

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-6 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-semibold mb-4">Seitenaufrufe</h3>
      
      <div className="flex justify-between items-center mb-6">
        <div>
          <p className="text-sm text-gray-500">Unique Users</p>
          <p className="text-2xl font-bold text-indigo-600">{pageViews?.uniqueUsers || 0}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Gesamt Aufrufe</p>
          <p className="text-2xl font-bold text-indigo-600">{pageViews?.totalViews || 0}</p>
        </div>
      </div>
      
      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Seite
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Unique Users
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pageViews?.usersByPage.map((view: any, index: number) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {view.path}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {view.count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}