import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface ProcessStepProps {
  number: number;
  icon: LucideIcon;
  title: string;
  description: string;
  isMobile?: boolean;
}

export function ProcessStep({ number, icon: Icon, title, description, isMobile }: ProcessStepProps) {
  if (isMobile) {
    return (
      <div className="relative bg-white rounded-xl p-6 border border-white/20 overflow-visible group transition-all duration-500 hover:bg-gray-50 h-full">
        {/* Step number with pulsing effect - now smaller for mobile */}
        <div className="absolute -top-4 -right-4 w-8 h-8 bg-[#FFC0CB] rounded-full flex items-center justify-center transform rotate-12 group-hover:scale-110 transition-transform">
          <div className="absolute inset-0 bg-[#FFC0CB] rounded-full animate-pulse opacity-50" />
          <span className="text-sm font-bold text-white transform -rotate-12 relative z-10">{number}</span>
        </div>

        {/* Content with hover animations */}
        <div className="relative z-10">
          <div className="flex items-center space-x-4 mb-4">
            <div className="bg-[#FFC0CB] p-3 rounded-lg transition-all duration-500">
              <Icon className="w-6 h-6 text-white transform group-hover:scale-110 transition-transform duration-500" />
            </div>
            <h3 className="text-xl font-semibold text-[#1E3A8A] group-hover:translate-x-1 transition-transform duration-500">{title}</h3>
          </div>
          <p className="text-gray-900 text-sm leading-relaxed pl-14 transform group-hover:translate-x-2 transition-all duration-500 delay-100">{description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative bg-white rounded-xl p-6 border border-white/20 overflow-visible group transition-all duration-500 hover:bg-gray-50 h-full">
      {/* Number circle */}
      <div className="absolute -top-4 -right-4 w-12 h-12 bg-[#FFC0CB] rounded-full flex items-center justify-center shadow-lg z-10">
        <span className="text-lg font-bold text-white">{number}</span>
      </div>
      
      <div className="flex flex-col items-center text-center space-y-4 h-full">
        <div className="bg-[#FFC0CB] p-4 rounded-full transition-transform group-hover:scale-110">
          <Icon className="w-8 h-8 text-white" />
        </div>
        
        <h3 className="text-lg font-semibold text-[#1E3A8A]">{title}</h3>
        <p className="text-gray-900 text-sm leading-relaxed flex-grow">{description}</p>
      </div>
    </div>
  );
}