import type { FunnelSubmission } from '../../types/funnel';

function formatSocialLink(platform: string, username: string): string {
  if (!username) return '';
  
  // Remove @ if present
  const cleanUsername = username.startsWith('@') ? username.slice(1) : username;
  
  switch (platform.toLowerCase()) {
    case 'instagram':
      return `https://instagram.com/${cleanUsername}`;
    case 'onlyfans':
      return `https://onlyfans.com/${cleanUsername}`;
    default:
      return username;
  }
}

function formatAnswer(value: any): string {
  // Handle non-string values
  if (typeof value !== 'string') {
    if (value === null || value === undefined) return '';
    return String(value);
  }

  // Format income ranges
  switch (value) {
    case 'under_1k':
      return 'Under €1,000';
    case '1k_5k':
      return '€1,000 - €5,000';
    case '5k_15k':
      return '€5,000 - €15,000';
    case '5k_20k':
      return '€5,000 - €20,000';
    case '15k_50k':
      return '€15,000 - €50,000';
    case 'over_20k':
      return 'Over €20,000';
    case 'over_50k':
      return 'Over €50,000';
  }

  // Format time ranges
  switch (value) {
    case 'under_1h':
      return 'Less than 1 hour';
    case '1_2h':
      return '1-2 hours';
    case '3_5h':
      return '3-5 hours';
    case 'over_5h':
      return 'Over 5 hours';
  }

  // Format agency experience
  switch (value) {
    case 'self_managed':
      return 'Self-managed';
    case 'unsatisfied':
      return 'Currently with agency (unsatisfied)';
    case 'satisfied':
      return 'Currently with agency (satisfied)';
  }

  // Format other common values
  return value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}

function getQuestionLabel(stepId: string): string {
  switch (stepId) {
    case 'current_income':
      return 'Current Monthly Income';
    case 'time_spent':
      return 'Daily Time Investment';
    case 'agency_experience':
      return 'Agency Experience';
    case 'motivation':
      return 'Main Motivation';
    case 'time_commitment':
      return 'Available Time';
    case 'social_presence':
      return 'Social Media Presence';
    case 'agency_goals':
      return 'Agency Goals';
    case 'agency_expectations':
      return 'Agency Expectations';
    case 'anonymity':
      return 'Anonymity Preference';
    case 'content_boundaries':
      return 'Content Boundaries';
    case 'income_goals':
      return 'Income Goals';
    default:
      return stepId.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  }
}

export function prepareLeadExport(submission: FunnelSubmission): string {
  const lines: string[] = [];
  const socialLinks: string[] = [];
  const answers: string[] = [];
  
  // Basic Info
  lines.push(`Lead Information (${new Date(submission.created_at || '').toLocaleDateString()})`);
  lines.push('----------------------------------------');
  lines.push(`Path Type: ${submission.pathType === 'existing' ? 'Existing Creator' : 'New Creator'}`);
  
  // Process answers and collect social media links
  submission.answers.forEach(answer => {
    if (answer.stepId === 'social_media') {
      const socialAnswers = answer.answer as Record<string, string>;
      Object.entries(socialAnswers).forEach(([platform, username]) => {
        if (username) {
          socialLinks.push(`${platform.charAt(0).toUpperCase() + platform.slice(1)}: ${formatSocialLink(platform, username)}`);
        }
      });
    } else if (answer.stepId !== 'contact') {
      const questionLabel = getQuestionLabel(answer.stepId);
      const answerValue = Array.isArray(answer.answer) 
        ? answer.answer.map(formatAnswer).join(', ') 
        : formatAnswer(answer.answer);
      
      answers.push(`${questionLabel}: ${answerValue}`);
    }
  });

  // Add social links right after path type
  lines.push(...socialLinks);
  lines.push('');

  // Add Questionnaire Responses
  lines.push('Questionnaire Responses');
  lines.push('----------------------------------------');
  lines.push(...answers);

  return lines.join('\n');
}