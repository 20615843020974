import { supabase } from './supabase';
import type { FunnelSubmission } from '../types/funnel';

export async function submitFunnelData(data: FunnelSubmission): Promise<boolean> {
  try {
    console.log('Submitting funnel data:', data); // Debug log

    const { data: result, error } = await supabase
      .from('funnel_submissions')
      .insert([{
        path_type: data.pathType,
        answers: data.answers,
        contact_info: data.contactInfo,
        status: 'pending',
        processed: false
      }])
      .select();

    if (error) {
      console.error('Supabase error:', error); // Debug log
      throw error;
    }

    console.log('Submission successful:', result); // Debug log
    return true;
  } catch (error) {
    console.error('Error submitting funnel data:', error);
    return false;
  }
}