import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { BlogPosts } from '../components/blog/BlogPosts';
import { PopularPosts } from '../components/blog/PopularPosts';
import { backgrounds } from '../constants/backgrounds';
import { useTranslation } from '../hooks/useTranslation';

export default function BlogPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t.blog.title} | MatchMates</title>
        <meta name="description" content={t.blog.subtitle} />
        
        {/* Open Graph */}
        <meta property="og:title" content={`${t.blog.title} | MatchMates`} />
        <meta property="og:description" content={t.blog.subtitle} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="MatchMates Blog" />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        <main className={`${backgrounds.hero} flex-grow pt-32 pb-16`}>
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <h1 className="text-4xl font-bold text-white mb-4 text-center">
                {t.blog.title}
              </h1>
              <p className="text-white/80 text-lg mb-12 text-center">
                {t.blog.subtitle}
              </p>
              <PopularPosts />
              <BlogPosts />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}