import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Configure Supabase client with optimizations
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
    storage: window.localStorage,
    storageKey: 'matchmates-admin-auth'
  },
  // Add mobile-specific options
  headers: {
    'X-Client-Info': 'matchmates-web',
    'X-Client-Platform': /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
  },
  // Enable response compression
  shouldThrowOnError: false,
  // Retry configuration
  retryAttempts: 5,
  retryInterval: 1000, // Start with 1s delay
  retryIntervalRange: [1000, 5000], // Max 5s delay
});

// Enhanced error handling wrapper
export async function handleSupabaseError<T>(
  promise: Promise<{ data: T | null; error: any }>,
  retryCount = 0
): Promise<T> {
  try {
    const { data, error } = await promise;
    
    if (error) {
      // Retry on network errors
      if (error.code === 'NETWORK_ERROR' && retryCount < 5) {
        await new Promise(resolve => setTimeout(resolve, Math.min(1000 * (retryCount + 1), 5000)));
        return handleSupabaseError(promise, retryCount + 1);
      }
      
      console.error('Supabase operation failed:', error);
      throw new Error(error.message || 'Database operation failed');
    }
    
    if (!data) {
      throw new Error('No data returned');
    }
    
    return data;
  } catch (error: any) {
    const message = error.message || 'An unknown error occurred';
    const details = error.details ? `: ${error.details}` : '';
    throw new Error(`${message}${details}`);
  }
}

// Initialize auth state
export async function initializeAuth() {
  try {
    // Use cached session first
    const cachedSession = localStorage.getItem('sb-auth-token');
    if (cachedSession) {
      return true;
    }

    const { data: { session } } = await supabase.auth.getSession();
    return !!session;
  } catch (error) {
    console.error('Auth initialization failed:', error);
    return false;
  }
}