import React from 'react';
import { Heart, Lightbulb, Shield, TrendingUp } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

export function AboutValues() {
  const { t } = useTranslation();
  
  const values = [
    { key: 'trust', icon: Heart },
    { key: 'security', icon: Shield },
    { key: 'growth', icon: TrendingUp },
    { key: 'innovation', icon: Lightbulb }
  ];

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-4">{t.about.values.title}</h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.about.values.subtitle}
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {values.map((value, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-md rounded-xl p-8 border border-white/20">
              <value.icon className="w-12 h-12 text-white mb-6" />
              <h3 className="text-xl font-bold text-white mb-4">{t.about.values.list[value.key].title}</h3>
              <p className="text-white/80">{t.about.values.list[value.key].description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}