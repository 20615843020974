import React from 'react';
import { FormField } from './FormField';
import type { SEOSettings } from './types';

interface GeneralSEOSectionProps {
  settings: SEOSettings;
  onChange: (field: keyof SEOSettings, value: string) => void;
}

export function GeneralSEOSection({ settings, onChange }: GeneralSEOSectionProps) {
  return (
    <section className="space-y-4">
      <h3 className="text-lg font-semibold">General SEO</h3>
      
      <FormField
        label="Page Title"
        value={settings.title}
        onChange={(value) => onChange('title', value)}
        placeholder="MatchMates - Connect Creators with Trusted Agencies"
      />

      <FormField
        label="Meta Description"
        value={settings.description}
        onChange={(value) => onChange('description', value)}
        placeholder="MatchMates connects creators with verified, professional agencies..."
        multiline
      />

      <FormField
        label="Keywords"
        value={settings.keywords}
        onChange={(value) => onChange('keywords', value)}
        placeholder="creator agency, onlyfans management, content creator support"
        helperText="Separate keywords with commas"
      />
    </section>
  );
}