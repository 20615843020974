import { useEffect, useRef } from 'react';

export function useComparisonAnimation() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const container = entry.target;
          const negativeEl = container.querySelector('[data-comparison="negative"]');
          const positiveEl = container.querySelector('[data-comparison="positive"]');

          if (negativeEl && positiveEl) {
            if (entry.isIntersecting) {
              // Move negative (blue) card further back and to the left
              negativeEl.classList.add('-translate-z-24', '-translate-x-8', 'scale-95');
              negativeEl.classList.remove('translate-z-0', 'translate-x-0', 'scale-100');
              
              // Bring positive (right) card more forward and scale up more
              positiveEl.classList.add('translate-z-12', 'scale-105', '-translate-x-4', 'z-20');
              positiveEl.classList.remove('translate-z-0', 'scale-100', 'translate-x-0', 'z-10');
            } else {
              // Reset all transformations when out of view
              negativeEl.classList.remove('-translate-z-24', '-translate-x-8', 'scale-95');
              negativeEl.classList.add('translate-z-0', 'translate-x-0', 'scale-100');
              
              positiveEl.classList.remove('translate-z-12', 'scale-105', '-translate-x-4', 'z-20');
              positiveEl.classList.add('translate-z-0', 'scale-100', 'translate-x-0', 'z-10');
            }
          }
        });
      },
      {
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1], // Multiple thresholds for smoother animation
        rootMargin: '-50px', // Trigger slightly earlier
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return { containerRef };
}