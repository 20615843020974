import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../Header';
import Footer from '../Footer';
import { motion } from 'framer-motion';
import { backgrounds } from '../../constants/backgrounds';

interface LegalTemplateProps {
  title: string;
  content: string;
}

export function LegalTemplate({ title, content }: LegalTemplateProps) {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{title} | MatchMates</title>
      </Helmet>

      <Header />
      
      <main className={`${backgrounds.hero} flex-grow pt-32 pb-16`}>
        <div className="container mx-auto px-4">
          <motion.article 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-4xl mx-auto bg-white/10 backdrop-blur-md rounded-2xl shadow-xl overflow-hidden"
          >
            {/* Content wrapper */}
            <div className="p-8 md:p-12">
              {/* Article header */}
              <header className="mb-12 text-center">
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight">
                  {title}
                </h1>
                <div className="w-24 h-1 bg-gradient-to-r from-[#003366] to-[#FFC0CB] mx-auto rounded-full" />
              </header>

              {/* Article content */}
              <div 
                className="prose prose-lg prose-invert max-w-none"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </motion.article>
        </div>
      </main>

      <Footer />
    </div>
  );
}