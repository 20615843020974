import { useQuery } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';

interface UseAnalyticsDataProps {
  timeRange: 'day' | 'week' | 'year';
}

export function useAnalyticsData({ timeRange }: UseAnalyticsDataProps) {
  const getTimeFilter = () => {
    const now = new Date();
    switch (timeRange) {
      case 'day':
        return new Date(now.setDate(now.getDate() - 1));
      case 'week':
        return new Date(now.setDate(now.getDate() - 7));
      case 'year':
        return new Date(now.setFullYear(now.getFullYear() - 1));
    }
  };

  return useQuery({
    queryKey: ['analytics', timeRange],
    queryFn: async () => {
      const startTime = getTimeFilter();

      const [sessions, pageViews, interactions] = await Promise.all([
        supabase
          .from('user_sessions')
          .select('*')
          .gte('start_time', startTime.toISOString())
          .order('start_time', { ascending: true }),
        
        supabase
          .from('page_views')
          .select('*')
          .gte('timestamp', startTime.toISOString())
          .order('timestamp', { ascending: true }),
        
        supabase
          .from('user_interactions')
          .select('*')
          .gte('timestamp', startTime.toISOString())
          .order('timestamp', { ascending: true })
      ]);

      return {
        sessions: sessions.data || [],
        pageViews: pageViews.data || [],
        interactions: interactions.data || []
      };
    }
  });
}