import React from 'react';
import { ArrowRight } from 'lucide-react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  icon?: boolean;
  variant?: 'primary' | 'secondary';
  className?: string;
  ariaLabel?: string;
  disabled?: boolean;
}

export default function Button({ 
  children, 
  onClick, 
  icon = false, 
  variant = 'primary',
  className = '',
  ariaLabel,
  disabled = false
}: ButtonProps) {
  const baseStyles = "inline-flex items-center justify-center font-semibold rounded-xl transition-all";
  const variants = {
    primary: "bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] text-white hover:from-[#FFB0BE] hover:to-[#FF96AB]",
    secondary: "bg-white/10 backdrop-blur-md border border-white/20 text-white hover:bg-white/20"
  };

  return (
    <button 
      onClick={onClick}
      className={`${baseStyles} ${variants[variant]} ${className}`}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      <span>{children}</span>
      {icon && (
        <ArrowRight 
          className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" 
          aria-hidden="true"
        />
      )}
    </button>
  );
}