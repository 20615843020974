import React from 'react';
import { qualificationData } from './agencyData';
import { QualificationCard } from './QualificationCard';
import { useTranslation } from '../../hooks/useTranslation';

export function AgencyQualifications() {
  const { t } = useTranslation();

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-2 rounded-full text-white border border-[#FFC0CB] text-sm font-medium mb-4">
            {t.agencies.requirements.badge}
          </span>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.agencies.requirements.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.agencies.requirements.subtitle}
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {qualificationData.map((qualification, index) => (
            <QualificationCard 
              key={index} 
              icon={qualification.icon}
              title={t.agencies.requirements.items[qualification.key].title}
              description={t.agencies.requirements.items[qualification.key].description}
              requirement={t.agencies.requirements.items[qualification.key].requirement}
              highlight={qualification.highlight}
            />
          ))}
        </div>
      </div>
    </section>
  );
}