import { existingCreatorPath, newCreatorPath, commonFinalSteps } from '../components/funnel/data/funnelPaths';

const getAllQuestions = () => {
  const questions = new Map<string, { title: string; question: string }>();

  // Add existing creator path questions
  existingCreatorPath.steps.forEach(step => {
    questions.set(step.id, {
      title: step.title,
      question: step.question
    });
  });

  // Add new creator path questions
  newCreatorPath.steps.forEach(step => {
    questions.set(step.id, {
      title: step.title,
      question: step.question
    });
  });

  // Add common final steps
  commonFinalSteps.steps.forEach(step => {
    questions.set(step.id, {
      title: step.title,
      question: step.question
    });
  });

  return questions;
};

export const questionMap = getAllQuestions();