import React from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { SubmissionsList } from '../../components/admin/submissions/SubmissionsList';

export default function SubmissionsPage() {
  return (
    <AdminLayout>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-900">Creator Submissions</h2>
        <SubmissionsList />
      </div>
    </AdminLayout>
  );
}