import React, { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';
import { StatusSelect } from './StatusSelect';
import { StatusBadge } from './StatusBadge';
import { Copy, ExternalLink, Mail, Check } from 'lucide-react';

interface AgencySubmission {
  id: string;
  created_at: string;
  agency_name: string;
  contact_person: string;
  email: string;
  phone: string;
  description: string;
  status: 'pending' | 'contacted' | 'completed' | 'no_response' | 'bad_lead';
}

export function AgencySubmissionsList() {
  const [submissions, setSubmissions] = useState<AgencySubmission[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  useEffect(() => {
    fetchSubmissions();
  }, []);

  async function fetchSubmissions() {
    try {
      setLoading(true);
      setError(null);
      const { data, error } = await supabase
        .from('agency_submissions')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setSubmissions(data || []);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setError('Failed to load submissions. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  }

  const handleCopy = async (submission: AgencySubmission) => {
    const text = `
Agency Name: ${submission.agency_name}
Contact Person: ${submission.contact_person}
Description: ${submission.description}
Submitted: ${new Date(submission.created_at).toLocaleString()}
    `.trim();

    try {
      await navigator.clipboard.writeText(text);
      setCopiedId(submission.id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="animate-pulse space-y-4">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-16 bg-gray-200 rounded" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-700">{error}</p>
        <button
          onClick={fetchSubmissions}
          className="mt-2 text-red-600 hover:text-red-800 underline"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto" style={{ minWidth: '100%' }}>
        <div style={{ minWidth: '1024px' }}>
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-[#1E3A8A]/5">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#1E3A8A] uppercase tracking-wider w-[180px]">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#1E3A8A] uppercase tracking-wider w-[200px]">
                  Agency
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#1E3A8A] uppercase tracking-wider w-[200px]">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#1E3A8A] uppercase tracking-wider w-[180px]">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#1E3A8A] uppercase tracking-wider w-[120px]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {submissions.map((submission) => (
                <tr key={submission.id} className="hover:bg-[#1E3A8A]/5">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(submission.created_at).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-[#1E3A8A]">
                      {submission.agency_name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {submission.contact_person}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-[#1E3A8A]">
                      {submission.email}
                    </div>
                    <div className="text-sm text-gray-500">
                      {submission.phone}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <StatusSelect
                      id={submission.id}
                      currentStatus={submission.status}
                      onStatusChange={(newStatus) => {
                        setSubmissions(prev =>
                          prev.map(sub =>
                            sub.id === submission.id
                              ? { ...sub, status: newStatus }
                              : sub
                          )
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => handleCopy(submission)}
                        className={`${
                          copiedId === submission.id
                            ? 'text-green-600'
                            : 'text-gray-600 hover:text-gray-900'
                        }`}
                        title="Copy Details"
                      >
                        {copiedId === submission.id ? (
                          <Check className="w-5 h-5" />
                        ) : (
                          <Copy className="w-5 h-5" />
                        )}
                      </button>
                      <a
                        href={`mailto:${submission.email}`}
                        className="text-[#3BB8C3] hover:text-[#1E3A8A]"
                        title="Send Email"
                      >
                        <Mail className="w-5 h-5" />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}