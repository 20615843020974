import React, { useEffect, useRef, useState } from 'react';
import { reverseCarouselTestimonials } from './reverseCarouselData';
import { CarouselCard } from './CarouselCard';
import { useTranslation } from '../../hooks/useTranslation';

export function ReverseTestimonialCarousel() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;

  // Intersection Observer to pause when not visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer) return;

    const scroll = () => {
      if (!isPaused && isVisible) {
        if (scrollContainer.scrollLeft <= 0) {
          // Smooth reset to end
          scrollContainer.scrollTo({
            left: scrollContainer.scrollWidth - scrollContainer.clientWidth,
            behavior: 'instant'
          });
        } else {
          scrollContainer.scrollLeft -= 1;
        }
      }
    };

    // Slower interval for mobile
    const scrollInterval = isMobile ? 60 : 30;
    intervalRef.current = setInterval(scroll, scrollInterval);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPaused, isVisible, isMobile]);

  // Double the items to create a seamless loop
  const items = [...reverseCarouselTestimonials, ...reverseCarouselTestimonials];

  return (
    <div 
      ref={scrollRef}
      className="flex overflow-x-hidden relative"
      style={{ 
        WebkitMaskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)',
        maskImage: 'linear-gradient(to right, transparent, black 10%, black 90%, transparent)'
      }}
    >
      <div className="flex gap-4 py-4">
        {items.map((testimonial, index) => (
          <div 
            key={`${testimonial.name}-${index}`}
            className="w-[280px] flex-shrink-0"
          >
            <CarouselCard 
              {...testimonial} 
              onMouseEnter={() => setIsPaused(true)}
              onMouseLeave={() => setIsPaused(false)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}