import { useState, useCallback } from 'react';
import type { FunnelAnswer } from '../types/funnel';

interface UseFunnelNavigationProps {
  totalSteps: number;
  onComplete: (answers: FunnelAnswer[]) => Promise<void>;
}

export function useFunnelNavigation({ totalSteps, onComplete }: UseFunnelNavigationProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<FunnelAnswer[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAnswer = useCallback(async (answer: FunnelAnswer) => {
    const updatedAnswers = [...answers, answer];
    setAnswers(updatedAnswers);

    if (currentStep < totalSteps - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      setIsSubmitting(true);
      await onComplete(updatedAnswers);
      setIsSubmitting(false);
    }
  }, [currentStep, totalSteps, answers, onComplete]);

  const goBack = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
      setAnswers(prev => prev.slice(0, -1));
    }
  }, [currentStep]);

  return {
    currentStep,
    answers,
    isSubmitting,
    handleAnswer,
    goBack
  };
}