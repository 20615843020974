import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Process from './components/Process';
import { TestimonialCarousels } from './components/carousel/TestimonialCarousels';
import Comparison from './components/Comparison';
import { DetailedTestimonials } from './components/testimonials/DetailedTestimonials';
import { FAQSection } from './components/faq/FAQSection';
import Footer from './components/Footer';

export default function App() {
  const location = useLocation();

  // Handle scroll to section when navigating from other pages
  useEffect(() => {
    if (location.state?.scrollToSection) {
      const element = document.getElementById(location.state.scrollToSection);
      if (element) {
        // Use a small delay to ensure the page has rendered
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location.state]);

  return (
    <div className="flex flex-col min-h-screen" suppressHydrationWarning>
      <Header />
      
      <main className="flex-grow relative">
        {/* Hero section - Gradient and Image */}
        <section className="relative w-full h-screen max-h-[1080px] bg-gradient-to-br from-[#003366] to-[#FFC0CB]">
          <div className="absolute inset-0 w-full h-full">
            <img 
              src="https://imgur.com/JobLQUb.png" 
              alt=""
              className="w-full h-full object-cover opacity-25"
              loading="eager"
              style={{ objectPosition: 'center' }}
            />
            <div className="absolute inset-0 bg-gradient-to-br from-[#003366]/50 to-[#FFC0CB]/50" />
          </div>
          <div className="relative z-10">
            <Hero />
          </div>
        </section>

        {/* Features section - White */}
        <section className="relative w-full bg-white">
          <Features />
        </section>

        {/* Process section - Gradient */}
        <section className="relative w-full bg-gradient-to-br from-[#003366] to-[#FFC0CB]">
          <Process />
        </section>

        {/* Success Stories section - Gradient and Image */}
        <section id="success-stories" className="relative w-full">
          <div className="absolute inset-0 w-full h-full">
            <img 
              src="https://imgur.com/wPK4GUs.png" 
              alt=""
              className="w-full h-full object-cover opacity-25"
              loading="eager"
              style={{ objectPosition: 'center' }}
            />
            <div className="absolute inset-0 bg-gradient-to-br from-[#003366]/50 to-[#FFC0CB]/50" />
          </div>
          <div className="relative z-10">
            <TestimonialCarousels />
          </div>
        </section>

        {/* Comparison section - White */}
        <section className="relative w-full bg-white">
          <Comparison />
        </section>

        {/* Detailed Testimonials section - Gradient */}
        <section id="testimonials" className="relative w-full bg-gradient-to-br from-[#003366] to-[#FFC0CB]">
          <DetailedTestimonials />
        </section>

        {/* FAQ section - Gradient and Image */}
        <section className="relative w-full">
          <div className="absolute inset-0 w-full h-full">
            <img 
              src="https://imgur.com/QLln1h1.png" 
              alt=""
              className="w-full h-full object-cover opacity-25"
              loading="eager"
              style={{ objectPosition: 'center' }}
            />
            <div className="absolute inset-0 bg-gradient-to-br from-[#003366]/50 to-[#FFC0CB]/50" />
          </div>
          <div className="relative z-10">
            <FAQSection />
          </div>
        </section>
      </main>

      {/* Footer - Gradient */}
      <footer className="relative w-full bg-gradient-to-br from-[#003366] to-[#FFC0CB]">
        <Footer />
      </footer>
    </div>
  );
}