import React from 'react';
import { ArrowRight } from 'lucide-react';
import type { FunnelStep } from '../../../types/funnel';

interface ChoiceStepProps {
  step: FunnelStep;
  onSubmit: (answer: string | string[]) => void;
  isSubmitting?: boolean;
}

export function ChoiceStep({ step, onSubmit, isSubmitting }: ChoiceStepProps) {
  const [selected, setSelected] = React.useState<string[]>([]);

  const handleSelect = (value: string) => {
    if (!step.maxSelections || step.maxSelections === 1) {
      // For single selection, submit immediately
      onSubmit(value);
      return;
    }

    // For multiple selections
    const newSelected = selected.includes(value)
      ? selected.filter(v => v !== value)
      : [...selected, value];

    setSelected(newSelected);

    // Submit when max selections is reached
    if (newSelected.length === step.maxSelections) {
      onSubmit(newSelected);
    }
  };

  return (
    <div className="space-y-3">
      <p className="text-white text-sm md:text-base mb-4">{step.question}</p>
      
      {step.options?.map((option) => (
        <button
          key={option.value}
          onClick={() => handleSelect(option.value)}
          disabled={isSubmitting || (step.maxSelections && step.maxSelections > 1 && selected.length >= step.maxSelections && !selected.includes(option.value))}
          className={`group w-full text-left p-3 md:p-4 rounded-xl transition-all ${
            selected.includes(option.value)
              ? 'bg-[#FFC0CB] border-[#FFC0CB] shadow-[0_0_15px_rgba(255,192,203,0.3)]'
              : 'bg-white/5 border-white/10 hover:bg-white/10 hover:border-[#FFC0CB]/50 active:bg-white/15'
          } border text-white/90 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed text-sm md:text-base`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              {option.icon && <option.icon className="w-5 h-5 flex-shrink-0" />}
              <span>{option.label}</span>
            </div>
            <ArrowRight className="w-5 h-5 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-2 transition-all duration-300" />
          </div>
        </button>
      ))}
    </div>
  );
}