import React, { useState } from 'react';
import { TimeRangeSelector } from './TimeRangeSelector';
import { useAnalyticsData } from '../../../hooks/useAnalyticsData';
import { DailyVisitors } from './DailyVisitors';
import { PageViewStats } from './PageViewStats';
import { BlogStats } from './BlogStats';

export function AnalyticsOverview() {
  const [timeRange, setTimeRange] = useState<'day' | 'week' | 'month'>('week');
  const { data, isLoading } = useAnalyticsData({ timeRange });

  if (isLoading) {
    return <div>Loading analytics data...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900">Analytics Overview</h2>
        <TimeRangeSelector range={timeRange} onChange={setTimeRange} />
      </div>

      <div className="grid grid-cols-1 gap-6">
        <DailyVisitors data={data?.sessions} timeRange={timeRange} />
        <PageViewStats />
        <BlogStats />
      </div>
    </div>
  );
}