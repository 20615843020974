import React from 'react';
import { ArrowRight } from 'lucide-react';
import type { FunnelStep } from '../../../types/funnel';

interface SocialStepProps {
  step: FunnelStep;
  onSubmit: (data: Record<string, string>) => void;
  errors: Record<string, string>;
  isSubmitting?: boolean;
}

export function SocialStep({
  step,
  onSubmit,
  errors,
  isSubmitting
}: SocialStepProps) {
  const [formData, setFormData] = React.useState<Record<string, string>>({});

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <p className="text-white mb-6">{step.question}</p>

      {step.fields?.map((field) => (
        <div key={field.name}>
          <label className="block text-white mb-2" htmlFor={field.name}>
            {field.label}{field.required ? '*' : ''}
          </label>
          <input
            type="text"
            id={field.name}
            name={field.name}
            placeholder={field.placeholder}
            value={formData[field.name] || ''}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              [field.name]: e.target.value
            }))}
            required={field.required}
            className={`w-full px-4 py-3 rounded-lg bg-white/5 border text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all ${
              errors[field.name] ? 'border-red-500' : 'border-white/20'
            }`}
          />
          {errors[field.name] && (
            <p className="mt-2 text-red-400 text-sm">{errors[field.name]}</p>
          )}
        </div>
      ))}

      <button
        type="submit"
        disabled={isSubmitting}
        className="group w-full py-3 rounded-lg bg-[#FFC0CB] text-white font-medium hover:bg-[#FFB0BE] transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
      >
        <span>Continue</span>
        <ArrowRight className="w-5 h-5 ml-2 transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300" />
      </button>
    </form>
  );
}