import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DetailedTestimonialCard } from './DetailedTestimonialCard';
import { detailedTestimonialData } from './detailedTestimonialData';
import { useTranslation } from '../../hooks/useTranslation';

export function DetailedTestimonials() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && currentIndex < detailedTestimonialData.length - 1) {
      setCurrentIndex(prev => prev + 1);
    }
    if (isRightSwipe && currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.pageX - (carouselRef.current?.offsetLeft || 0));
    setScrollLeft(carouselRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (carouselRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 2;
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <section id="success-stories" className="py-12 lg:py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 lg:mb-12">
          <span className="inline-block px-4 py-2 rounded-full text-white border border-white text-sm font-medium mb-4">
            {t.successStories.badge}
          </span>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.successStories.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.successStories.subtitle}
          </p>
        </div>

        {/* Mobile Carousel */}
        <div className="lg:hidden">
          <div 
            ref={carouselRef}
            className="relative overflow-visible"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          >
            {/* Content wrapper with overflow hidden */}
            <div className="overflow-hidden">
              <div 
                className="flex transition-transform duration-500 ease-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
              >
                {detailedTestimonialData.map((testimonial) => (
                  <div 
                    key={testimonial.key}
                    className="w-full flex-shrink-0 px-4"
                  >
                    <DetailedTestimonialCard
                      testimonialKey={testimonial.key}
                      name={testimonial.name}
                      image={testimonial.image}
                      audioUrl={testimonial.audioUrl}
                      isMobile={true}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Navigation Arrows */}
            <button
              onClick={() => setCurrentIndex(prev => Math.max(0, prev - 1))}
              className={`absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 w-12 h-12 rounded-full bg-white/10 backdrop-blur-md flex items-center justify-center transition-all ${
                currentIndex === 0 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'opacity-100 hover:bg-white/20 hover:scale-110'
              }`}
              disabled={currentIndex === 0}
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button>
            <button
              onClick={() => setCurrentIndex(prev => Math.min(detailedTestimonialData.length - 1, prev + 1))}
              className={`absolute right-0 top-1/2 translate-x-1/2 -translate-y-1/2 w-12 h-12 rounded-full bg-white/10 backdrop-blur-md flex items-center justify-center transition-all ${
                currentIndex === detailedTestimonialData.length - 1 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'opacity-100 hover:bg-white/20 hover:scale-110'
              }`}
              disabled={currentIndex === detailedTestimonialData.length - 1}
            >
              <ChevronRight className="w-6 h-6 text-white" />
            </button>

            {/* Dots Navigation */}
            <div className="absolute -bottom-6 left-1/2 -translate-x-1/2 flex space-x-2">
              {detailedTestimonialData.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-2 h-2 rounded-full transition-all ${
                    index === currentIndex 
                      ? 'bg-white w-4' 
                      : 'bg-white/40 hover:bg-white/60'
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Desktop Layout */}
        <div className="hidden lg:block space-y-6">
          {detailedTestimonialData.map((testimonial) => (
            <DetailedTestimonialCard
              key={testimonial.key}
              testimonialKey={testimonial.key}
              name={testimonial.name}
              image={testimonial.image}
              audioUrl={testimonial.audioUrl}
            />
          ))}
        </div>
      </div>
    </section>
  );
}