import React from 'react';
import { FormField } from './FormField';
import type { SEOSettings } from './types';

interface TwitterSectionProps {
  settings: SEOSettings;
  onChange: (field: keyof SEOSettings, value: string) => void;
}

export function TwitterSection({ settings, onChange }: TwitterSectionProps) {
  return (
    <section className="space-y-4">
      <h3 className="text-lg font-semibold">Twitter Card</h3>
      
      <FormField
        label="Twitter Title"
        value={settings.twitter_title}
        onChange={(value) => onChange('twitter_title', value)}
      />

      <FormField
        label="Twitter Description"
        value={settings.twitter_description}
        onChange={(value) => onChange('twitter_description', value)}
        multiline
      />

      <FormField
        label="Twitter Image URL"
        value={settings.twitter_image}
        onChange={(value) => onChange('twitter_image', value)}
        type="url"
      />
    </section>
  );
}