import React from 'react';
import { LegalTemplate } from '../../components/legal/LegalTemplate';
import { useLanguage } from '../../contexts/LanguageContext';

export default function CookiePolicyPage() {
  const { language } = useLanguage();

  const content = {
    en: `
     <h1>Cookie Policy</h1>
<p>
  This Cookie Policy explains which cookies are used on our website and for what purposes. By using our website, you agree to the use of cookies in accordance with this policy.
</p>

<h2>Cookies We Use</h2>
<p>We use the following cookies:</p>
<ul>
  <li>
    <strong>clarity.ms:</strong> This cookie, provided by Microsoft Clarity, is used to analyze user behavior on our website anonymously. The collected data helps us continuously improve the usability and functionality of our site.
  </li>
  <li>
    <strong>googletagmanager (Google Ads):</strong> Implemented via Google Tag Manager, this cookie assists in integrating Google Ads and other marketing and analytics tools. It evaluates user behavior to deliver targeted advertising.
  </li>
  <li>
    <strong>Meta Pixel:</strong> Formerly known as Facebook Pixel, this cookie enables us to measure the effectiveness of our Facebook advertising campaigns and define target audiences for future campaigns by collecting information on your interactions with our website.
  </li>
</ul>

<h2>Managing Cookies</h2>
<p>
  You can manage, disable, or delete cookies through your browser settings. Please note that disabling cookies may affect the functionality of our website. For more information, please refer to your browser's help documentation.
</p>

<h2>Contact</h2>
<p>
  If you have any questions about this Cookie Policy or require further information, please contact us at <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
</p>
    `,
    de: `
      <h1>Cookie-Richtlinie</h1>
<p>
  Diese Cookie-Richtlinie informiert Sie darüber, welche Cookies auf unserer Website verwendet werden und zu welchen Zwecken. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies gemäß dieser Richtlinie zu.
</p>

<h2>Verwendete Cookies</h2>
<p>Wir setzen folgende Cookies ein:</p>
<ul>
  <li>
    <strong>clarity.ms:</strong> Dieses Cookie stammt von Microsoft Clarity und dient dazu, das Nutzerverhalten auf unserer Website anonym zu analysieren. Die gewonnenen Daten helfen uns, die Benutzerfreundlichkeit und Funktionalität kontinuierlich zu verbessern.
  </li>
  <li>
    <strong>googletagmanager (Google Ads):</strong> Dieses Cookie wird über den Google Tag Manager implementiert. Es unterstützt uns bei der Integration von Google Ads sowie weiterer Marketing- und Analysetools, um das Nutzerverhalten auszuwerten und zielgerichtete Werbung anzuzeigen.
  </li>
  <li>
    <strong>Meta Pixel:</strong> Das Meta Pixel (früher Facebook Pixel) Cookie ermöglicht es uns, die Wirksamkeit unserer Facebook-Werbekampagnen zu messen und Zielgruppen für zukünftige Kampagnen zu definieren. Es sammelt Informationen über Ihre Interaktionen mit unserer Website.
  </li>
</ul>

<h2>Cookies verwalten</h2>
<p>
  Sie können die Nutzung von Cookies in den Einstellungen Ihres Browsers verwalten, deaktivieren oder löschen. Bitte beachten Sie, dass eine Deaktivierung der Cookies Auswirkungen auf die Funktionalität unserer Website haben kann. Weitere Informationen finden Sie in der Hilfe Ihres Browsers.
</p>

<h2>Kontakt</h2>
<p>
  Wenn Sie Fragen zu dieser Cookie-Richtlinie haben oder weitere Informationen wünschen, kontaktieren Sie uns bitte unter <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
</p>
    `
  };

  return (
    <LegalTemplate
      title="Cookie Policy"
      content={content[language]}
    />
  );
}