import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface SubmissionStats {
  total: number;
  pending: number;
  completed: number;
  loading: boolean;
}

export function useSubmissionStats() {
  const [stats, setStats] = useState<SubmissionStats>({
    total: 0,
    pending: 0,
    completed: 0,
    loading: true
  });

  useEffect(() => {
    async function fetchStats() {
      try {
        const { data, error } = await supabase
          .from('funnel_submissions')
          .select('status');

        if (error) throw error;

        const total = data.length;
        const pending = data.filter(sub => sub.status === 'pending').length;
        const completed = data.filter(sub => sub.status === 'completed').length;

        setStats({
          total,
          pending,
          completed,
          loading: false
        });
      } catch (error) {
        console.error('Error fetching submission stats:', error);
        setStats(prev => ({ ...prev, loading: false }));
      }
    }

    fetchStats();
  }, []);

  return stats;
}