import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, HelpCircle, DollarSign, Shield, Clock, CheckCircle } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

interface AdminAuthProps {
  onAuth: () => void;
}

export function ChatFAQ() {
  const { t } = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);
  const [previousQuestion, setPreviousQuestion] = useState<string | null>(null);

  const handleQuestionClick = async (questionKey: string) => {
    if (selectedQuestion === questionKey) {
      setSelectedQuestion(null);
      setPreviousQuestion(null);
      return;
    }

    if (selectedQuestion) {
      setPreviousQuestion(selectedQuestion);
      setSelectedQuestion(null);
      await new Promise(resolve => setTimeout(resolve, 150));
    }

    setSelectedQuestion(questionKey);
  };

  const questions = [
    { key: 'what', icon: HelpCircle, delay: 0.1 },
    { key: 'cost', icon: DollarSign, delay: 0.2 },
    { key: 'verification', icon: Shield, delay: 0.3 },
    { key: 'duration', icon: Clock, delay: 0.4 },
    { key: 'satisfaction', icon: CheckCircle, delay: 0.5 }
  ];

  return (
    <div className="container mx-auto px-4 relative z-10">
      <div className="text-center mb-16">
        {/* FAQ Badge with gradient */}
        <div className="inline-block mb-6">
          <span 
            className="inline-flex items-center px-6 py-2 text-white font-semibold rounded-xl bg-gradient-to-r from-[#003366] to-[#FFC0CB] shadow-lg"
          >
            {t.faq.badge}
          </span>
        </div>
      </div>

      <div className="max-w-3xl mx-auto space-y-4">
        {questions.map(({ key, icon: Icon, delay }) => (
          <div key={key} className="space-y-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay }}
              className="flex justify-center"
            >
              <button
                onClick={() => handleQuestionClick(key)}
                className="w-full p-6 rounded-xl transition-all duration-300 bg-gradient-to-r from-[#003366] to-[#FFC0CB] hover:shadow-lg relative group"
              >
                <div className="flex items-center space-x-4">
                  <Icon className="w-6 h-6 text-[#FFC0CB]" />
                  <span className="flex-grow text-left text-lg font-medium text-white">
                    {t.faq.questions[key].question}
                  </span>
                  <ChevronDown 
                    className={`w-6 h-6 text-white transition-transform duration-300 ${
                      selectedQuestion === key ? 'rotate-180' : ''
                    }`}
                  />
                </div>
              </button>
            </motion.div>

            <AnimatePresence mode="wait">
              {selectedQuestion === key && (
                <motion.div
                  key={`answer-${key}`}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex justify-center"
                >
                  <div className="w-full p-6 rounded-xl bg-[#FFC0CB]/20 backdrop-blur-sm border border-[#FFC0CB]/30 shadow-lg">
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.15 }}
                      className="text-white text-lg leading-relaxed"
                    >
                      {t.faq.questions[key].answer}
                    </motion.p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
}