import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp } from 'lucide-react';
import { getPopularPosts } from '../../lib/blog';
import type { BlogPost } from './types';

export function PopularPosts() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadPopularPosts();
  }, []);

  async function loadPopularPosts() {
    try {
      const data = await getPopularPosts(3);
      setPosts(data);
    } catch (error) {
      console.error('Error loading popular posts:', error);
      setError('Failed to load popular posts');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="mb-12">
        <div className="flex items-center space-x-2 mb-6">
          <TrendingUp className="w-5 h-5 text-[#3BB8C3]" />
          <h2 className="text-2xl font-bold text-white">Most Popular</h2>
        </div>
        <div className="grid md:grid-cols-3 gap-6">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-md rounded-xl aspect-[4/3] animate-pulse">
              <div className="h-full bg-white/5"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return null; // Don't show error state for popular posts section
  }

  if (posts.length === 0) {
    return null;
  }

  return (
    <div className="mb-12">
      <div className="flex items-center space-x-2 mb-6">
        <TrendingUp className="w-5 h-5 text-[#3BB8C3]" />
        <h2 className="text-2xl font-bold text-white">Most Popular</h2>
      </div>
      
      <div className="grid md:grid-cols-3 gap-6">
        {posts.map((post) => (
          <Link
            key={post.id}
            to={`/blog/${post.slug}`}
            className="group relative overflow-hidden rounded-xl aspect-[4/3]"
          >
            <img 
              src={post.image_url} 
              alt={post.title}
              className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h3 className="text-lg font-semibold text-white mb-2 line-clamp-2">
                {post.title}
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-white/80 text-sm">{post.category}</span>
                {post.blog_views?.[0]?.view_count && (
                  <span className="text-white/80 text-sm">
                    {post.blog_views[0].view_count} views
                  </span>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}