import React from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { GoogleTagSettings } from '../../components/admin/settings/GoogleTagSettings';

export default function SettingsPage() {
  return (
    <AdminLayout>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-900">Settings</h2>
        <GoogleTagSettings />
      </div>
    </AdminLayout>
  );
}