import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import { GeneralSEOSection } from './seo/GeneralSEOSection';
import { OpenGraphSection } from './seo/OpenGraphSection';
import { TwitterSection } from './seo/TwitterSection';
import { SaveButton } from './seo/SaveButton';
import { defaultSettings, type SEOSettings } from './seo/types';
import { loadSEOSettings, updateSEOSettings } from '../../lib/seo';

export function SEOConfig() {
  const [settings, setSettings] = useState<SEOSettings>(defaultSettings);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      const data = await loadSEOSettings();
      if (data) {
        setSettings(data);
      }
    } catch (error) {
      console.error('Error loading SEO settings:', error);
      setMessage('Error loading settings. Please refresh the page.');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsSaving(true);
    setMessage('');

    try {
      // Remove id from settings when updating
      const { id, ...updateData } = settings;
      const success = await updateSEOSettings(updateData);
      
      if (success) {
        setMessage('SEO settings saved successfully!');
      } else {
        throw new Error('Failed to save settings');
      }
    } catch (error) {
      console.error('Error saving SEO settings:', error);
      setMessage('Error saving settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  }

  const handleFieldChange = (field: keyof SEOSettings, value: string) => {
    setSettings(prev => ({ ...prev, [field]: value || '' }));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-6">SEO Configuration</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <GeneralSEOSection settings={settings} onChange={handleFieldChange} />
        <OpenGraphSection settings={settings} onChange={handleFieldChange} />
        <TwitterSection settings={settings} onChange={handleFieldChange} />

        {message && (
          <div className={`p-4 rounded-md ${
            message.includes('Error') ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'
          }`}>
            {message}
          </div>
        )}

        <SaveButton isSaving={isSaving} />
      </form>
    </div>
  );
}