import React from 'react';
import { X, ChevronDown, ChevronUp, Copy, Mail, ExternalLink } from 'lucide-react';
import { formatDate } from '../../utils/date';
import { questionMap } from '../../utils/questionMapping';

interface SubmissionDetailsProps {
  submission: any;
  onClose: () => void;
}

export function SubmissionDetails({ submission, onClose }: SubmissionDetailsProps) {
  const [expandedSection, setExpandedSection] = React.useState<string | null>('basic');
  const [copiedField, setCopiedField] = React.useState<string | null>(null);

  const getQuestionDetails = (stepId: string) => {
    const details = questionMap.get(stepId);
    return details || { title: 'Unknown Question', question: stepId };
  };

  const formatAnswer = (answer: any) => {
    if (typeof answer === 'object') {
      return Object.entries(answer)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
    }
    return answer;
  };

  const handleCopy = async (text: string, field: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const Section = ({ 
    id, 
    title, 
    children 
  }: { 
    id: string; 
    title: string; 
    children: React.ReactNode 
  }) => (
    <div className="border-b border-gray-200 last:border-0">
      <button
        onClick={() => setExpandedSection(expandedSection === id ? null : id)}
        className="w-full px-4 py-3 flex items-center justify-between bg-gray-50"
      >
        <h3 className="text-base font-semibold text-gray-900">{title}</h3>
        {expandedSection === id ? (
          <ChevronUp className="w-5 h-5 text-[#FFC0CB]" />
        ) : (
          <ChevronDown className="w-5 h-5 text-[#FFC0CB]" />
        )}
      </button>
      {expandedSection === id && (
        <div className="p-4 bg-white space-y-4">
          {children}
        </div>
      )}
    </div>
  );

  const Field = ({ label, value, copyable = true }: { label: string; value: string; copyable?: boolean }) => (
    <div className="flex justify-between items-start">
      <div className="flex-1">
        <label className="block text-sm font-medium text-gray-500">{label}</label>
        <p className="mt-1 text-sm text-gray-900">{value}</p>
      </div>
      {copyable && (
        <button
          onClick={() => handleCopy(value, label)}
          className="ml-2 p-1 text-gray-400 hover:text-[#FFC0CB] transition-colors"
        >
          {copiedField === label ? (
            <div className="text-[#FFC0CB] text-xs whitespace-nowrap">Copied!</div>
          ) : (
            <Copy className="w-4 h-4" />
          )}
        </button>
      )}
    </div>
  );

  // Get contact information from funnel answers
  const getContactFields = () => {
    const fields: Array<{ label: string; value: string }> = [];
    
    // Find the contact step answer
    const contactAnswer = submission.answers.find((answer: any) => answer.stepId === 'contact');
    if (contactAnswer && contactAnswer.answer) {
      const contactData = contactAnswer.answer;

      // Add name if present
      if (contactData.name) {
        fields.push({ label: 'Name', value: contactData.name });
      }

      // Add email if present
      if (contactData.email) {
        fields.push({ label: 'Email', value: contactData.email });
      }

      // Combine phone number from country_code and phone
      if (contactData.country_code || contactData.phone) {
        const fullNumber = `${contactData.country_code || ''}${contactData.phone || ''}`;
        fields.push({ label: 'Full Number', value: fullNumber });
      }

      // Add contact method if present
      if (contactData.contact_method) {
        fields.push({ 
          label: 'Contact Method', 
          value: contactData.contact_method.charAt(0).toUpperCase() + contactData.contact_method.slice(1)
        });
      }

      // Add telegram if present
      if (contactData.telegram) {
        fields.push({ label: 'Telegram', value: contactData.telegram });
      }
    }

    // Add any social media information
    const socialAnswer = submission.answers.find((answer: any) => answer.stepId === 'social_media');
    if (socialAnswer && socialAnswer.answer) {
      const socialData = socialAnswer.answer;
      if (socialData.instagram) {
        fields.push({ label: 'Instagram', value: socialData.instagram });
      }
      if (socialData.onlyfans) {
        fields.push({ label: 'OnlyFans', value: socialData.onlyfans });
      }
    }

    return fields;
  };

  return (
    <div className="fixed inset-0 bg-black/50 z-50 overflow-y-auto">
      <div className="min-h-screen px-4 py-8">
        <div className="bg-white rounded-lg shadow-xl max-w-lg mx-auto">
          {/* Header */}
          <div className="sticky top-0 px-4 py-3 border-b border-gray-200 flex items-center justify-between bg-white z-10">
            <h2 className="text-lg font-semibold text-gray-900">Lead Details</h2>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-[#FFC0CB] transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Quick Actions */}
          <div className="px-4 py-3 bg-gray-50 border-b border-gray-200 flex justify-end space-x-3">
            {submission.contact_info.email && (
              <a
                href={`mailto:${submission.contact_info.email}`}
                className="inline-flex items-center px-3 py-1.5 text-sm text-[#FFC0CB] bg-[#FFC0CB]/10 rounded-md hover:bg-[#FFC0CB]/20 transition-colors"
              >
                <Mail className="w-4 h-4 mr-1" />
                Email
              </a>
            )}
            {submission.contact_info.instagram && (
              <a
                href={`https://instagram.com/${submission.contact_info.instagram.replace('@', '')}`}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-1.5 text-sm text-[#FFC0CB] bg-[#FFC0CB]/10 rounded-md hover:bg-[#FFC0CB]/20 transition-colors"
              >
                <ExternalLink className="w-4 h-4 mr-1" />
                Instagram
              </a>
            )}
          </div>

          {/* Content */}
          <div className="divide-y divide-gray-200">
            {/* Basic Info */}
            <Section id="basic" title="Basic Information">
              <div className="space-y-4">
                <Field 
                  label="Submission Date" 
                  value={formatDate(submission.created_at)} 
                  copyable={false}
                />
                <Field 
                  label="Path Type" 
                  value={submission.path_type === 'existing' ? 'Existing Creator' : 'New Creator'} 
                  copyable={false}
                />
              </div>
            </Section>

            {/* Contact Info */}
            <Section id="contact" title="Contact Information">
              <div className="space-y-4">
                {getContactFields().map((field, index) => (
                  <Field
                    key={index}
                    label={field.label}
                    value={field.value}
                  />
                ))}
              </div>
            </Section>

            {/* Answers */}
            <Section id="answers" title="Questionnaire Answers">
              <div className="space-y-6">
                {submission.answers.map((answer: any, index: number) => {
                  const questionDetails = getQuestionDetails(answer.stepId);
                  return (
                    <div key={index} className="pb-4 border-b border-gray-100 last:border-0 last:pb-0">
                      <h4 className="font-medium text-gray-900 mb-1">
                        {questionDetails.title}
                      </h4>
                      <p className="text-sm text-gray-600 mb-2">
                        {questionDetails.question}
                      </p>
                      <div className="bg-gray-50 p-3 rounded-lg text-sm text-gray-900 whitespace-pre-wrap">
                        {formatAnswer(answer.answer)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
}