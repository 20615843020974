import React from 'react';

interface StatusBadgeProps {
  status: string;
}

export function StatusBadge({ status }: StatusBadgeProps) {
  const getStatusStyles = () => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 border border-yellow-200';
      case 'contacted':
        return 'bg-blue-100 text-blue-800 border border-blue-200';
      case 'completed':
        return 'bg-green-100 text-green-800 border border-green-200';
      case 'no_response':
        return 'bg-gray-100 text-gray-800 border border-gray-200';
      case 'bad_lead':
        return 'bg-red-100 text-red-800 border border-red-200';
      case 'call_set':
        return 'bg-purple-100 text-purple-800 border border-purple-200';
      case '1st_followup':
        return 'bg-indigo-100 text-indigo-800 border border-indigo-200';
      case '2nd_followup':
        return 'bg-pink-100 text-pink-800 border border-pink-200';
      case '3rd_followup':
        return 'bg-orange-100 text-orange-800 border border-orange-200';
      case 'existing creator':
        return 'bg-purple-100 text-purple-800 border border-purple-200';
      case 'new creator':
        return 'bg-indigo-100 text-indigo-800 border border-indigo-200';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-200';
    }
  };

  const formatStatus = (status: string): string => {
    switch (status.toLowerCase()) {
      case '1st_followup':
        return '1. Follow Up';
      case '2nd_followup':
        return '2. Follow Up';
      case '3rd_followup':
        return '3. Follow Up';
      default:
        return status.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    }
  };

  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyles()}`}>
      {formatStatus(status)}
    </span>
  );
}