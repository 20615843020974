import React from 'react';
import { processSteps } from './agencyData';
import { useTranslation } from '../../hooks/useTranslation';

export function AgencyProcess() {
  const { t } = useTranslation();

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-2 rounded-full text-white border border-[#FFC0CB] text-sm font-medium mb-4">
            {t.agencies.process.badge}
          </span>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.agencies.process.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.agencies.process.subtitle}
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          {processSteps.map((step, index) => (
            <div key={index} className="relative flex items-start mb-12 last:mb-0">
              {/* Step number and line */}
              <div className="flex flex-col items-center mr-8">
                <div className="w-12 h-12 bg-[#FFC0CB] rounded-full flex items-center justify-center text-2xl">
                  {step.icon}
                </div>
                {index !== processSteps.length - 1 && (
                  <div className="w-0.5 h-24 bg-[#FFC0CB]/20 mt-4" />
                )}
              </div>

              {/* Content */}
              <div className="flex-1 pt-2">
                <h3 className="text-xl font-bold text-white mb-2">
                  {t.agencies.process.steps[step.key].title}
                </h3>
                <p className="text-white/80">
                  {t.agencies.process.steps[step.key].description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}