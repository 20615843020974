import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Star, ArrowRight } from 'lucide-react';
import Button from './ui/Button';
import { useLanguage } from '../contexts/LanguageContext';

export default function Hero() {
  const navigate = useNavigate();
  const { language } = useLanguage();

  return (
    <section className="relative h-screen max-h-[1080px] flex items-center pt-32 pb-16">
      <div className="container mx-auto px-4">
        {/* Mobile Hero Content */}
        <div className="lg:hidden space-y-4 pt-12">
          {/* Eyebrow text with gradient */}
          <div className="text-center">
            <div className="inline-block px-4 py-2 text-sm font-semibold tracking-wide rounded-xl">
              <span 
                className="bg-clip-text text-transparent"
                style={{
                  background: 'radial-gradient(circle at center, #FFC0CB 0%, #FF96AB 100%)',
                  WebkitBackgroundClip: 'text',
                  textShadow: '0px 2px 8px rgba(255, 192, 203, 0.4)',
                  letterSpacing: '1px'
                }}
              >
                UNLOCK YOUR FULL POTENTIAL AND GET MATCHED
              </span>
            </div>
          </div>

          <div className="space-y-4 text-center">
            <h1 className="text-3xl sm:text-4xl font-bold text-white leading-tight">
              Matching{' '}
              <span className="text-[#FFC0CB]">OnlyFans Models</span>{' '}
              with Exclusive Agencies
            </h1>
            
            <p className="text-lg sm:text-xl text-white/90 leading-relaxed mx-auto max-w-2xl">
              MatchMates connects you with elite agencies that have a proven track record in scaling OnlyFans models to the top 0.5%
            </p>

            <div className="space-y-2">
              <div className="relative">
                {/* CTA Button with shine effect */}
                <button 
                  onClick={() => navigate('/creator-quiz')}
                  className="relative w-full text-lg py-4 px-6 text-white font-semibold rounded-xl bg-[#FFC0CB] overflow-hidden"
                  style={{
                    isolation: 'isolate'
                  }}
                >
                  {/* Shine effect overlay */}
                  <div 
                    className="absolute inset-0 pointer-events-none"
                    style={{
                      background: 'linear-gradient(105deg, transparent, transparent 20%, rgba(255, 255, 255, 0.3) 25%, transparent 30%)',
                      animation: 'shine 2.5s infinite linear',
                    }}
                  />
                  
                  {/* Button content */}
                  <div className="relative flex items-center justify-center">
                    <span>Get Matched Today</span>
                    <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                  </div>
                </button>
              </div>

              {/* Trust indicator text */}
              <p className="text-xs text-white/60 font-medium tracking-wide">
                300+ Creators Trust MatchMates
              </p>
            </div>
          </div>
        </div>

        {/* Desktop Content */}
        <div className="hidden lg:block pt-16">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
            {/* Left Column */}
            <div className="flex flex-col justify-between py-8">
              <div className="mb-6">
                <div className="inline-flex items-center space-x-2 bg-white/10 backdrop-blur-md rounded-full px-4 py-2">
                  <Star className="w-5 h-5 text-yellow-400 fill-current" />
                  <span className="text-white/90">Your Success Starts With The Best Agency</span>
                </div>
              </div>
              
              <div className="flex-grow flex flex-col justify-center space-y-8">
                <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold text-white leading-tight">
                  Matching{' '}
                  <span className="text-[#FFC0CB]">OnlyFans Models</span>{' '}
                  with Exclusive Agencies
                </h1>
                
                <p className="text-xl text-white/90 max-w-2xl">
                  Unlock Your Full Potential - MatchMates connects you with elite agencies that have a proven track record in scaling OnlyFans models to the top 0.5%- completely free
                </p>

                <ul className="space-y-4">
                  <li className="flex items-center space-x-3">
                    <div className="bg-white/10 p-2 rounded-full">
                      <Star className="w-5 h-5 text-white" />
                    </div>
                    <span className="text-white/80">100% Verified Agencies - Every partner thoroughly vetted</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <div className="bg-white/10 p-2 rounded-full">
                      <Star className="w-5 h-5 text-white" />
                    </div>
                    <span className="text-white/80">Proven Growth Results - Average 273% revenue increase</span>
                  </li>
                </ul>
              </div>

              <div className="mt-8">
                <button 
                  onClick={() => navigate('/creator-quiz')}
                  className="text-xl px-10 py-6 text-white font-semibold rounded-xl bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] hover:from-[#FFB0BE] hover:to-[#FF96AB] transition-all duration-300 transform hover:scale-[1.02] flex items-center"
                >
                  <span>Get Matched Today</span>
                  <ArrowRight className="w-6 h-6 ml-2" />
                </button>
              </div>
            </div>

            {/* Right Column - Stats */}
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                {[
                  { value: '273%', label: 'Average Growth' },
                  { value: '100%', label: 'Verified Partners' }
                ].map((stat, index) => (
                  <div key={index} className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20 text-center">
                    <div className="text-4xl font-bold text-white mb-3">
                      {stat.value}
                    </div>
                    <div className="text-lg text-white/80">
                      {stat.label}
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20">
                <div className="mb-4">
                  <h3 className="text-2xl font-semibold text-white">Sarah</h3>
                  <p className="text-lg text-white/80">one of our first clients</p>
                </div>
                <blockquote className="text-xl text-white/90 italic mb-6">
                  <p>With MatchMates, I finally found the right agency that understands and implements my vision.</p>
                </blockquote>
                <div className="grid grid-cols-3 gap-4">
                  {[
                    { value: '438%', label: 'Revenue Increase' },
                    { value: '150k+', label: 'Followers in 5 Months' },
                    { value: '13h', label: 'Less Work Hours / Week' }
                  ].map((metric, index) => (
                    <div key={index} className="text-center">
                      <div className="text-2xl font-bold text-white">{metric.value}</div>
                      <div className="text-base text-white/80">{metric.label}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}