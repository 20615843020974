import React from 'react';
import { ChatFAQ } from './ChatFAQ';

export function FAQSection() {
  return (
    <section className="relative py-24 bg-white">
      {/* Background Image with Gradient Overlay */}
      <div className="absolute inset-0 w-full h-full">
        <div className="relative h-full w-full overflow-hidden">
          <img 
            src="https://imgur.com/QLln1h1.png" 
            alt=""
            className="w-full h-full object-cover opacity-25"
            loading="eager"
            style={{ 
              objectPosition: 'center',
              transform: 'translateZ(0)',
              willChange: 'transform'
            }}
          />
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-[#003366] to-[#FFC0CB] opacity-50" />
        </div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <ChatFAQ />
      </div>
    </section>
  );
}