import { FunnelPath } from '../../../types/funnel';

export const existingCreatorPath: FunnelPath = {
  id: 'existing',
  title: 'Existing Creator Path',
  steps: [
    {
      id: 'current_income',
      type: 'choice',
      title: 'How much are you currently earning each month on OnlyFans?',
      subtitle: 'This helps us match you with the right agency for your level.',
      question: 'Select your monthly earnings:',
      options: [
        { label: 'Under €1,000', value: 'under_1k' },
        { label: '€1,000 – €5,000', value: '1k_5k' },
        { label: '€5,000 – €20,000', value: '5k_20k' },
        { label: 'Over €20,000', value: 'over_20k' }
      ]
    },
    {
      id: 'time_spent',
      type: 'choice',
      title: 'How much time do you currently spend daily on your OnlyFans account?',
      subtitle: 'We\'ll help you optimize your time investment.',
      question: 'Select your daily time investment:',
      options: [
        { label: 'Less than 1 hour', value: 'under_1h' },
        { label: '1–2 hours', value: '1_2h' },
        { label: '3–5 hours', value: '3_5h' },
        { label: 'Over 5 hours', value: 'over_5h' }
      ]
    },
    {
      id: 'social_media',
      type: 'social',
      title: 'Where can we find you on social media?',
      subtitle: 'This helps us understand your current reach.',
      question: 'Share your social media profiles:',
      fields: [
        {
          name: 'instagram',
          type: 'text',
          label: 'Instagram',
          placeholder: '@username',
          required: true
        },
        {
          name: 'onlyfans',
          type: 'text',
          label: 'OnlyFans',
          placeholder: 'username (optional)',
          required: false
        }
      ]
    },
    {
      id: 'agency_experience',
      type: 'choice',
      title: 'Are you currently working with an agency or management team?',
      subtitle: 'Let us know about your current situation.',
      question: 'Select your experience:',
      options: [
        { label: 'Yes, but I am not satisfied', value: 'unsatisfied' },
        { label: 'Yes, and I am satisfied', value: 'satisfied' },
        { label: 'No, I manage everything myself', value: 'self_managed' }
      ]
    }
  ]
};