import React from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { AgencySubmissionsList } from '../../components/admin/submissions/AgencySubmissionsList';

export default function AgencySubmissionsPage() {
  return (
    <AdminLayout>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-[#1E3A8A]">Agency Submissions</h2>
        <AgencySubmissionsList />
      </div>
    </AdminLayout>
  );
}