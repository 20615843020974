import React, { useState } from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { BlogPostsList } from '../../components/admin/blog/BlogPostsList';
import BlogPostEditor from '../../components/admin/blog/BlogPostEditor';
import Button from '../../components/ui/Button';

export default function BlogManagementPage() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingPost, setEditingPost] = useState<string | null>(null);

  return (
    <AdminLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-900">Blog Management</h2>
          {!isCreating && !editingPost && (
            <Button 
              variant="primary"
              onClick={() => setIsCreating(true)}
            >
              Create New Post
            </Button>
          )}
        </div>

        {isCreating ? (
          <BlogPostEditor onClose={() => setIsCreating(false)} />
        ) : editingPost ? (
          <BlogPostEditor postId={editingPost} onClose={() => setEditingPost(null)} />
        ) : (
          <BlogPostsList onEdit={setEditingPost} />
        )}
      </div>
    </AdminLayout>
  );
}