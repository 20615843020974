import React from 'react';
import { Star, TrendingUp } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

interface CarouselCardProps {
  name: string;
  achievementKey: string;
}

export function CarouselCard({ 
  name, 
  achievementKey
}: CarouselCardProps) {
  const { t } = useTranslation();

  return (
    <div 
      className="relative group cursor-pointer transform transition-all duration-300 hover:scale-[1.02] h-[200px]"
    >
      <div className="h-full bg-gradient-to-br from-[#1E3A8A]/10 via-[#3BB8C3]/10 to-[#FFC0CB]/10 backdrop-blur-md rounded-xl p-6 border border-white/20 hover:bg-white/20 transition-all shadow-lg">
        {/* Top Section */}
        <div className="flex items-center justify-between mb-4">
          <div>
            <h3 className="text-lg font-bold text-[#FFC0CB] drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">{name}</h3>
            <div className="flex mt-1">
              {[...Array(5)].map((_, i) => (
                <Star key={i} className="w-4 h-4 text-white fill-white drop-shadow-[0_1px_1px_rgba(0,0,0,0.3)]" />
              ))}
            </div>
          </div>
          <div className="bg-gradient-to-br from-[#FFC0CB] via-[#FFC0CB] to-[#FFC0CB] p-2 rounded-full shadow-lg">
            <TrendingUp className="w-5 h-5 text-white" />
          </div>
        </div>

        {/* Achievement Text */}
        <p className="text-white leading-relaxed line-clamp-3 drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">
          {t.carousel.achievements[achievementKey]}
        </p>

        {/* Decorative Elements */}
        <div className="absolute bottom-4 right-4 opacity-10">
          <div className="text-6xl font-bold bg-gradient-to-br from-[#1E3A8A] via-[#3BB8C3] to-[#FFC0CB] bg-clip-text text-transparent">"</div>
        </div>
      </div>
    </div>
  );
}