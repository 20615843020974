import React from 'react';
import { Link } from 'react-router-dom';
import { Clock } from 'lucide-react';
import type { BlogPost } from './types';
import { useLanguage } from '../../contexts/LanguageContext';

interface RelatedPostsProps {
  currentPost: BlogPost;
  posts: BlogPost[];
}

export function RelatedPosts({ currentPost, posts }: RelatedPostsProps) {
  const { language } = useLanguage();
  
  // Get 3 related posts based on category, excluding the current post
  const relatedPosts = posts
    .filter(post => post.slug !== currentPost.slug)
    .sort((a, b) => {
      // First prioritize posts in the same category
      if (a.category === currentPost.category && b.category !== currentPost.category) return -1;
      if (b.category === currentPost.category && a.category !== currentPost.category) return 1;
      // Then sort by date
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    })
    .slice(0, 3);

  if (relatedPosts.length === 0) return null;

  return (
    <div className="mt-16 border-t border-gray-200 pt-16">
      <h2 className="text-2xl font-bold text-gray-900 mb-8">
        {language === 'de' ? 'Ähnliche Artikel' : 'Related Articles'}
      </h2>
      <div className="grid md:grid-cols-3 gap-6">
        {relatedPosts.map((post) => (
          <Link
            key={post.slug}
            to={`${language === 'de' ? '/de' : ''}/blog/${post.slug}`}
            className="group bg-gray-50 hover:bg-gray-100 rounded-xl overflow-hidden transition-all duration-300"
          >
            {post.image_url && (
              <div className="relative h-48">
                <img 
                  src={post.image_url} 
                  alt={post.title}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
              </div>
            )}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2 line-clamp-2 group-hover:text-[#007aff] transition-colors">
                {post.title}
              </h3>
              <div className="flex items-center text-gray-500 text-sm">
                <Clock className="w-4 h-4 mr-1" />
                <span>{post.read_time} {language === 'de' ? 'Min. Lesezeit' : 'min read'}</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}