import React from 'react';
import { LegalTemplate } from '../../components/legal/LegalTemplate';
import { useLanguage } from '../../contexts/LanguageContext';

export default function TermsOfServicePage() {
  const { language } = useLanguage();

  const content = {
    en: `
      <h1>Terms of Service</h1>
<p><strong>Effective as of:</strong> 04.06.2024</p>

<section id="introduction">
  <h2>1. Introduction</h2>
  <p>
    Welcome to MatchMates. These Terms of Service govern your use of our website and all associated services. By accessing or using our website, you agree to be bound by these terms.
  </p>
</section>

<section id="definitions">
  <h2>2. Definitions</h2>
  <ul>
    <li><strong>User:</strong> Any natural or legal person who visits our website or uses our services.</li>
    <li><strong>Services:</strong> All services provided by [Company Name], including all features and content available on our website.</li>
    <li><strong>Content:</strong> All text, images, graphics, videos, software, and other materials provided on our website.</li>
  </ul>
</section>

<section id="registration">
  <h2>3. Registration and User Account</h2>
  <p>
    Certain features of our website require registration. You agree to provide accurate, complete, and up-to-date information about yourself and to keep your login credentials confidential. You are responsible for all activities that occur under your account.
  </p>
</section>

<section id="usage">
  <h2>4. Use of Services</h2>
  <p>
    You agree to use our services solely for lawful purposes and in compliance with all applicable laws and regulations. Any abusive behavior, including the distribution of spam, malware, or illegal content, is strictly prohibited.
  </p>
</section>

<section id="intellectual-property">
  <h2>5. Intellectual Property</h2>
  <p>
    All content on this website, including text, images, logos, graphics, videos, and software, is protected by copyright and is the property of [Company Name] or our licensors. Any reproduction, distribution, modification, or other use of such content without explicit written permission is prohibited.
  </p>
</section>

<section id="modifications">
  <h2>6. Modifications to the Terms of Service</h2>
  <p>
    We reserve the right to modify or update these Terms of Service at any time. The most current version will be posted on our website. It is your responsibility to review these terms regularly for any changes.
  </p>
</section>

<section id="termination">
  <h2>7. Termination</h2>
  <p>
    We reserve the right to terminate your user account and access to our services at any time, without notice, if you violate these Terms of Service or use our services in a manner deemed abusive.
  </p>
</section>

<section id="disclaimer">
  <h2>8. Disclaimer</h2>
  <p>
    Our services are provided on an "as is" and "as available" basis. We make no guarantees regarding the accuracy, timeliness, or completeness of the content. Your use of our services is at your own risk.
  </p>
</section>

<section id="limitation">
  <h2>9. Limitation of Liability</h2>
  <p>
    Under no circumstances shall we be liable for any indirect, incidental, special, or consequential damages arising from the use or inability to use our services. Our total liability shall be limited to the amount you have paid for the services.
  </p>
</section>

<section id="governing-law">
  <h2>10. Governing Law and Jurisdiction</h2>
  <p>
    These Terms of Service shall be governed by the laws of the Federal Republic of Germany. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts located in [Location].
  </p>
</section>

<section id="contact">
  <h2>11. Contact</h2>
  <p>
    If you have any questions about these Terms of Service or require further information, please contact us via email at <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
  </p>
</section>
    `,
    de: `
      <h1>Nutzungsbedingungen</h1>
<p><strong>Gültig ab:</strong> 04.06.2024</p>

<section id="introduction">
  <h2>1. Einleitung</h2>
  <p>
    Willkommen bei MatchMates. Diese Nutzungsbedingungen regeln Ihre Nutzung unserer Website sowie aller damit verbundenen Dienstleistungen. Durch den Zugriff auf oder die Nutzung unserer Website erklären Sie sich mit diesen Bedingungen einverstanden.
  </p>
</section>

<section id="definitions">
  <h2>2. Definitionen</h2>
  <ul>
    <li><strong>Nutzer:</strong> Jede natürliche oder juristische Person, die unsere Website besucht oder unsere Dienstleistungen in Anspruch nimmt.</li>
    <li><strong>Dienstleistungen:</strong> Alle von [Name des Unternehmens] angebotenen Services, einschließlich sämtlicher Funktionen und Inhalte auf unserer Website.</li>
    <li><strong>Inhalte:</strong> Alle Texte, Bilder, Grafiken, Videos, Software und sonstige Materialien, die auf unserer Website bereitgestellt werden.</li>
  </ul>
</section>

<section id="registration">
  <h2>3. Registrierung und Benutzerkonto</h2>
  <p>
    Für bestimmte Funktionen unserer Website ist eine Registrierung erforderlich. Sie verpflichten sich, wahrheitsgemäße, vollständige und aktuelle Angaben zu Ihrer Person zu machen und Ihre Zugangsdaten streng vertraulich zu behandeln. Sie sind für alle Aktivitäten verantwortlich, die über Ihr Benutzerkonto erfolgen.
  </p>
</section>

<section id="usage">
  <h2>4. Nutzung der Dienstleistungen</h2>
  <p>
    Sie verpflichten sich, unsere Dienstleistungen ausschließlich für rechtmäßige Zwecke zu nutzen und alle anwendbaren Gesetze und Vorschriften einzuhalten. Jegliche missbräuchliche Nutzung, insbesondere die Verbreitung von Spam, Malware oder rechtswidrigen Inhalten, ist strengstens untersagt.
  </p>
</section>

<section id="intellectual-property">
  <h2>5. Geistiges Eigentum</h2>
  <p>
    Alle Inhalte auf dieser Website, einschließlich Texte, Bilder, Logos, Grafiken, Videos und Software, sind urheberrechtlich geschützt und Eigentum von [Name des Unternehmens] oder unseren Lizenzgebern. Eine Vervielfältigung, Verbreitung, Änderung oder anderweitige Nutzung dieser Inhalte ohne ausdrückliche schriftliche Genehmigung ist untersagt.
  </p>
</section>

<section id="modifications">
  <h2>6. Änderungen der Nutzungsbedingungen</h2>
  <p>
    Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern oder zu aktualisieren. Die jeweils aktuelle Version wird auf unserer Website veröffentlicht. Es liegt in Ihrer Verantwortung, sich regelmäßig über etwaige Änderungen zu informieren.
  </p>
</section>

<section id="termination">
  <h2>7. Beendigung</h2>
  <p>
    Wir behalten uns das Recht vor, Ihr Benutzerkonto und Ihre Nutzung unserer Dienstleistungen jederzeit und ohne Vorankündigung zu beenden, wenn Sie gegen diese Nutzungsbedingungen verstoßen oder unsere Dienste missbräuchlich nutzen.
  </p>
</section>

<section id="disclaimer">
  <h2>8. Haftungsausschluss</h2>
  <p>
    Unsere Dienstleistungen werden "wie besehen" und "wie verfügbar" bereitgestellt. Wir übernehmen keine Garantie für die Fehlerfreiheit, Aktualität oder Vollständigkeit der Inhalte. Die Nutzung unserer Dienste erfolgt auf eigenes Risiko.
  </p>
</section>

<section id="limitation">
  <h2>9. Haftungsbeschränkung</h2>
  <p>
    In keinem Fall haften wir für indirekte, zufällige, besondere oder Folgeschäden, die sich aus der Nutzung oder der Unmöglichkeit der Nutzung unserer Dienstleistungen ergeben. Unsere Gesamthaftung ist in jedem Fall auf den Betrag beschränkt, den Sie für die Nutzung unserer Dienstleistungen gezahlt haben.
  </p>
</section>

<section id="governing-law">
  <h2>10. Anwendbares Recht und Gerichtsstand</h2>
  <p>
    Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland. Für alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesen Bedingungen ergeben, ist der Gerichtsstand [Ort] ausschließlicher Gerichtsstand.
  </p>
</section>

<section id="contact">
  <h2>11. Kontakt</h2>
  <p>
    Wenn Sie Fragen zu diesen Nutzungsbedingungen haben oder weitere Informationen benötigen, kontaktieren Sie uns bitte per E-Mail an <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
  </p>
</section>
    `
  };

  return (
    <LegalTemplate
      title="Terms of Service"
      content={content[language]}
    />
  );
}