import type { DetailedTestimonial } from '../../types/testimonial';
import { AUDIO_URLS } from '../../constants/audio';

export const detailedTestimonialData: DetailedTestimonial[] = [
  {
    key: 'laura',
    name: 'Laura',
    image: 'https://imgur.com/vWZsDkp.png',
    audioUrl: AUDIO_URLS.lauraTestimonial
  },
  {
    key: 'anna',
    name: 'Anna',
    image: 'https://imgur.com/3bNywzu.png',
    audioUrl: AUDIO_URLS.annaTestimonial
  },
  {
    key: 'sophia',
    name: 'Sophia',
    image: 'https://imgur.com/kCbCXkm.png',
    audioUrl: AUDIO_URLS.sophiaTestimonial
  }
];