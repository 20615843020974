import { useState, useCallback } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import type { FunnelStep } from '../types/funnel';

export function useFunnelValidation(step: FunnelStep) {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { language } = useLanguage();

  const validateFields = useCallback((data: Record<string, any>) => {
    const newErrors: Record<string, string> = {};

    if (step.fields) {
      step.fields.forEach(field => {
        if (field.required && !data[field.name]) {
          newErrors[field.name] = language === 'de' 
            ? `${field.label} ist erforderlich`
            : `${field.label} is required`;
        }

        if (field.type === 'email' && data[field.name]) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(data[field.name])) {
            newErrors[field.name] = language === 'de'
              ? 'Bitte gib eine gültige E-Mail-Adresse ein'
              : 'Please enter a valid email address';
          }
        }

        if (field.type === 'tel' && data[field.name]) {
          const phoneRegex = /^\+?[\d\s-]{8,}$/;
          if (!phoneRegex.test(data[field.name])) {
            newErrors[field.name] = language === 'de'
              ? 'Bitte gib eine gültige Telefonnummer ein'
              : 'Please enter a valid phone number';
          }
        }

        // Instagram username validation
        if (field.name === 'instagram' && data[field.name]) {
          const username = data[field.name].replace('@', ''); // Remove @ if present
          if (username.length < 5) {
            newErrors[field.name] = language === 'de' 
              ? 'Bitte gib deinen richtigen Instagram-Nutzernamen ein'
              : 'Please enter your real Instagram username';
          }
        }
      });
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [step, language]);

  return {
    errors,
    validateFields,
    clearErrors: () => setErrors({})
  };
}