import { supabase } from './supabase';

export async function incrementPostViews(postId: string) {
  try {
    // First try to update existing view record
    const { data: existingView, error: updateError } = await supabase
      .from('blog_views')
      .update({ 
        view_count: supabase.raw('view_count + 1'),
        last_viewed_at: new Date().toISOString()
      })
      .eq('post_id', postId)
      .select()
      .single();

    // If no record exists (update returned no rows), create a new one
    if (!existingView && !updateError) {
      const { error: insertError } = await supabase
        .from('blog_views')
        .insert([{ 
          post_id: postId,
          view_count: 1,
          last_viewed_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (insertError && insertError.code !== '23505') { // Ignore duplicate key errors
        throw insertError;
      }
    }
  } catch (error) {
    console.warn('Error tracking post view:', error);
    // Don't throw the error - we don't want to break the user experience
    // just because view tracking failed
  }
}

export async function getPopularPosts(limit = 3) {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        blog_views (
          view_count
        )
      `)
      .eq('published', true)
      .order('created_at', { ascending: false })
      .limit(limit);

    if (error) throw error;

    // Sort by view count
    const sortedPosts = data
      ?.map(post => ({
        ...post,
        view_count: post.blog_views?.[0]?.view_count || 0
      }))
      .sort((a, b) => b.view_count - a.view_count);

    return sortedPosts || [];
  } catch (error) {
    console.error('Error fetching popular posts:', error);
    return [];
  }
}