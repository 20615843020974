import React from 'react';
import { TestimonialCarousel } from './TestimonialCarousel';
import { ReverseTestimonialCarousel } from './ReverseTestimonialCarousel';
import { useTranslation } from '../../hooks/useTranslation';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function TestimonialCarousels() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section id="success-stories" className="relative py-24 overflow-visible">
      {/* Fixed background image */}
      {/* 
      <div className="absolute inset-0 w-full h-full">
        <div className="relative h-full max-w-[1920px] mx-auto overflow-hidden">
          <img 
            src="https://imgur.com/wPK4GUs.png" 
            alt=""
            className="w-full h-full object-cover opacity-25"
            loading="eager"
            style={{ 
              objectPosition: 'center',
              transform: 'translateZ(0)',
              willChange: 'transform'
            }}
          />
          Gradient overlay for better readability
          <div className="absolute inset-0 bg-gradient-to-br from-[#003366] to-[#FFC0CB] opacity-50" />
        </div>
      </div>
     */}

      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          {/* Badge */}
          <div className="inline-flex items-center mb-4">
            <span className="inline-flex items-center px-6 py-2 text-white font-semibold rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] transition-all duration-300">
              {t.successStories.badge}
            </span>
          </div>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.successStories.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.successStories.subtitle}
          </p>
        </div>

        <div className="space-y-8">
          <TestimonialCarousel />
          <ReverseTestimonialCarousel />
        </div>

        {/* New CTA Section */}
        <div className="text-center mt-16">
          <button 
            onClick={() => navigate('/creator-quiz')}
            className="group inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] transition-all duration-300 transform hover:scale-[1.02]"
          >
            <span>Get Your Success Story</span>
            <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-2 transition-transform duration-300" />
          </button>
        </div>
      </div>
    </section>
  );
}