import React from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { AnalyticsOverview } from '../../components/admin/analytics/AnalyticsOverview';
import { IPExclusion } from '../../components/admin/analytics/IPExclusion';

export default function AnalyticsPage() {
  return (
    <AdminLayout>
      <div className="space-y-8">
        <AnalyticsOverview />
        <IPExclusion />
      </div>
    </AdminLayout>
  );
}