import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';

interface QualificationCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  requirement: string;
  highlight?: boolean;
}

export function QualificationCard({ 
  icon: Icon, 
  title, 
  description, 
  requirement,
  highlight = false 
}: QualificationCardProps) {
  return (
    <div className="bg-white/10 backdrop-blur-md rounded-2xl border border-white/20 p-8 hover:bg-white/20 transition-all h-full flex flex-col">
      {/* Icon */}
      <div className="bg-[#FFC0CB] p-3 rounded-full w-14 h-14 flex items-center justify-center mb-6">
        <Icon className="w-7 h-7 text-white" />
      </div>

      {/* Content */}
      <div className="flex-grow">
        <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
        <p className="text-white/80">{description}</p>
      </div>
      
      {/* Requirement Box - Always at Bottom */}
      <div className="mt-6">
        <div className={`
          min-h-[60px] flex items-center justify-center px-4 py-3 rounded-lg text-center
          ${highlight 
            ? 'bg-[#FFC0CB]/20 border-2 border-[#FFC0CB] text-white' 
            : 'text-[#FFC0CB]'
          }
        `}>
          <span className="font-semibold">
            Requirement: {requirement}
          </span>
        </div>
      </div>
    </div>
  );
}