import React from 'react';
import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

export function AboutTeam() {
  const { t } = useTranslation();

  return (
    <section className="py-24 relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="container mx-auto px-4"
      >
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ y: 40, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="bg-gradient-to-r from-[#1E3A8A]/20 to-[#3BB8C3]/20 backdrop-blur-md rounded-3xl overflow-hidden border border-white/20 p-12"
          >
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              {/* Image Column */}
              <motion.div
                initial={{ x: -40, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                viewport={{ once: true }}
                className="relative"
              >
                <div className="aspect-square rounded-2xl overflow-hidden">
                  <img 
                    src="https://imgur.com/Lg3iuTN.png"
                    alt={t.about.team.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent" />
                </div>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.8 }}
                  viewport={{ once: true }}
                  className="absolute bottom-6 left-6 right-6"
                >
                  <h3 className="text-3xl font-bold text-white mb-2">Rafael</h3>
                  <div className="flex items-center space-x-2">
                    <span className="text-[#3BB8C3] font-medium">{t.about.team.role}</span>
                  </div>
                </motion.div>
              </motion.div>

              {/* Content Column */}
              <motion.div
                initial={{ x: 40, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.8 }}
                viewport={{ once: true }}
                className="relative"
              >
                <Quote className="absolute -top-4 -left-4 w-16 h-16 text-[#3BB8C3] opacity-20" />
                <div className="space-y-6 text-white/90 relative">
                  <p className="text-lg leading-relaxed">
                    {t.about.team.quote1}
                  </p>
                  <p className="text-lg leading-relaxed">
                    {t.about.team.quote2}
                  </p>
                  <p className="text-lg leading-relaxed">
                    {t.about.team.quote3}
                  </p>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
}