import { supabase } from './supabase';
import type { SEOSettings } from '../components/admin/seo/types';

export async function loadSEOSettings(): Promise<SEOSettings | null> {
  try {
    const { data, error } = await supabase
      .from('seo_settings')
      .select('*')
      .limit(1)
      .maybeSingle();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error loading SEO settings:', error);
    return null;
  }
}

export async function updateSEOSettings(settings: Partial<SEOSettings>): Promise<boolean> {
  try {
    const { data: existing } = await supabase
      .from('seo_settings')
      .select('id')
      .limit(1)
      .maybeSingle();

    if (!existing?.id) {
      console.error('No existing SEO settings found');
      return false;
    }

    const { error } = await supabase
      .from('seo_settings')
      .update(settings)
      .eq('id', existing.id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating SEO settings:', error);
    return false;
  }
}