import { ClipboardList, Phone, Search, Handshake, Rocket } from 'lucide-react';

export const processStepsData = [
  {
    icon: ClipboardList,
    key: 'analysis'
  },
  {
    icon: Phone,
    key: 'consultation'
  },
  {
    icon: Search,
    key: 'search'
  },
  {
    icon: Handshake,
    key: 'matching'
  },
  {
    icon: Rocket,
    key: 'support'
  }
];