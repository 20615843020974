import React, { useState } from 'react';
import { Loader2, ArrowRight } from 'lucide-react';
import { CountryCodeSelect } from './CountryCodeSelect';
import { useLanguage } from '../../../contexts/LanguageContext';
import type { FunnelStep } from '../../../types/funnel';

interface ContactStepProps {
  step: FunnelStep;
  onSubmit: (data: Record<string, string>) => void;
  errors: Record<string, string>;
  isSubmitting?: boolean;
}

export function ContactStep({
  step,
  onSubmit,
  errors,
  isSubmitting
}: ContactStepProps) {
  const [formData, setFormData] = useState<Record<string, string>>({
    country_code: '+1' // Default to US
  });
  const [contactMethod, setContactMethod] = useState<string>('');
  const { language } = useLanguage();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === 'contact_method') {
      setContactMethod(value);
      // Clear telegram username when switching to WhatsApp or iMessage
      if (value === 'whatsapp' || value === 'imessage') {
        setFormData(prev => {
          const { telegram, ...rest } = prev;
          return rest;
        });
      }
    }
    
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <p className="text-white mb-6">{step.question}</p>

      {step.fields?.map((field) => {
        // Hide Telegram username field if WhatsApp or iMessage is selected
        if (field.name === 'telegram' && contactMethod !== 'telegram') {
          return null;
        }

        if (field.type === 'select') {
          return (
            <div key={field.name}>
              <label className="block text-white mb-2" htmlFor={field.name}>
                {field.label}{field.required ? '*' : ''}
              </label>
              <select
                id={field.name}
                name={field.name}
                value={formData[field.name] || ''}
                onChange={handleChange}
                required={field.required}
                className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
              >
                <option value="">Select...</option>
                {field.options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors[field.name] && (
                <p className="mt-1 text-red-400 text-sm">{errors[field.name]}</p>
              )}
            </div>
          );
        }

        // Special handling for phone field to include country code
        if (field.name === 'phone') {
          return (
            <div key={field.name}>
              <label className="block text-white mb-2" htmlFor={field.name}>
                {field.label}{field.required ? '*' : ''}
              </label>
              <div className="flex flex-col sm:flex-row gap-2">
                <div className="w-full sm:w-auto">
                  <CountryCodeSelect
                    value={formData.country_code || '+1'}
                    onChange={(code) => setFormData(prev => ({ ...prev, country_code: code }))}
                  />
                </div>
                <input
                  type="tel"
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  required={field.required}
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
                />
              </div>
              {errors[field.name] && (
                <p className="mt-1 text-red-400 text-sm">{errors[field.name]}</p>
              )}
            </div>
          );
        }

        return (
          <div key={field.name}>
            <label className="block text-white mb-2" htmlFor={field.name}>
              {field.label}{field.required ? '*' : ''}
            </label>
            <input
              type={field.type}
              id={field.name}
              name={field.name}
              placeholder={field.placeholder}
              value={formData[field.name] || ''}
              onChange={handleChange}
              required={field.required}
              className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
            />
            {errors[field.name] && (
              <p className="mt-1 text-red-400 text-sm">{errors[field.name]}</p>
            )}
          </div>
        );
      })}

      <button
        type="submit"
        disabled={isSubmitting}
        className="group w-full py-3 rounded-lg bg-[#FFC0CB] text-white font-medium hover:bg-[#FFB0BE] transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
      >
        {isSubmitting ? (
          <>
            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
            Submitting...
          </>
        ) : (
          <div className="flex items-center">
            <span>Continue</span>
            <ArrowRight className="w-5 h-5 ml-2 transform translate-x-0 group-hover:translate-x-2 transition-transform duration-300" />
          </div>
        )}
      </button>
    </form>
  );
}