import { 
  HelpCircle, 
  Users, 
  AlertTriangle, 
  Clock, 
  TrendingDown,
  CheckCircle,
  Handshake,
  Wallet,
  Timer,
  TrendingUp 
} from 'lucide-react';

export const searchAloneData = [
  {
    icon: HelpCircle,
    key: 'uncertainty'
  },
  {
    icon: Users,
    key: 'noFocus'
  },
  {
    icon: AlertTriangle,
    key: 'costs'
  },
  {
    icon: Clock,
    key: 'timeConsuming'
  },
  {
    icon: TrendingDown,
    key: 'noGuarantee'
  }
];

export const matchmatesData = [
  {
    icon: CheckCircle,
    key: 'vettedAgencies'
  },
  {
    icon: Handshake,
    key: 'support'
  },
  {
    icon: Wallet,
    key: 'pricing'
  },
  {
    icon: Timer,
    key: 'matching'
  },
  {
    icon: TrendingUp,
    key: 'success'
  }
];