import { useCachedQuery } from '../../lib/cache/hooks/useCachedQuery';
import React, { useState, useEffect } from 'react';
import { BlogCard } from './BlogCard';
import { supabase } from '../../lib/supabase';
import type { BlogPost } from './types';

export function BlogPosts() {
  const { data: posts, isLoading, error } = useCachedQuery(
    {
      key: 'blog-posts',
      ttl: 5 * 60 * 1000 // Cache for 5 minutes
    },
    async () => {
      console.log('Fetching blog posts...');
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('published', true)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching blog posts:', error);
        throw error;
      }

      console.log('Retrieved blog posts:', data?.length || 0);
      return data || [];
    }
  );

  if (isLoading) {
    return (
      <div className="space-y-8">
        {[...Array(3)].map((_, index) => (
          <div key={index} className="bg-white/10 backdrop-blur-md rounded-2xl border border-white/20 overflow-hidden">
            <div className="grid md:grid-cols-3 gap-6">
              <div className="relative h-48 md:h-full bg-white/5 animate-pulse" />
              <div className="p-6 md:col-span-2 space-y-4">
                <div className="h-8 bg-white/5 rounded animate-pulse" />
                <div className="h-4 bg-white/5 rounded w-3/4 animate-pulse" />
                <div className="h-4 bg-white/5 rounded w-1/2 animate-pulse" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    console.error('Error displaying blog posts:', error);
    return (
      <div className="bg-red-500/10 border border-red-500/20 rounded-xl p-8 text-center">
        <p className="text-white/90 mb-4">Failed to load blog posts</p>
        <button 
          className="px-6 py-2 bg-white/10 hover:bg-white/20 rounded-lg text-white transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (!posts || posts.length === 0) {
    return (
      <div className="bg-white/10 backdrop-blur-md rounded-2xl border border-white/20 p-8 text-center">
        <p className="text-white/90 text-lg">No blog posts found.</p>
        <p className="text-white/60 mt-2">Check back later for new content!</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {posts.map((post) => (
        <BlogCard key={post.id} {...post} />
      ))}
    </div>
  );
}