import { TESTIMONIAL_IMAGES } from '../../constants/testimonialImages';

export interface ReverseCarouselTestimonial {
  name: string;
  image: string;
  achievementKey: string;
}

export const reverseCarouselTestimonials: ReverseCarouselTestimonial[] = [
  {
    name: 'Olivia',
    image: TESTIMONIAL_IMAGES.LAURA,
    achievementKey: 'olivia'
  },
  {
    name: 'Isabelle',
    image: TESTIMONIAL_IMAGES.ANNA,
    achievementKey: 'isabelle'
  },
  {
    name: 'Lisa',
    image: TESTIMONIAL_IMAGES.ANNA,
    achievementKey: 'lisa'
  },
  {
    name: 'Mia',
    image: TESTIMONIAL_IMAGES.LAURA,
    achievementKey: 'mia'
  },
  {
    name: 'Emma',
    image: TESTIMONIAL_IMAGES.SOPHIA,
    achievementKey: 'emma'
  },
  {
    name: 'Sophia',
    image: TESTIMONIAL_IMAGES.SOPHIA,
    achievementKey: 'sophia'
  }
];