import React from 'react';
import { QuestionFunnel } from '../components/funnel/QuestionFunnel';
import { backgrounds } from '../constants/backgrounds';

export default function CreatorFunnelPage() {
  return (
    <div className="min-h-screen">
      <main className={`${backgrounds.hero} min-h-screen py-8`}>
        <div className="container mx-auto px-4 pt-16">
          <QuestionFunnel />
        </div>
      </main>
    </div>
  );
}