import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { cacheManager } from '../cacheManager';

interface CacheConfig {
  ttl?: number;
  key: string;
}

export function useCachedQuery<T>(
  cacheConfig: CacheConfig,
  queryFn: () => Promise<T>,
  options?: Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>
) {
  const { key, ttl } = cacheConfig;

  return useQuery({
    queryKey: [key],
    queryFn: async () => {
      // Check cache first
      const cachedData = cacheManager.get<T>(key);
      if (cachedData) {
        return cachedData;
      }

      // If not in cache, fetch data
      const data = await queryFn();
      
      // Store in cache
      cacheManager.set(key, data, ttl);
      
      return data;
    },
    staleTime: ttl || 5 * 60 * 1000, // Match cache TTL
    ...options
  });
}