import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Settings,
  FileText,
  MessageSquare,
  LineChart,
  BookOpen,
  Building2,
  Scale
} from 'lucide-react';

const menuItems = [
  { path: '/admin', label: 'Dashboard', icon: LayoutDashboard },
  { path: '/admin/analytics', label: 'Analytics', icon: LineChart },
  { path: '/admin/submissions', label: 'Creator Submissions', icon: MessageSquare },
  { path: '/admin/agencies', label: 'Agency Submissions', icon: Building2 },
  { path: '/admin/blog', label: 'Blog', icon: BookOpen },
  { path: '/admin/seo', label: 'SEO Settings', icon: FileText },
  { path: '/admin/settings', label: 'Settings', icon: Settings },
];

export function AdminSidebar() {
  const location = useLocation();

  return (
    <aside className="w-64 bg-white shadow-sm min-h-screen">
      <nav className="mt-8 px-4">
        <ul className="space-y-2">
          {menuItems.map((item) => {
            const Icon = item.icon;
            const isActive = location.pathname === item.path;

            return (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`flex items-center px-4 py-3 rounded-lg transition-colors ${
                    isActive
                      ? 'bg-[#FFC0CB]/10 text-[#FFC0CB]'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-[#FFC0CB]'
                  }`}
                >
                  <Icon className="w-5 h-5 mr-3" />
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
}