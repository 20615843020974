import { StrictMode } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ScrollToTop } from '../components/ScrollToTop';
import { LanguageProvider } from '../contexts/LanguageContext';
import { getLanguageFromPath } from '../lib/utils/languageUtils';
import App from '../App';
import AgenciesPage from '../pages/AgenciesPage';
import ImprintPage from '../pages/ImprintPage';
import CreatorFunnelPage from '../pages/CreatorFunnelPage';
import SuccessPage from '../pages/SuccessPage';
import DashboardPage from '../pages/admin/DashboardPage';
import AnalyticsPage from '../pages/admin/AnalyticsPage';
import SubmissionsPage from '../pages/admin/SubmissionsPage';
import AgencySubmissionsPage from '../pages/admin/AgencySubmissionsPage';
import SEOPage from '../pages/admin/SEOPage';
import BlogManagementPage from '../pages/admin/BlogManagementPage';
import SettingsPage from '../pages/admin/SettingsPage';
import BlogPage from '../pages/BlogPage';
import BlogPostPage from '../pages/BlogPostPage';
import AboutPage from '../pages/AboutPage';
import TransparencyPage from '../pages/legal/TransparencyPage';
import PrivacyPolicyPage from '../pages/legal/PrivacyPolicyPage';
import TermsOfServicePage from '../pages/legal/TermsOfServicePage';
import CookiePolicyPage from '../pages/legal/CookiePolicyPage';
import { generateSitemap } from '../lib/sitemap';

function LanguageRedirect() {
  const location = useLocation();
  const detectedLanguage = getLanguageFromPath(location.pathname);
  
  if (detectedLanguage === 'de' && !location.pathname.startsWith('/de')) {
    return <Navigate to={`/de${location.pathname}`} replace />;
  }
  return null;
}

// Sitemap component
function SitemapRoute() {
  const [sitemap, setSitemap] = React.useState<string | null>(null);

  React.useEffect(() => {
    generateSitemap().then(setSitemap);
  }, []);

  if (!sitemap) return null;

  return (
    <div dangerouslySetInnerHTML={{ __html: sitemap }} />
  );
}

export default function AppRoutes() {
  return (
    <>
      <ScrollToTop />
      <LanguageProvider>
        <LanguageRedirect />
        <Routes>
          {/* German routes */}
          <Route path="/de" element={<App />} />
          <Route path="/de/agencies" element={<AgenciesPage />} />
          <Route path="/de/imprint" element={<ImprintPage />} />
          <Route path="/de/success" element={<SuccessPage />} />
          <Route path="/de/blog" element={<BlogPage />} />
          <Route path="/de/blog/:slug" element={<BlogPostPage />} />
          <Route path="/de/about" element={<AboutPage />} />
          <Route path="/de/transparency" element={<TransparencyPage />} />
          <Route path="/de/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/de/terms" element={<TermsOfServicePage />} />
          <Route path="/de/cookies" element={<CookiePolicyPage />} />
          <Route path="/de/creator-quiz" element={<CreatorFunnelPage />} />

          {/* English routes */}
          <Route path="/" element={<App />} />
          <Route path="/agencies" element={<AgenciesPage />} />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:slug" element={<BlogPostPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/transparency" element={<TransparencyPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/cookies" element={<CookiePolicyPage />} />
          <Route path="/creator-quiz" element={<CreatorFunnelPage />} />

          {/* Admin routes (no language prefix) */}
          <Route path="/admin" element={<DashboardPage />} />
          <Route path="/admin/analytics" element={<AnalyticsPage />} />
          <Route path="/admin/submissions" element={<SubmissionsPage />} />
          <Route path="/admin/agencies" element={<AgencySubmissionsPage />} />
          <Route path="/admin/seo" element={<SEOPage />} />
          <Route path="/admin/blog" element={<BlogManagementPage />} />
          <Route path="/admin/settings" element={<SettingsPage />} />

          {/* Sitemap */}
          <Route path="/sitemap.xml" element={<SitemapRoute />} />
        </Routes>
      </LanguageProvider>
    </>
  );
}