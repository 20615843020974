import React from 'react';
import { ComparisonSide } from './comparison/ComparisonSide';
import { ComparisonCTA } from './comparison/ComparisonCTA';
import { searchAloneData, matchmatesData } from './comparison/comparisonData';
import { useComparisonAnimation } from './comparison/useComparisonAnimation';
import { useTranslation } from '../hooks/useTranslation';

export default function Comparison() {
  const { containerRef } = useComparisonAnimation();
  const { t } = useTranslation();

  return (
    <section className="py-12 lg:py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 lg:mb-12">
          <span 
            className="inline-block px-4 py-2 rounded-full text-white text-sm font-medium mb-4"
            style={{
              background: 'linear-gradient(135deg, #003366 0%, #FFC0CB 100%)'
            }}
          >
            {t.comparison.badge}
          </span>
          <h2 className="text-3xl font-bold text-[#1E3A8A] mb-4">
            {t.comparison.title}
          </h2>
          <p className="text-gray-600 max-w-3xl mx-auto">
            {t.comparison.subtitle}
          </p>
        </div>

        <div ref={containerRef} className="relative min-h-[600px] max-w-4xl mx-auto">
          {/* Mobile Layout */}
          <div className="md:hidden space-y-6">
            <ComparisonSide 
              title={t.comparison.challenges.title}
              items={searchAloneData}
              variant="negative"
            />
            <ComparisonSide 
              title={t.comparison.benefits.title}
              items={matchmatesData}
              variant="positive"
            />
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:block">
            <div className="absolute left-0 top-0 w-[52%] transition-all duration-700" data-comparison="negative">
              <ComparisonSide 
                title={t.comparison.challenges.title}
                items={searchAloneData}
                variant="negative"
              />
            </div>
            <div className="absolute left-[48%] top-8 w-[52%] transition-all duration-700" data-comparison="positive">
              <ComparisonSide 
                title={t.comparison.benefits.title}
                items={matchmatesData}
                variant="positive"
              />
            </div>
          </div>
        </div>

        <ComparisonCTA />
      </div>
    </section>
  );
}