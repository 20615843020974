import React from 'react';
import { LegalTemplate } from '../../components/legal/LegalTemplate';
import { useLanguage } from '../../contexts/LanguageContext';

export default function PrivacyPolicyPage() {
  const { language } = useLanguage();

  const content = {
    en: `
      <h1>Privacy Policy</h1>
<p><strong>Last Updated:</strong> 04.06.2024</p>

<section id="introduction">
  <h2>1. Introduction</h2>
  <p>
    This Privacy Policy informs you about how we collect, process, and protect your personal data. Protecting your data is of utmost importance to us, and we are committed to safeguarding your privacy in accordance with applicable data protection laws.
  </p>
</section>

<section id="data-collection">
  <h2>2. Collection and Use of Personal Data</h2>
  <p>
    When you use our website, we collect personal data that you voluntarily provide to us, as well as data that is automatically generated when you visit our website (e.g., IP address, browser type, access times). We use this data to provide our services, optimize the user experience, and ensure the security of our website.
  </p>
</section>

<section id="purpose">
  <h2>3. Purpose of Data Processing</h2>
  <p>
    We process your data for the following purposes:
  </p>
  <ul>
    <li>Provision and improvement of our services</li>
    <li>Analysis and optimization of our website</li>
    <li>Communication with you, such as responding to inquiries or sending information</li>
    <li>Compliance with legal obligations</li>
  </ul>
</section>

<section id="cookies">
  <h2>4. Use of Cookies and Tracking Technologies</h2>
  <p>
    Our website uses cookies and similar tracking technologies to analyze website usage and display personalized content and advertising. For more information, please refer to our <a href="#cookie-policy">Cookie Policy</a>.
  </p>
</section>

<section id="data-sharing">
  <h2>5. Data Sharing</h2>
  <p>
    Your personal data will only be shared with third parties if it is necessary for the fulfillment of the purposes mentioned above or if we are legally obligated to do so. Any transfer to third countries will be carried out in accordance with applicable data protection regulations.
  </p>
</section>

<section id="data-security">
  <h2>6. Data Security</h2>
  <p>
    We implement appropriate technical and organizational measures to protect your data from loss, misuse, and unauthorized access. However, despite these measures, absolute security cannot be guaranteed.
  </p>
</section>

<section id="user-rights">
  <h2>7. User Rights</h2>
  <p>
    You have the right to access the personal data we hold about you, as well as the rights to rectify, delete, restrict processing, data portability, and to object. You may exercise these rights at any time by contacting us.
  </p>
</section>

<section id="third-party-links">
  <h2>8. Third-Party Links</h2>
  <p>
    Our website may contain links to third-party websites. Please note that we have no control over the content and privacy practices of these sites. We recommend that you review the privacy policies of the linked sites.
  </p>
</section>

<section id="changes">
  <h2>9. Changes to the Privacy Policy</h2>
  <p>
    We reserve the right to change this Privacy Policy at any time. The current version will be published on our website. Please review it regularly for any updates.
  </p>
</section>

<section id="contact">
  <h2>10. Contact</h2>
  <p>
    If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us via email at <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
  </p>
</section>





    `,
    de: `
     <h1>Datenschutzerklärung</h1>
<p><strong>Letzte Aktualisierung:</strong> 04.06.2024</p>

<section id="introduction">
  <h2>1. Einleitung</h2>
  <p>
    Diese Datenschutzerklärung informiert Sie darüber, wie wir Ihre personenbezogenen Daten erheben, verarbeiten und schützen. Der Schutz Ihrer Daten ist uns ein wichtiges Anliegen, und wir verpflichten uns, Ihre Privatsphäre in Übereinstimmung mit den geltenden Datenschutzgesetzen zu wahren.
  </p>
</section>

<section id="data-collection">
  <h2>2. Erhebung und Verwendung personenbezogener Daten</h2>
  <p>
    Bei der Nutzung unserer Website erheben wir personenbezogene Daten, die Sie uns freiwillig zur Verfügung stellen, sowie Daten, die automatisch beim Besuch unserer Website generiert werden (z. B. IP-Adresse, Browsertyp, Zugriffszeiten). Diese Daten verwenden wir, um unsere Dienste bereitzustellen, die Nutzererfahrung zu optimieren und die Sicherheit unserer Website zu gewährleisten.
  </p>
</section>

<section id="purpose">
  <h2>3. Zweck der Datenverarbeitung</h2>
  <p>
    Die Verarbeitung Ihrer Daten erfolgt zu folgenden Zwecken:
  </p>
  <ul>
    <li>Bereitstellung und Verbesserung unserer Dienstleistungen</li>
    <li>Analyse und Optimierung unserer Website</li>
    <li>Kommunikation mit Ihnen, z. B. zur Beantwortung von Anfragen oder zur Zusendung von Informationen</li>
    <li>Erfüllung gesetzlicher Verpflichtungen</li>
  </ul>
</section>

<section id="cookies">
  <h2>4. Verwendung von Cookies und Tracking-Technologien</h2>
  <p>
    Unsere Website verwendet Cookies und ähnliche Tracking-Technologien, um die Nutzung der Website zu analysieren und personalisierte Inhalte sowie Werbung anzuzeigen. Weitere Informationen hierzu finden Sie in unserer <a href="#cookie-policy">Cookie-Richtlinie</a>.
  </p>
</section>

<section id="data-sharing">
  <h2>5. Weitergabe von Daten</h2>
  <p>
    Eine Weitergabe Ihrer personenbezogenen Daten an Dritte erfolgt nur, wenn dies zur Erfüllung der oben genannten Zwecke notwendig ist oder wir gesetzlich dazu verpflichtet sind. Eine Übermittlung an Drittstaaten erfolgt unter Beachtung der gesetzlichen Datenschutzvorgaben.
  </p>
</section>

<section id="data-security">
  <h2>6. Datensicherheit</h2>
  <p>
    Wir setzen angemessene technische und organisatorische Maßnahmen ein, um Ihre Daten vor Verlust, Missbrauch und unbefugtem Zugriff zu schützen. Trotz dieser Maßnahmen kann jedoch keine hundertprozentige Sicherheit gewährleistet werden.
  </p>
</section>

<section id="user-rights">
  <h2>7. Rechte der Nutzer</h2>
  <p>
    Sie haben das Recht, Auskunft über Ihre bei uns gespeicherten personenbezogenen Daten zu erhalten, sowie das Recht auf Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Widerspruch. Zur Ausübung dieser Rechte können Sie sich jederzeit an uns wenden.
  </p>
</section>

<section id="third-party-links">
  <h2>8. Links zu Drittanbietern</h2>
  <p>
    Unsere Website kann Links zu Webseiten Dritter enthalten. Bitte beachten Sie, dass wir keinen Einfluss auf die Inhalte und Datenschutzpraktiken dieser Seiten haben. Wir empfehlen Ihnen, die Datenschutzerklärungen der verlinkten Seiten zu lesen.
  </p>
</section>

<section id="changes">
  <h2>9. Änderungen der Datenschutzerklärung</h2>
  <p>
    Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Die jeweils aktuelle Version wird auf unserer Website veröffentlicht. Bitte informieren Sie sich regelmäßig über eventuelle Änderungen.
  </p>
</section>

<section id="contact">
  <h2>10. Kontakt</h2>
  <p>
    Wenn Sie Fragen zu dieser Datenschutzerklärung haben oder Ihre Rechte ausüben möchten, kontaktieren Sie uns bitte per E-Mail an <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a>.
  </p>
</section>
    `
  };

  return (
    <LegalTemplate
      title="Privacy Policy"
      content={content[language]}
    />
  );
}