import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';
import Button from '../components/ui/Button';
import { backgrounds } from '../constants/backgrounds';
import { trackConversion } from '../lib/googleTag';
import { useLanguage } from '../contexts/LanguageContext';

export default function SuccessPage() {
  const navigate = useNavigate();
  const { language } = useLanguage();

  useEffect(() => {
    // Track conversion with proper configuration
    trackConversion('AW-11532404213/SjdTCOjBgowaEPWLivsq', () => {
      console.log('Conversion tracked successfully');
    });
  }, []);

  const content = {
    de: {
      title: 'Vielen Dank! 🎉',
      subtitle: 'Wir haben deine Informationen erhalten und werden sie sorgfältig analysieren, um die perfekte Agentur für dich zu finden.',
      nextSteps: {
        title: 'Was passiert als Nächstes? 💭',
        steps: [
          'Wir analysieren dein Profil und deine Anforderungen',
          'Wir bereiten ein Erstgespräch vor',
          'Wir kontaktieren dich innerhalb von 24 Stunden, um die nächsten Schritte zu besprechen'
        ]
      },
      contact: {
        question: 'Fragen? Kontaktiere uns unter: 📧',
        email: 'office@match-mates.com'
      },
      backButton: 'Zurück zur Startseite'
    },
    en: {
      title: 'Thank You! 🎉',
      subtitle: 'We have received your information and will carefully analyze it to find the perfect agency match for you.',
      nextSteps: {
        title: 'What Happens Next? 💭',
        steps: [
          'We analyze your profile and requirements',
          'We prepare an initial consultation',
          'We will contact you within 24 hours to discuss next steps'
        ]
      },
      contact: {
        question: 'Questions? Contact us at: 📧',
        email: 'office@match-mates.com'
      },
      backButton: 'Back to Homepage'
    }
  };

  const text = content[language];

  return (
    <div className="min-h-screen flex flex-col">
      <main className={`${backgrounds.hero} flex-grow py-8`}>
        <div className="container mx-auto px-4 pt-16">
          <div className="max-w-2xl mx-auto text-center">
            <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20">
              <div className="w-16 h-16 mx-auto mb-6 rounded-full bg-[#FFC0CB] flex items-center justify-center">
                <CheckCircle className="w-8 h-8 text-white" />
              </div>
              
              <h1 className="text-3xl font-bold text-white mb-4">{text.title}</h1>
              <p className="text-white/90 text-lg mb-8">
                {text.subtitle}
              </p>

              <div className="bg-white/5 rounded-xl p-6 mb-8">
                <h2 className="text-xl font-semibold text-white mb-4">{text.nextSteps.title}</h2>
                <ul className="space-y-3 text-left text-white/80">
                  {text.nextSteps.steps.map((step, index) => (
                    <li key={index} className="flex items-center">
                      <span className="w-2 h-2 rounded-full bg-[#FFC0CB] mr-3" />
                      {step}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="text-white/80 mb-8">
                <p>{text.contact.question}</p>
                <p className="font-medium text-white">{text.contact.email}</p>
              </div>

              <button 
                onClick={() => navigate('/')}
                className="group inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] hover:from-[#FFB0BE] hover:to-[#FF96AB] transition-all duration-300 transform hover:scale-[1.02]"
              >
                {text.backButton}
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}