import type { Translations } from './types';

const de: Translations = {
  common: {
    startNow: 'Jetzt Starten',
    getMatched: 'Jetzt Matching starten',
    loading: 'Lädt...',
    error: 'Ein Fehler ist aufgetreten',
    tryAgain: 'Erneut versuchen',
    success: 'Erfolgreich',
    close: 'Schließen',
    save: 'Speichern',
    delete: 'Löschen',
    edit: 'Bearbeiten',
    view: 'Ansehen',
    copy: 'Kopieren',
    copied: 'Kopiert',
    next: 'Weiter',
    back: 'Zurück',
    submit: 'Absenden',
    cancel: 'Abbrechen'
  },
  nav: {
    aboutUs: 'Über Uns',
    forCreators: 'Für Creator',
    forAgencies: 'Für Agenturen', 
    successStories: 'Erfolgsgeschichten',
    blog: 'Blog',
    dashboard: 'Dashboard',
    analytics: 'Analysen',
    submissions: 'Bewerbungen',
    settings: 'Einstellungen'
  },
  hero: {
    trusted: 'Trusted von über 300 Creatorn',
    title: {
      part1: 'Wir verbinden',
      part2: 'Models',
      part3: 'mit',
      part4: 'geprüften Agenturen'
    },
    subtitle: 'Sag Tschüss zu unzuverlässigen Agenturen.\nWir verbinden dich mit Partnern, die deinen Erfolg unterstützen\n- komplett kostenlos.',
    benefits: {
      verification: '100% Geprüfte Agenturen - Jeder Partner wird gründlich überprüft',
      growth: 'Bewiesene Wachstumsergebnisse - Durchschnittlich 273% Umsatzsteigerung',
      risk: 'Risikofreies Matching - Keine Gebühren oder Verpflichtungen'
    },
    cta: {
      main: 'Jetzt Matching starten',
      secondary: 'Erfolgsgeschichten ansehen'
    }
  },
  stats: {
    averageGrowth: 'Durchschnittliches Wachstum',
    verifiedPartners: 'Geprüfte Partner',
    totalMatches: 'Erfolgreiche Matches',
    satisfactionRate: 'Zufriedenheitsrate'
  },
  features: {
    title: 'Warum MatchMates?',
    subtitle: 'Dein Weg zum Erfolg mit professioneller Unterstützung',
    matching: {
      title: 'Perfektes Matching',
      description: 'Wir verbinden dich mit Agenturen, die perfekt zu deinen Zielen und deiner Vision passen.'
    },
    protection: {
      title: 'Keine Interessenkonflikte',
      description: 'Wir schützen dich vor negativen Erfahrungen.'
    },
    security: {
      title: 'Sichere Partnerschaft',
      description: 'Alle Agenturen werden gründlich geprüft und müssen unsere Qualitätsstandards erfüllen.'
    },
    dilemma: {
      title: 'Das Creator-Dilemma',
      description: 'Viele Creator kämpfen mit unzuverlässigen Agenturen, versteckten Gebühren und gebrochenen Versprechen.'
    },
    solution: {
      title: 'Die Lösung',
      description: 'Durch sorgfältige Prüfung und KI-gestütztes Matching verbinden wir dich mit verifizierten Agenturen.'
    }
  },
  process: {
    badge: 'ZUSAMMENARBEIT',
    title: 'Dein 5-Schritte-Weg zum Erfolg',
    subtitle: 'Wir führen dich in fünf klaren Schritten zur perfekten Agentur – transparent, strukturiert und personalisiert.',
    steps: {
      analysis: {
        title: 'Bedarfsanalyse',
        description: 'Kurze Analyse deiner Ziele und Anforderungen.'
      },
      consultation: {
        title: 'Beratung',
        description: 'Persönliches Gespräch über deine Bedürfnisse.'
      },
      search: {
        title: 'Agentursuche',
        description: 'Gezielte Suche nach passenden Partnern.'
      },
      matching: {
        title: 'Matching',
        description: 'Vorstellung ausgewählter Agenturen.'
      },
      support: {
        title: 'After-Match Care',
        description: 'Kontinuierliche Betreuung für deinen Erfolg.'
      }
    },
    cta: {
      question: 'Bereit für den ersten Schritt? Starte jetzt mit MatchMates und finde deine ideale Agentur.'
    }
  },
  comparison: {
    badge: 'VERGLEICH',
    title: 'Warum MatchMates die bessere Wahl ist',
    subtitle: 'Die perfekte Agentur zu finden kann herausfordernd sein. Mit MatchMates vermeidest du Fehler, sparst Zeit und findest den idealen Partner für deinen Erfolg – risikofrei.',
    challenges: {
      title: 'Herausforderungen bei der eigenständigen Agentursuche',
      items: {
        uncertainty: {
          title: 'Unsicherheit bei der Auswahl',
          description: 'Du weißt nicht, ob eine Agentur wirklich professionell ist oder nur leere Versprechungen macht.'
        },
        noFocus: {
          title: 'Kein individueller Fokus',
          description: 'Agenturen behandeln Creator oft wie Nummern statt als Persönlichkeiten.'
        },
        costs: {
          title: 'Intransparente Kosten',
          description: 'Versteckte Gebühren und unklare Preisstrukturen führen oft zu Frust.'
        },
        timeConsuming: {
          title: 'Zeitaufwändige Recherche',
          description: 'Agenturen selbst zu recherchieren und zu vergleichen kostet viel Zeit und Energie.'
        },
        noGuarantee: {
          title: 'Keine Erfolgsgarantie',
          description: 'Auch nach der Agenturwahl bleibt das Ergebnis ungewiss.'
        }
      }
    },
    benefits: {
      title: 'Deine Vorteile mit MatchMates',
      items: {
        vettedAgencies: {
          title: 'Geprüfte & professionelle Agenturen',
          description: 'Jede Agentur durchläuft einen strengen Verifizierungsprozess für höchste Qualität.'
        },
        support: {
          title: 'Individuelle Unterstützung',
          description: 'Wir passen jedes Matching an deine spezifischen Bedürfnisse an.'
        },
        pricing: {
          title: 'Faire & transparente Preise',
          description: 'Keine versteckten Kosten – volle Transparenz von Anfang an.'
        },
        matching: {
          title: 'Schnelles Matching',
          description: 'Finde die perfekte Agentur in nur wenigen Tagen, ohne endlose Recherche.'
        },
        success: {
          title: 'Bewiesener Erfolg',
          description: 'Creator berichten von durchschnittlich 312% Einkommenswachstum nach dem Matching.'
        }
      }
    }
  },
  successStories: {
    badge: 'Was unsere Kunden sagen',
    title: 'Von Herausforderung zu Erfolg',
    subtitle: 'Echte Geschichten von Creatorn, die ihre Karriere mit MatchMates transformiert haben.',
    details: {
      situation: 'Situation',
      goal: 'Ziel',
      result: 'Ergebnis'
    },
    cta: {
      question: 'Bereit, deine Erfolgsgeschichte zu schreiben?',
      button: 'Jetzt Starten - Kostenlos'
    }
  },
  testimonials: {
    firstClient: 'Eine unserer ersten Kundinnen',
    vision: 'Mit MatchMates habe ich endlich die richtige Agentur gefunden, die meine Vision versteht und umsetzt.',
    metrics: {
      revenue: 'Umsatzsteigerung',
      followers: 'Follower in 5 Monaten',
      workHours: 'Weniger Arbeitsstunden / Woche'
    },
    detailed: {
      laura: {
        title: '40.000€ monatlich, aber das Potenzial war größer',
        followers: 'verdoppelte ihr Einkommen mit ihrer neuen Agentur',
        situation: 'Laura verdiente bereits 40.000€ monatlich, wusste aber, dass mit der richtigen Unterstützung viel mehr möglich war.',
        goal: 'Sie suchte eine Agentur, die ihr wahres Potenzial erkennt und ihr hilft, die nächste Stufe zu erreichen.',
        result: 'Mit der richtigen Agentur, vermittelt durch MatchMates, konnte Laura ihre Einnahmen und Reichweite auf das nächste Level bringen.'
      },
      anna: {
        title: 'Mehrere Agenturen, keine Ergebnisse – bis MatchMates',
        followers: 'hatte viele Agenturen ausprobiert',
        situation: 'Nach der Zusammenarbeit mit mehreren Agenturen und ständiger Enttäuschung war Anna kurz davor, die Suche nach dem richtigen Partner aufzugeben.',
        goal: 'Sie wollte endlich eine Agentur finden, die ihre Bedürfnisse versteht und ihr langfristiges Wachstum unterstützt.',
        result: 'Durch MatchMates fand Anna eine Agentur, die wirklich zu ihrer Vision passt. Heute hat sie nicht nur Erfolg, sondern genießt auch ihre Arbeit wieder.'
      },
      sophia: {
        title: 'Von Aufmerksamkeit zu Ignoranz – MatchMates brachte Stabilität',
        followers: 'alte Agentur verlor den Fokus',
        situation: 'Sophia startete mit einer Agentur, die anfangs alles gab. Aber nach einem kleinen Rückgang ihrer Zahlen wurde sie von ihrem Account Manager vernachlässigt.',
        goal: 'Sophia wollte eine Agentur, die konstant an ihrer Seite bleibt – egal ob die Zahlen steigen oder fallen.',
        result: 'MatchMates fand für Sophia eine Agentur, die langfristige Stabilität und kontinuierliche Unterstützung bietet.'
      }
    }
  },
  faq: {
    badge: 'FAQ',
    title: 'Häufig gestellte Fragen',
    subtitle: 'Erhalte Antworten auf häufige Fragen zu MatchMates und unseren Services.',
    questions: {
      what: {
        question: 'Was ist MatchMates?',
        answer: 'MatchMates ist eine Plattform, die Creator mit verifizierten, professionellen Agenturen verbindet. Wir prüfen jede Agentur sorgfältig, um sicherzustellen, dass sie unsere hohen Qualitäts- und Zuverlässigkeitsstandards erfüllt.'
      },
      cost: {
        question: 'Was kostet es?',
        answer: 'MatchMates ist für Creator komplett kostenlos - wir berechnen Creatorn nie etwas für unseren Matching-Service. Unsere Plattform wird von den Agenturen finanziert, die eine Gebühr zahlen, um Teil unseres geprüften Netzwerks zu sein.'
      },
      verification: {
        question: 'Wie verifiziert ihr Agenturen?',
        answer: 'Wir haben einen strengen Verifizierungsprozess, der die Überprüfung von Finanzdaten, Kundenreferenzen und Interviews sowohl mit der Agentur als auch mit deren bestehenden Kunden umfasst.'
      },
      duration: {
        question: 'Wie lange dauert der Matching-Prozess?',
        answer: 'Typischerweise können wir dich innerhalb von 48-72 Stunden mit passenden Agenturen matchen. Allerdings priorisieren wir das Finden des richtigen Matches über Geschwindigkeit.'
      },
      satisfaction: {
        question: 'Was, wenn ich mit meinem Match nicht zufrieden bin?',
        answer: 'Deine Zufriedenheit hat für uns oberste Priorität. Wenn du mit deinem ersten Match nicht zufrieden bist, arbeiten wir mit dir daran, eine bessere Passform zu finden - ohne zusätzliche Kosten.'
      }
    },
    contact: {
      text: 'Noch Fragen? Kontaktiere uns unter',
      email: 'office@match-mates.com'
    }
  },
  blog: {
    title: 'Creator Erfolgs-Blog',
    subtitle: 'Einblicke, Tipps und Strategien für OnlyFans Creator',
    readTime: 'min Lesezeit',
    categories: {
      monetization: 'Monetarisierung',
      content: 'Content',
      'content strategy': 'Content-Strategie',
      engagement: 'Engagement',
      collaboration: 'Zusammenarbeit',
      'success stories': 'Erfolgsgeschichten'
    },
    readMore: 'Weiterlesen',
    popularPosts: 'Beliebte Artikel',
    relatedPosts: 'Ähnliche Artikel',
    backToBlog: 'Zurück zum Blog'
  },
  footer: {
    navigation: 'Navigation',
    legal: 'Rechtliches',
    contact: 'Kontakt',
    description: 'Die Plattform für erfolgreiche Partnerschaften zwischen Models und Agenturen.',
    copyright: '© {year} MatchMates. Alle Rechte vorbehalten.',
    links: {
      transparency: 'Transparenz',
      privacy: 'Datenschutz',
      terms: 'AGB',
      cookies: 'Cookie-Richtlinie',
      imprint: 'Impressum'
    }
  },
  status: {
    pending: 'Ausstehend',
    contacted: 'Kontaktiert',
    completed: 'Abgeschlossen',
    'no_response': 'Keine Antwort',
    'bad_lead': 'Schlechter Lead',
    'call_set': 'Termin vereinbart',
    '1st_followup': '1. Follow-up',
    '2nd_followup': '2. Follow-up',
    '3rd_followup': '3. Follow-up'
  },
  admin: {
    submissions: {
      title: 'Creator Bewerbungen',
      lastStatusChange: 'Letzter Statuswechsel',
      actions: {
        viewDetails: 'Details anzeigen',
        copyLead: 'Lead kopieren',
        delete: 'Löschen'
      },
      filters: {
        all: 'Alle',
        pending: 'Ausstehend',
        contacted: 'Kontaktiert',
        completed: 'Abgeschlossen'
      },
      table: {
        date: 'Datum',
        name: 'Name',
        type: 'Typ',
        instagram: 'Instagram',
        status: 'Status',
        actions: 'Aktionen'
      },
      details: {
        title: 'Lead Details',
        basicInfo: 'Grundinformationen',
        contactInfo: 'Kontaktinformationen',
        answers: 'Antworten',
        close: 'Schließen'
      }
    },
    analytics: {
      title: 'Analytics',
      overview: {
        visitors: 'Besucher',
        pageViews: 'Seitenaufrufe',
        avgTimeOnSite: 'Durchschnittliche Besuchszeit',
        bounceRate: 'Absprungrate'
      },
      timeRanges: {
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
        year: 'Jahr'
      }
    }
  },
  funnel: {
    intro: {
      title: "Lass uns starten",
      subtitle: "Finde die richtige Agentur in nur 2 Minuten!",
      existing: {
        title: "Ich bin bereits Creator und verdiene Geld",
        description: "Lass uns eine Agentur finden, die dir beim weiteren Wachstum hilft"
      },
      new: {
        title: "Ich habe noch keinen OnlyFans Account",
        description: "Wir führen dich durch den erfolgreichen Start"
      }
    },
    progress: {
      question: "Frage",
      of: "von",
      complete: "Abgeschlossen"
    },
    success: {
      title: "Vielen Dank! 🎉",
      subtitle: "Wir haben deine Informationen erhalten und werden sie sorgfältig analysieren, um die perfekte Agentur für dich zu finden.",
      nextSteps: {
        title: "Was passiert als Nächstes? 💭",
        steps: [
          "Wir analysieren dein Profil und deine Anforderungen",
          "Wir bereiten ein Erstgespräch vor",
          "Wir kontaktieren dich innerhalb von 24 Stunden, um die nächsten Schritte zu besprechen"
        ]
      },
      contact: {
        question: "Fragen? Kontaktiere uns unter: 📧",
        email: "office@match-mates.com"
      },
      backButton: "Zurück zur Startseite"
    }
  },
  about: {
    hero: {
      badge: 'Unsere Geschichte',
      title: {
        part1: 'Wir transformieren',
        part2: 'Creator-Erfolg'
      },
      subtitle: 'Von Branchenherausforderungen zu innovativen Lösungen - unsere Geschichte handelt davon, Creator zu ihrem vollen Potenzial zu verhelfen.',
      stats: {
        experience: {
          value: '5+',
          label: 'Jahre Erfahrung'
        },
        success: {
          value: '300+',
          label: 'Creator-Erfolgsgeschichten'
        },
        verification: {
          value: '100%',
          label: 'Agentur-Verifizierungsrate'
        }
      }
    },
    mission: {
      title: 'Warum es MatchMates gibt',
      subtitle: 'Geboren aus echter Branchenerfahrung, sind wir hier, um die größte Herausforderung von Creatorn zu lösen: zuverlässige, kompetente Agenturpartner zu finden.',
      values: {
        protection: {
          title: 'Schutz zuerst',
          description: 'Wir schützen Creator vor schlechten Erfahrungen durch strenge Agenturprüfung und kontinuierliches Monitoring.'
        },
        matching: {
          title: 'Perfekte Matches',
          description: 'Unser Matching-Prozess basiert auf echten Kompatibilitätsfaktoren und bewährten Erfolgsmustern.'
        },
        support: {
          title: 'Persönliche Betreuung',
          description: 'Jeder Creator erhält individuelle Aufmerksamkeit, um den Erfolg der Partnerschaft sicherzustellen.'
        }
      }
    },
    values: {
      title: 'Unsere Werte',
      subtitle: 'Die Prinzipien, die alles leiten, was wir bei MatchMates tun.',
      list: {
        trust: {
          title: 'Vertrauen',
          description: 'Aufbau von Beziehungen basierend auf Transparenz und Zuverlässigkeit.'
        },
        security: {
          title: 'Sicherheit',
          description: 'Schutz unserer Creator und Gewährleistung sicherer Partnerschaften.'
        },
        growth: {
          title: 'Wachstum',
          description: 'Fokus auf kontinuierliche Verbesserung und Erfolg.'
        },
        innovation: {
          title: 'Innovation',
          description: 'Ständige Weiterentwicklung, um Creator-Bedürfnisse zu erfüllen.'
        }
      }
    },
    team: {
      title: 'Rafael - CEO von MatchMates',
      role: 'Gründer & CEO',
      quote1: '"Als ehemaliger Agenturinhaber habe ich die Schwierigkeiten der Creator hautnah miterlebt. Ich traf unzählige talentierte Menschen, die von Agenturen enttäuscht wurden, die alles versprachen, aber nichts lieferten."',
      quote2: '"Diese Erfahrung offenbarte eine kritische Lücke in der Branche – Creator brauchten einen zuverlässigen Weg, um vertrauenswürdige Agenturen zu finden, die ihre Bedürfnisse wirklich verstehen."',
      quote3: '"Deshalb habe ich MatchMates gegründet. Unsere Mission ist einfach: sicherzustellen, dass kein Creator jemals wieder Zeit oder Geld mit der falschen Agentur verschwendet. Wir haben eine Plattform aufgebaut, die jede Agentur sorgfältig prüft und Matches basierend auf echter Kompatibilität erstellt, nicht auf leeren Versprechungen."'
    }
  },
  carousel: {
    achievements: {
      emily: "229% Umsatzwachstum dank der perfekten Agentur durch MatchMates.",
      hannah: "Nach zwei Agenturen endlich die perfekte gefunden – dank MatchMates!",
      anna: "Mein Einkommen hat sich in nur sechs Wochen verdoppelt – dank MatchMates!",
      sarah: "Erste Agentur, perfektes Match – 427% Umsatzwachstum!",
      laura: "Von Frustration zu konstantem Wachstum – MatchMates hat alles verändert.",
      julia: "Endlich eine Agentur, die meine Ziele versteht.",
      olivia: "MatchMates half mir, meine Abonnentenbasis von 150K auf 350K in nur 4 Monaten zu steigern!",
      isabelle: "Durch MatchMates eine Agentur gefunden, die sich wie eine Erweiterung meines Teams anfühlt.",
      lisa: "Von 56.000€ auf über 150.000€ monatlich – MatchMates war der Schlüssel!",
      mia: "Meine Engagement-Rate stieg in nur 3 Monaten um 312%!",
      emma: "MatchMates brachte Klarheit und eine Agentur, die mich wirklich versteht.",
      sophia: "Weniger Stress, mehr Erfolg – MatchMates übernahm die Agentursuche."
    }
  },
  agencies: {
    hero: {
      badge: 'Exklusives Agenturnetzwerk',
      title: 'Werde Teil des exklusiven MatchMates Agentur-Pools',
      subtitle: 'Nur die besten Agenturen haben Zugang zu unserem Netzwerk – bewirb dich jetzt und zeige, dass du dazu gehörst.'
    },
    requirements: {
      badge: 'ANFORDERUNGEN',
      title: 'Unsere Anforderungen - Nur die Besten schaffen es',
      subtitle: 'Wir setzen die höchsten Standards für unsere Partneragenturen, um Creatorn nur die besten Optionen zu bieten.',
      items: {
        revenue: {
          title: 'Bewiesener Umsatz',
          description: 'Demonstriere konstant hohe Leistung und Umsatzgenerierung für deine Kunden.',
          requirement: '500.000€+ durchschnittlicher monatlicher Umsatz'
        },
        performer: {
          title: 'Top-Performer',
          description: 'Mindestens ein Creator mit außergewöhnlichem monatlichen Einkommen.',
          requirement: '1+ Creator mit 80.000€+ monatlich'
        },
        experience: {
          title: 'OFM-Erfahrung',
          description: 'Umfangreiche Erfahrung im Online Funnel Marketing (OFM) und nachweisbare Erfolge.',
          requirement: '3+ Jahre Erfahrung im OFM'
        },
        documentation: {
          title: 'Dokumentation',
          description: 'Umfassende Dokumentation aller Anforderungen und Prozesse.',
          requirement: 'Vollständige Dokumentation aller Anforderungen'
        },
        value: {
          title: 'Einzigartiger Wert',
          description: 'Klare Differenzierung und einzigartiger Ansatz für Creator-Wachstum.',
          requirement: 'Nachweisbares USP im Tech-Call'
        },
        quality: {
          title: 'Prozessqualität',
          description: 'Etablierte und verifizierte Prozesse für Creator- und Team-Zusammenarbeit.',
          requirement: 'Bewährte Prozesse nach Creator- und Team-Member-Calls'
        }
      }
    },
    process: {
      badge: 'BEWERBUNGSPROZESS',
      title: 'Unser Bewerbungsprozess - Schritt für Schritt',
      subtitle: 'Ein gründlicher Prozess, um sicherzustellen, dass nur die besten Agenturen Teil unseres Netzwerks werden.',
      steps: {
        application: {
          title: 'Bewerbung einreichen',
          description: 'Fülle unser Bewerbungsformular aus und lade alle erforderlichen Dokumente hoch, einschließlich Umsatzberichte, CRM-Zugang und Dokumentation.'
        },
        review: {
          title: 'Erste Dokumentenprüfung',
          description: 'Unser Team prüft die eingereichten Dokumente auf Vollständigkeit und Richtigkeit.'
        },
        audit: {
          title: 'Tiefgehende Agenturprüfung',
          description: 'Wir führen Creator-Interviews durch, überprüfen Kontoauszüge, analysieren CRM-Daten und interviewen Teammitglieder zur Prozessvalidierung.'
        },
        usp: {
          title: 'USP-Verifizierung',
          description: 'Dein Alleinstellungsmerkmal wird auf seine Einzigartigkeit und seinen Wert für Creator geprüft.'
        },
        decision: {
          title: 'Finale Entscheidung & Pool-Zugang',
          description: 'Nach erfolgreicher Verifizierung wirst du offiziell Teil des MatchMates Agentur-Pools und erhältst Zugang zu exklusiven Creator-Matches.'
        }
      }
    },
    benefits: {
      badge: 'VORTEILE',
      title: 'Warum dem MatchMates Agentur-Pool beitreten?',
      subtitle: 'Maximiere deinen Erfolg durch unser exklusives Netzwerk.',
      items: {
        matches: {
          title: 'Exklusive Creator-Matches',
          description: 'Zugang zu verifizierten, professionellen Creatorn, die aktiv Agenturpartnerschaften suchen.'
        },
        partnerships: {
          title: 'Stabile Partnerschaften',
          description: 'Langfristige und profitable Zusammenarbeit mit vorqualifizierten Creatorn.'
        },
        reputation: {
          title: 'Verbesserte Reputation',
          description: 'Werde als verifiziertes Mitglied eines exklusiven Netzwerks von Top-Agenturen anerkannt.'
        },
        support: {
          title: 'Support & Beratung',
          description: 'Kontinuierliche Unterstützung und Beratung für gemeinsame Projekte und Wachstumsinitiativen.'
        }
      }
    },
    application: {
      title: 'Jetzt bewerben und MatchMates beitreten',
      subtitle: 'Bereit für den nächsten Schritt? Starte deine Bewerbung und werde Teil des exklusiven MatchMates-Netzwerks.',
      form: {
        agencyName: {
          label: 'Agenturname',
          placeholder: 'Dein Agenturname'
        },
        contactPerson: {
          label: 'Ansprechpartner',
          placeholder: 'Vollständiger Name'
        },
        email: {
          label: 'E-Mail',
          placeholder: 'deine@email.com'
        },
        phone: {
          label: 'Telefon',
          placeholder: '+49 (0) 123 456789'
        },
        description: {
          label: 'Beschreibe deine Agentur und USP',
          placeholder: 'Erzähle uns von deiner Agentur und was sie einzigartig macht...'
        },
        confirmation: {
          info: 'Ich bestätige, dass alle angegebenen Informationen korrekt sind',
          privacy: 'Ich stimme der Datenschutzerklärung zu'
        },
        submit: 'Bewerbung absenden'
      }
    }
  }
} as const;

export { de };