import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '../../../lib/supabase';

export function BlogStats() {
  const { data: blogStats, isLoading } = useQuery({
    queryKey: ['blog-stats'],
    queryFn: async () => {
      // Get total blog views
      const { count: totalBlogViews, error: totalError } = await supabase
        .from('page_views')
        .select('*', { count: 'exact', head: true })
        .ilike('path', '/blog%');

      if (totalError) throw totalError;

      // Get views per blog post
      const { data: viewsByPost, error: viewsError } = await supabase
        .from('page_views')
        .select('path')
        .select('count(*)')
        .ilike('path', '/blog%')
        .group('path')
        .order('count', { ascending: false });

      if (viewsError) throw viewsError;

      // Get unique blog visitors
      const { data: uniqueVisitors, error: visitorsError } = await supabase
        .from('page_views')
        .select('session_id')
        .select('count(distinct session_id)')
        .ilike('path', '/blog%')
        .single();

      if (visitorsError) throw visitorsError;

      return {
        totalViews: totalBlogViews || 0,
        uniqueVisitors: uniqueVisitors?.count || 0,
        viewsByPost: viewsByPost || []
      };
    }
  });

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-6 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-full"></div>
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-semibold mb-4">Blog</h3>
      
      <div className="flex justify-between items-center mb-6">
        <div>
          <p className="text-sm text-gray-500">Blog Aufrufe</p>
          <p className="text-2xl font-bold text-indigo-600">{blogStats?.uniqueVisitors || 0}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Gesamt Aufrufe</p>
          <p className="text-2xl font-bold text-indigo-600">{blogStats?.totalViews || 0}</p>
        </div>
      </div>
      
      <div className="overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Blog Post
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Aufrufe
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {blogStats?.viewsByPost.map((view: any, index: number) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {view.path.replace('/blog/', '')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {view.count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}