import React from 'react';
import { ProcessSteps } from './process/ProcessSteps';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../hooks/useTranslation';

export default function Process() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className="relative py-24">
      {/* Semi-transparent overlay for better text readability */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent" />

      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-2 rounded-full text-white border border-white text-sm font-medium mb-4">
            {t.process.badge}
          </span>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.process.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.process.subtitle}
          </p>
        </div>

        <ProcessSteps />
        
        <div className="text-center mt-12">
          <p className="text-white/90 text-xl mb-8 max-w-2xl mx-auto">
            {t.process.cta.question}
          </p>
          <button 
            onClick={() => navigate('/creator-quiz')}
            className="inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] hover:from-[#FFB0BE] hover:to-[#FF96AB] transition-all duration-300 transform hover:scale-[1.02]"
          >
            <span>Get Matched Today</span>
            <ArrowRight className="w-5 h-5 ml-2" />
          </button>
        </div>
      </div>
    </section>
  );
}