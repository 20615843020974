import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { Language } from '../translations';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('en');
  const location = useLocation();
  const navigate = useNavigate();

  // Set initial language based on URL path
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/de')) {
      setLanguage('de');
    } else {
      setLanguage('en');
    }
  }, [location.pathname]);

  // Update URL when language changes
  useEffect(() => {
    const currentPath = location.pathname;
    if (language === 'de' && !currentPath.startsWith('/de')) {
      navigate(`/de${currentPath}`);
    } else if (language === 'en' && currentPath.startsWith('/de')) {
      navigate(currentPath.replace('/de', ''));
    }
  }, [language, location.pathname, navigate]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext }