import React, { useRef } from 'react';
import { processStepsData } from './processData';
import { ProcessStep } from './ProcessStep';
import { useProcessAnimation } from './useProcessAnimation';
import { useTranslation } from '../../hooks/useTranslation';

export function ProcessSteps() {
  const { containerRef } = useProcessAnimation();
  const { t } = useTranslation();
  const progressLineRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className="relative max-w-7xl mx-auto">
      {/* Mobile Layout */}
      <div className="md:hidden space-y-6">
        {processStepsData.map((step, index) => (
          <div 
            key={index}
            className="opacity-0 translate-y-8 scale-95"
            style={{
              transform: 'translateY(40px) scale(0.95)',
              transition: 'all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1)'
            }}
            data-process-step
          >
            <ProcessStep
              number={index + 1}
              icon={step.icon}
              title={t.process.steps[step.key].title}
              description={t.process.steps[step.key].description}
              isMobile={true}
            />
          </div>
        ))}
      </div>

      {/* Desktop Layout */}
      <div className="hidden md:grid md:grid-cols-5 gap-8 mb-16">
        {/* Progress Line Background */}
        <div className="absolute top-1/2 left-0 w-[80%] -translate-y-1/2 ml-[10%]">
          <div className="relative h-1">
            {/* Background line */}
            <div className="absolute inset-0 bg-white/10 rounded-full" />
            
            {/* Progress line */}
            <div 
              ref={progressLineRef}
              className="absolute top-0 left-0 h-full bg-[#FFC0CB] rounded-full transition-all duration-300"
              style={{ width: '0%' }}
              data-progress-line
            />
          </div>
        </div>

        {/* Process Steps */}
        {processStepsData.map((step, index) => (
          <div
            key={index}
            data-step-container
            className="opacity-0 translate-y-8 scale-95 transition-all duration-500"
          >
            <ProcessStep
              number={index + 1}
              icon={step.icon}
              title={t.process.steps[step.key].title}
              description={t.process.steps[step.key].description}
            />
          </div>
        ))}
      </div>
    </div>
  );
}