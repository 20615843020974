import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { LanguageProvider } from './contexts/LanguageContext';
import { initializeAuth } from './lib/supabase';
import { initializeGoogleTag } from './lib/googleTag';
import AppRoutes from './routes';

// Import CSS after app initialization to prevent render blocking
import './index.css';
import './styles/blog.css';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

// Configure React Query for optimal performance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000 // 10 minutes
    }
  }
});

const App = () => (
  <StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <AppRoutes />
          </LanguageProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </BrowserRouter>
  </StrictMode>
);

// Initialize services before rendering
Promise.all([
  initializeAuth().catch(err => {
    console.warn('Auth initialization failed:', err);
    return false;
  }),
  initializeGoogleTag().catch(err => {
    console.warn('Google Tag initialization failed:', err);
    return null;
  })
]).finally(() => {
  // Determine if we should hydrate or do a fresh render
  const shouldHydrate = rootElement.hasChildNodes() && 
                       process.env.NODE_ENV === 'production' && 
                       window.location.hostname !== 'localhost';

  if (shouldHydrate) {
    hydrateRoot(rootElement, <App />);
  } else {
    createRoot(rootElement).render(<App />);
  }
});