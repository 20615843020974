import { supabase } from './supabase';

const BASE_URL = 'https://match-mates.com';

// Strict typing for sitemap entries
interface SitemapEntry {
  loc: string;
  lastmod?: string;
  changefreq: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  priority: number;
}

// Static routes with their configurations
const STATIC_ROUTES: SitemapEntry[] = [
  {
    loc: '/',
    changefreq: 'weekly',
    priority: 1.0
  },
  {
    loc: '/about',
    changefreq: 'monthly',
    priority: 0.8
  },
  {
    loc: '/agencies',
    changefreq: 'monthly',
    priority: 0.8
  },
  {
    loc: '/creator-quiz',
    changefreq: 'monthly',
    priority: 0.9
  },
  {
    loc: '/blog',
    changefreq: 'daily',
    priority: 0.9
  },
  {
    loc: '/privacy',
    changefreq: 'yearly',
    priority: 0.3
  },
  {
    loc: '/terms',
    changefreq: 'yearly',
    priority: 0.3
  },
  {
    loc: '/cookies',
    changefreq: 'yearly',
    priority: 0.3
  },
  {
    loc: '/transparency',
    changefreq: 'yearly',
    priority: 0.3
  },
  {
    loc: '/imprint',
    changefreq: 'yearly',
    priority: 0.3
  }
];

async function getBlogPosts(): Promise<SitemapEntry[]> {
  try {
    console.log('Starting blog post fetch...');

    // Fetch all published blog posts
    const { data: posts, error } = await supabase
      .from('blog_posts')
      .select('slug, updated_at, published_at')
      .eq('published', true)
      .order('published_at', { ascending: false });

    if (error) {
      console.error('Error fetching blog posts:', error);
      throw error;
    }

    return (posts || []).map(post => ({
      loc: `/blog/${post.slug}`,
      lastmod: post.updated_at || post.published_at || new Date().toISOString(),
      changefreq: 'weekly' as const,
      priority: 0.7
    }));
  } catch (error) {
    console.error('Failed to fetch blog posts:', error);
    return [];
  }
}

// Create XML entry for a single URL with validation
function createUrlEntry(entry: SitemapEntry): string {
  try {
    const { loc, lastmod, changefreq, priority } = entry;
    const fullUrl = `${BASE_URL}${loc}`;
    
    // Validate URL
    try {
      new URL(fullUrl);
    } catch (e) {
      console.error(`Invalid URL: ${fullUrl}`);
      return '';
    }

    // Validate and format lastmod date
    let formattedDate = '';
    if (lastmod) {
      try {
        formattedDate = new Date(lastmod).toISOString().split('T')[0];
      } catch (e) {
        console.warn(`Invalid date format for ${fullUrl}: ${lastmod}`);
      }
    }

    return `
  <url>
    <loc>${fullUrl}</loc>${formattedDate ? `
    <lastmod>${formattedDate}</lastmod>` : ''}
    <changefreq>${changefreq}</changefreq>
    <priority>${priority.toFixed(1)}</priority>
  </url>`.trim();
  } catch (error) {
    console.error('Error creating URL entry:', error);
    return '';
  }
}

// Generate complete sitemap with enhanced error handling
export async function generateSitemap(): Promise<string> {
  console.log('Starting sitemap generation...');

  try {
    // 1. Get blog posts with timing
    const startTime = Date.now();
    console.log('Fetching blog posts...');
    const blogEntries = await getBlogPosts();
    console.log(`Found ${blogEntries.length} blog posts in ${Date.now() - startTime}ms`);

    // 2. Combine all entries
    const allEntries = [...STATIC_ROUTES, ...blogEntries];
    console.log(`Total entries to process: ${allEntries.length}`);

    // 3. Generate entries for both languages with validation
    const urlEntries = allEntries.flatMap(entry => {
      try {
        // Create English version
        const enEntry = createUrlEntry(entry);
        
        // Create German version (special handling for root)
        const deEntry = createUrlEntry({
          ...entry,
          loc: entry.loc === '/' ? '/de' : `/de${entry.loc}`
        });

        return [enEntry, deEntry].filter(Boolean);
      } catch (error) {
        console.error(`Error processing entry ${entry.loc}:`, error);
        return [];
      }
    }).filter(Boolean);

    console.log(`Generated ${urlEntries.length} valid URL entries`);

    // 4. Create final XML with proper formatting
    const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
  http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${urlEntries.join('\n')}
</urlset>`;

    // Validate final XML
    if (!sitemap.includes('<?xml') || !sitemap.includes('</urlset>')) {
      throw new Error('Generated sitemap is invalid');
    }

    console.log('Sitemap generation completed successfully');
    return sitemap;
  } catch (error) {
    console.error('Error generating sitemap:', error);
    // Return a minimal valid sitemap in case of error
    return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>${BASE_URL}/</loc>
    <changefreq>daily</changefreq>
    <priority>1.0</priority>
  </url>
</urlset>`;
  }
}