import type { Translations } from './types';

export const en: Translations = {
  common: {
    startNow: "Start Now - It's Free"
  },
  nav: {
    aboutUs: 'About Us',
    forCreators: 'For Creators',
    forAgencies: 'For Agencies',
    successStories: 'Success Stories',
    blog: 'Blog'
  },
  hero: {
    trusted: 'Trusted by 300+ Creators',
    title: {
      part1: 'Connecting',
      part2: 'Creators',
      part3: 'with',
      part4: 'Trusted Agencies'
    },
    subtitle: 'Say goodbye to unreliable agencies. Our platform connects you with vetted partners who support your success – completely free.',
    benefits: {
      verification: '100% Verified Agencies - Every partner thoroughly vetted',
      growth: 'Proven Growth Results - Average 273% revenue increase',
      risk: 'Risk-Free Matching - No fees or commitments'
    }
  },
  stats: {
    averageGrowth: 'Average Growth',
    verifiedPartners: 'Verified Partners'
  },
  features: {
    title: 'Why MatchMates?',
    matching: {
      title: 'Perfect Matching',
      description: 'We connect you with agencies that perfectly align with your goals and vision.'
    },
    protection: {
      title: 'No Conflict of Interest',
      description: 'We protect you from negative experiences.'
    },
    security: {
      title: 'Secure Partnership',
      description: 'All agencies are thoroughly vetted and must meet our quality standards.'
    }
  },
  process: {
    badge: 'COLLABORATION',
    title: 'Your 5-Step Path to Success',
    subtitle: 'We guide you to the perfect agency in five clear steps – transparent, structured, and personalized.',
    steps: {
      analysis: {
        title: 'Needs Analysis',
        description: 'Brief analysis of your goals and requirements.'
      },
      consultation: {
        title: 'Consultation',
        description: 'Personal discussion about your needs.'
      },
      search: {
        title: 'Agency Search',
        description: 'Targeted search for matching partners.'
      },
      matching: {
        title: 'Matching',
        description: 'Introduction to selected agencies.'
      },
      support: {
        title: 'After-Match Care',
        description: 'to ensure your well-being.'
      }
    },
    cta: {
      question: 'Ready to take the first step? Start with MatchMates now and find your ideal agency.'
    }
  },
  comparison: {
    badge: 'COMPARISON',
    title: 'Why MatchMates is the Better Choice',
    subtitle: 'Finding the perfect agency can be challenging. With MatchMates, you avoid mistakes, save time, and find the ideal partner for your success – risk-free.',
    challenges: {
      title: 'Challenges of Independent Agency Search',
      items: {
        uncertainty: {
          title: 'Uncertainty in Selection',
          description: "You don't know if an agency is truly professional or just making empty promises."
        },
        noFocus: {
          title: 'No Individual Focus',
          description: 'Agencies often treat creators like numbers rather than personalities.'
        },
        costs: {
          title: 'Non-transparent Costs',
          description: 'Hidden fees and unclear pricing structures often lead to frustration.'
        },
        timeConsuming: {
          title: 'Time-Consuming Research',
          description: 'Researching and comparing agencies yourself takes a lot of time and effort.'
        },
        noGuarantee: {
          title: 'No Success Guarantee',
          description: 'Even after selecting an agency, the outcome remains uncertain.'
        }
      }
    },
    benefits: {
      title: 'Your Benefits with MatchMates',
      items: {
        vettedAgencies: {
          title: 'Vetted & Professional Agencies',
          description: 'Every agency undergoes a strict verification process for highest quality.'
        },
        support: {
          title: 'Individual Support',
          description: 'We customize each match to your specific needs.'
        },
        pricing: {
          title: 'Fair & Transparent Pricing',
          description: 'No hidden costs – full transparency from the start.'
        },
        matching: {
          title: 'Quick Matching',
          description: 'Find the perfect agency in just a few days, without endless research.'
        },
        success: {
          title: 'Proven Success',
          description: 'Creators report an average of 312% income growth after matching.'
        }
      }
    },
    cta: {
      question: 'Why waste time and energy? Let us find the perfect agency for you – simple, fast, and free!'
    }
  },
  successStories: {
    badge: 'What our Clients say',
    title: 'From Struggle to Success',
    subtitle: 'Real stories from creators who transformed their careers with MatchMates.',
    details: {
      situation: 'Situation',
      goal: 'Goal',
      result: 'Result'
    },
    cta: {
      question: 'Ready to write your success story?',
      button: "Start Now - It's Free"
    }
  },
  carousel: {
    achievements: {
      emily: "229% revenue growth thanks to the perfect agency through MatchMates.",
      hannah: "After two agencies, I finally found the perfect match – thanks to MatchMates!",
      anna: "My income doubled in just six weeks – thanks to MatchMates!",
      sarah: "First agency, perfect match – 427% revenue growth!",
      laura: "From frustration to consistent growth – MatchMates changed everything.",
      julia: "Finally, an agency that understands my goals.",
      olivia: "MatchMates helped me grow my subscriber base from 150K to 350K in just 4 months!",
      isabelle: "Found an agency through MatchMates that feels like an extension of my team.",
      lisa: "From $56K to over $150K monthly – MatchMates was the key!",
      mia: "My engagement rate skyrocketed by 312% in just 3 months!",
      emma: "MatchMates brought clarity and an agency that truly understands me.",
      sophia: "Less stress, more success – MatchMates handled the agency search."
    }
  },
  testimonials: {
    detailed: {
      laura: {
        title: '$40K Monthly, But the Potential Was Higher',
        followers: 'doubled her income with her new agency',
        situation: 'Laura was already earning $40,000 monthly but knew much more was possible with the right support.',
        goal: 'She wanted an agency that recognizes her true potential and helps her reach the next level.',
        result: 'With the right agency, matched through MatchMates, Laura was able to take her earnings and reach to the next level.'
      },
      anna: {
        title: 'Multiple Agencies, Zero Results – Until MatchMates',
        followers: 'tried many agencies before',
        situation: 'After working with multiple agencies and facing constant disappointment, Anna was close to giving up on finding the right partner for her business.',
        goal: 'She wanted to finally find an agency that understands her needs and supports her long-term growth.',
        result: 'Through MatchMates, Anna found an agency that truly fits her vision. Today she not only has success but also enjoys her work again.'
      },
      sophia: {
        title: 'From Attention to Ignorance – MatchMates Brought Stability',
        followers: 'old agency lost focus',
        situation: 'Sophia started with an agency that gave everything at first. But after a small decline in her numbers, she was neglected by her account manager.',
        goal: 'Sophia wanted an agency that stays by her side consistently – whether numbers are rising or falling.',
        result: 'MatchMates found an agency for Sophia that provides long-term stability and continuous support.'
      }
    }
  },
  faq: {
    badge: 'Frequently Asked Questions',
    title: 'Frequently Asked Questions',
    subtitle: 'Get answers to common questions about MatchMates and our services.',
    questions: {
      what: {
        question: 'What is MatchMates?',
        answer: 'MatchMates is a platform that connects creators with verified, professional agencies. We carefully vet each agency to ensure they meet our high standards for quality and reliability.'
      },
      cost: {
        question: 'How much does it cost?',
        answer: 'MatchMates is completely free for creators - we never charge creators for our matching service. Our platform is funded by the agencies who pay a fee to be part of our vetted network.'
      },
      verification: {
        question: 'How do you verify agencies?',
        answer: 'We have a rigorous verification process that includes checking financial records, client testimonials, and conducting interviews with both the agency and their existing clients.'
      },
      duration: {
        question: 'How long does the matching process take?',
        answer: 'Typically, we can match you with suitable agencies within 48-72 hours. However, we prioritize finding the right match over speed.'
      },
      satisfaction: {
        question: "What if I'm not satisfied with my match?",
        answer: "Your satisfaction is our priority. If you're not happy with your initial match, we'll work with you to find a better fit at no additional cost."
      }
    },
    contact: {
      text: 'Still have questions? Contact us at',
      email: 'office@match-mates.com'
    }
  },
  blog: {
    title: 'Creator Success Blog',
    subtitle: 'Insights, tips, and strategies for OnlyFans creators',
    readTime: 'min read',
    categories: {
      monetization: 'Monetization',
      content: 'Content',
      'content strategy': 'Content Strategy',
      engagement: 'Engagement',
      collaboration: 'Collaboration',
      'success stories': 'Success Stories'
    }
  },
  about: {
    hero: {
      badge: 'Our Journey',
      title: {
        part1: 'Transforming',
        part2: 'Creator Success'
      },
      subtitle: 'From industry challenges to innovative solutions, our story is about empowering creators to reach their full potential.',
      stats: {
        experience: {
          value: '5+',
          label: 'Years Experience'
        },
        success: {
          value: '300+',
          label: 'Creator Success Stories'
        },
        verification: {
          value: '100%',
          label: 'Agency Verification Rate'
        }
      }
    },
    mission: {
      title: 'Why MatchMates Exists',
      subtitle: 'Born from real industry experience, we\'re here to solve the biggest challenge creators face: finding reliable, competent agency partners.',
      values: {
        protection: {
          title: 'Protection First',
          description: 'We shield creators from bad experiences through rigorous agency vetting and continuous monitoring.'
        },
        matching: {
          title: 'Perfect Matches',
          description: 'Our matching process is based on real compatibility factors and proven success patterns.'
        },
        support: {
          title: 'Personal Support',
          description: 'Every creator gets dedicated attention to ensure their partnership succeeds.'
        }
      }
    },
    values: {
      title: 'Our Values',
      subtitle: 'The principles that guide everything we do at MatchMates.',
      list: {
        trust: {
          title: 'Trust',
          description: 'Building relationships based on transparency and reliability.'
        },
        security: {
          title: 'Security',
          description: 'Protecting our creators and ensuring safe partnerships.'
        },
        growth: {
          title: 'Growth',
          description: 'Focusing on continuous improvement and success.'
        },
        innovation: {
          title: 'Innovation',
          description: 'Constantly evolving to meet creator needs.'
        }
      }
    },
    team: {
      title: 'Rafael - CEO of MatchMates',
      role: 'Founder & CEO',
      quote1: '"As a former agency owner, I witnessed firsthand the struggles creators faced. I met countless talented individuals who had been let down by agencies that promised everything but delivered nothing."',
      quote2: '"This experience revealed a critical gap in the industry – creators needed a reliable way to find trustworthy agencies that truly understood their needs."',
      quote3: '"That\'s why I created MatchMates. Our mission is simple: ensure no creator ever wastes time or money on the wrong agency again. We\'ve built a platform that carefully vets every agency and creates matches based on real compatibility, not empty promises."'
    }
  },
  footer: {
    navigation: 'Navigation',
    legal: 'Legal',
    contact: 'Contact',
    description: 'The platform for successful partnerships between creators and agencies.',
    copyright: '© {year} MatchMates. All rights reserved.',
    links: {
      transparency: 'Transparency',
      privacy: 'Privacy Policy',
      terms: 'Terms of Service',
      cookies: 'Cookie Policy',
      imprint: 'Imprint'
    }
  },
  agencies: {
    hero: {
      badge: 'Exclusive Agency Network',
      title: 'Join the Exclusive MatchMates Agency Pool',
      subtitle: 'Only the best agencies have access to our network – apply now and show that you belong.'
    },
    requirements: {
      badge: 'REQUIREMENTS',
      title: 'Our Requirements - Only the Best Make It',
      subtitle: 'We set the highest standards for our partner agencies to ensure creators get only the best options.',
      items: {
        revenue: {
          title: 'Proven Revenue',
          description: 'Demonstrate consistent high performance and revenue generation for your clients.',
          requirement: '$500,000+ average monthly revenue'
        },
        performer: {
          title: 'Top Performer',
          description: 'Have at least one creator achieving exceptional monthly income.',
          requirement: '1+ creator with $80,000+ monthly'
        },
        experience: {
          title: 'OFM Experience',
          description: 'Extensive experience in Online Funnel Marketing (OFM) and proven track record.',
          requirement: '3+ years experience in OFM'
        },
        documentation: {
          title: 'Documentation',
          description: 'Comprehensive documentation of all requirements and processes.',
          requirement: 'Complete documentation of all Requirements'
        },
        value: {
          title: 'Unique Value',
          description: 'Clear differentiation and unique approach to creator growth.',
          requirement: 'Verifiable USP in a Tech-Call'
        },
        quality: {
          title: 'Process Quality',
          description: 'Established and verified processes for creator and team collaboration.',
          requirement: 'Proven processes after Creator and Team-Member Calls'
        }
      }
    },
    process: {
      badge: 'APPLICATION PROCESS',
      title: 'Our Application Process - Step by Step',
      subtitle: 'A thorough process to ensure only the best agencies become part of our network.',
      steps: {
        application: {
          title: 'Submit Application',
          description: 'Fill out our application form and upload all required documents, including revenue reports, CRM access, and documentation.'
        },
        review: {
          title: 'Initial Document Review',
          description: 'Our team reviews the submitted documents for completeness and accuracy.'
        },
        audit: {
          title: 'In-Depth Agency Audit',
          description: 'We conduct creator interviews, verify bank statements, analyze CRM data, and interview team members to validate processes.'
        },
        usp: {
          title: 'USP Verification',
          description: 'Your unique selling proposition is evaluated for its distinctiveness and value to creators.'
        },
        decision: {
          title: 'Final Decision & Pool Access',
          description: 'Upon successful verification, you officially become part of the MatchMates Agency Pool and gain access to exclusive creator matches.'
        }
      }
    },
    benefits: {
      badge: 'BENEFITS',
      title: 'Why Join the MatchMates Agency Pool?',
      subtitle: 'Maximize your success through our exclusive network.',
      items: {
        matches: {
          title: 'Exclusive Creator Matches',
          description: 'Access to verified, professional creators actively seeking agency partnerships.'
        },
        partnerships: {
          title: 'Stable Partnerships',
          description: 'Long-term and profitable collaborations with pre-qualified creators.'
        },
        reputation: {
          title: 'Enhanced Reputation',
          description: 'Be recognized as a verified member of an exclusive network of top agencies.'
        },
        support: {
          title: 'Support & Guidance',
          description: 'Ongoing support and consultation for joint projects and growth initiatives.'
        }
      }
    },
    application: {
      title: 'Apply Now and Join MatchMates',
      subtitle: 'Ready to take the next step? Start your application and become part of the exclusive MatchMates network.',
      form: {
        agencyName: {
          label: 'Agency Name',
          placeholder: 'Your agency name'
        },
        contactPerson: {
          label: 'Contact Person',
          placeholder: 'Full name'
        },
        email: {
          label: 'Email',
          placeholder: 'your@email.com'
        },
        phone: {
          label: 'Phone',
          placeholder: '+1 (555) 000-0000'
        },
        description: {
          label: 'Describe Your Agency and USP',
          placeholder: 'Tell us about your agency and what makes it unique...'
        },
        documents: {
          label: 'Upload Documents',
          images: {
            title: 'Drop images or click to upload',
            description: 'PNG, JPG up to 10MB each'
          },
          pdf: {
            title: 'Drop PDF documents or click to upload',
            description: 'PDF files up to 20MB each'
          }
        },
        confirmation: {
          info: 'I confirm that all provided information is correct',
          privacy: 'I agree to the privacy policy'
        },
        submit: 'Submit Application'
      }
    }
  }
} as const;