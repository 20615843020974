import { FunnelPath } from '../../../types/funnel';

export const newCreatorPath: FunnelPath = {
  id: 'new',
  title: 'New Creator Path',
  steps: [
    {
      id: 'motivation',
      type: 'choice',
      title: 'Why are you interested in starting OnlyFans?',
      subtitle: 'Whatever your goal is, we\'re here to support you.',
      question: 'Select your main motivation:',
      options: [
        { label: 'Increase my income', value: 'income' },
        { label: 'Become more independent', value: 'independence' },
        { label: 'Want to explore my potential', value: 'potential' }
      ]
    },
    {
      id: 'time_commitment',
      type: 'choice',
      title: 'How much time can you dedicate to OnlyFans each day?',
      subtitle: 'Most of our creators work around 2 hours a day, but the more time you have, the more you can achieve!',
      question: 'Select your available time:',
      options: [
        { label: '1 hour per day', value: '1h' },
        { label: '1-3 hours per day', value: '1-3h' },
        { label: 'Full-time', value: 'full' }
      ]
    },
    {
      id: 'social_presence',
      type: 'choice',
      title: 'Do you already have a community or following on social media?',
      subtitle: 'We work with creators at all levels to help them grow.',
      question: 'Select your current situation:',
      options: [
        { label: 'Yes, I have a large and active community', value: 'large' },
        { label: 'Yes, but I\'d like to grow it further', value: 'growing' },
        { label: 'No, but I\'m ready to start from scratch', value: 'new' }
      ]
    },
    {
      id: 'agency_goals',
      type: 'choice',
      title: 'What are your main goals for working with an agency?',
      subtitle: 'Your goals matter, and we\'re here to help you achieve them!',
      question: 'Select up to 3 options that matter most to you:',
      options: [
        { label: 'Increase my income', value: 'income' },
        { label: 'Build a bigger following', value: 'following' },
        { label: 'Work more efficiently and save time', value: 'efficiency' }
      ]
    },
    {
      id: 'agency_expectations',
      type: 'text',
      title: 'What do you expect from an agency?',
      subtitle: 'Feel free to share any specific expectations or support you\'re looking for.',
      question: 'Tell us about your expectations...',
      fields: [
        {
          name: 'expectations',
          type: 'textarea',
          label: 'Your expectations',
          placeholder: 'Share your thoughts... (optional)',
          required: false
        }
      ]
    },
    {
      id: 'anonymity',
      type: 'choice',
      title: 'How important is it for you to stay anonymous?',
      subtitle: 'Don\'t worry! We\'re committed to protecting your privacy and can find solutions that work for you.',
      question: 'Select your preference:',
      options: [
        { label: 'I\'m okay with being public', value: 'public' },
        { label: 'Privacy is very important to me', value: 'private' }
      ]
    },
    {
      id: 'social_media',
      type: 'social',
      title: 'Where can we find you on social media?',
      subtitle: 'We\'d love to get a better sense of who you are. Don\'t worry—it\'s just to get to know you better.',
      question: 'Share your social media:',
      fields: [
        {
          name: 'instagram',
          type: 'text',
          label: 'Instagram username',
          placeholder: '@username',
          required: false
        }
      ]
    }
  ]
};