import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { backgrounds } from '../constants/backgrounds';

export default function ImprintPage() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className={`${backgrounds.hero} flex-grow pt-32 pb-16`}>
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto bg-white/10 backdrop-blur-md rounded-2xl p-8 border border-white/20">
            <h1 className="text-3xl font-bold text-white mb-8">Imprint</h1>
            
            <div className="space-y-6 text-white/90">
              <section>
                <h2 className="text-xl font-semibold mb-3">Company Information</h2>
                <div className="space-y-2">
                  <p>MatchMates LLC</p>
                  <p>3833 POWERLINE RD SUITE 201</p>
                  <p>FORT LAUDERDALE, FL. US 33309</p>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3">Legal Notice</h2>
                <p>
                  This imprint complies with US legal requirements for business 
                  identification and contact information disclosure.
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}