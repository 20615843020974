import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, ArrowRight } from 'lucide-react';
import { IMAGES } from '../constants/images';
import { backgrounds } from '../constants/backgrounds';
import { useTranslation } from '../hooks/useTranslation';
import { useLanguage } from '../contexts/LanguageContext';
import { getLanguagePath } from '../lib/utils/languageUtils';
import { MobileMenu } from './navigation/MobileMenu';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();
  const { language } = useLanguage();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const navigateToHome = () => {
    navigate('/', { replace: true });
  };

  const navigateToSuccessStories = () => {
    // If already on home page, scroll to section
    if (location.pathname === '/' || location.pathname === '/de') {
      const element = document.getElementById('testimonials');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Navigate to home page with state to scroll after navigation
      navigate('/', { 
        state: { scrollToSection: 'testimonials' },
        replace: true 
      });
    }
  };

  return (
    <header 
      className={`fixed top-0 left-0 w-full z-50 px-6 pt-4 transition-all duration-300 ${
        isScrolled ? 'pt-2' : 'pt-4'
      }`} 
      role="banner"
    >
      <div className="container mx-auto">
        <nav 
          className={`bg-white/10 backdrop-blur-md border border-white/20 rounded-2xl px-6 flex items-center justify-between transition-all duration-300 ${
            isScrolled ? 'h-[50px] py-2' : 'h-[60px] py-3'
          }`}
          role="navigation"
          aria-label="Main navigation"
        >
          {/* Logo/Home Button */}
          <button 
            onClick={navigateToHome}
            className={`flex items-center transition-all duration-300 ${
              isScrolled ? 'h-7' : 'h-8'
            }`}
            aria-label="Go to homepage"
          >
            <img 
              src={IMAGES.logo} 
              alt="MatchMates Logo"
              className="h-full w-auto"
              loading="eager"
              fetchpriority="high"
            />
          </button>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`md:hidden w-8 h-8 flex items-center justify-center text-white transition-transform duration-300 ${
              isMenuOpen ? 'rotate-90' : 'rotate-0'
            }`}
            aria-expanded={isMenuOpen}
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? (
              <X className="w-5 h-5" />
            ) : (
              <Menu className="w-5 h-5" />
            )}
          </button>

          {/* Desktop Navigation */}
          <div className={`hidden md:flex items-center transition-all duration-300 ${
            isScrolled ? 'space-x-[20px]' : 'space-x-[25px]'
          }`}>
            {/* Home Button */}
            <button 
              onClick={navigateToHome}
              className="text-[15px] font-[500] text-white hover:text-white/80 transition-colors relative after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-white after:transition-all hover:after:w-full"
            >
              Home
            </button>

            {/* Regular Links */}
            <Link 
              to={`${getLanguagePath(language)}/agencies`}
              className="text-[15px] font-[500] text-white hover:text-white/80 transition-colors relative after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-white after:transition-all hover:after:w-full"
            >
              {t.nav.forAgencies}
            </Link>

            {/* Success Stories Button */}
            <button 
              onClick={navigateToSuccessStories}
              className="text-[15px] font-[500] text-white hover:text-white/80 transition-colors relative after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-white after:transition-all hover:after:w-full"
            >
              {t.nav.successStories}
            </button>

            <Link 
              to={`${getLanguagePath(language)}/about`}
              className="text-[15px] font-[500] text-white hover:text-white/80 transition-colors relative after:absolute after:left-0 after:bottom-[-2px] after:w-0 after:h-[2px] after:bg-white after:transition-all hover:after:w-full"
            >
              {t.nav.aboutUs}
            </Link>

            {/* CTA Button */}
            <Link
              to={`${getLanguagePath(language)}/creator-quiz`}
              className={`inline-flex items-center text-[15px] font-[600] text-white rounded-xl bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] hover:from-[#FFB0BE] hover:to-[#FF96AB] transition-all duration-300 transform hover:scale-[1.02] ${
                isScrolled ? 'px-3 py-1.5' : 'px-4 py-2'
              }`}
            >
              <span>Get Matched Today</span>
              <ArrowRight className="w-5 h-5 ml-2" />
            </Link>
          </div>
        </nav>
      </div>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onHomeClick={navigateToHome}
        onSuccessStoriesClick={navigateToSuccessStories}
      />
    </header>
  );
}