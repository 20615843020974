export function gtagReportConversion(url?: string) {
  if (window.gtag) {
    const callback = function () {
      if (typeof(url) != 'undefined') {
        window.location.href = url;
      }
    };
    
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11532404213/d8_WCMrLlIsaEPWLivsq',
      'event_callback': callback
    });
  }
  return false;
}