import React from 'react';
import { AudioPlayer } from '../audio/AudioPlayer';
import { useTranslation } from '../../hooks/useTranslation';
import { useCachedImage } from '../../lib/cache/hooks/useCachedImage';

interface DetailedTestimonialCardProps {
  testimonialKey: string;
  name: string;
  image: string;
  audioUrl: string;
  isMobile?: boolean;
}

export function DetailedTestimonialCard({
  testimonialKey,
  name,
  image,
  audioUrl,
  isMobile
}: DetailedTestimonialCardProps) {
  const { t } = useTranslation();
  const testimonial = t.testimonials.detailed[testimonialKey as keyof typeof t.testimonials.detailed];
  const imageLoaded = useCachedImage(image);

  const HighlightedHeading = ({ children }: { children: React.ReactNode }) => (
    <h4 className="relative inline-flex group">
      <span className="relative z-10 text-[#FFC0CB] font-medium px-2 py-1">
        {children}
        {/* Glow effect container with specific dimensions */}
        <span className="absolute inset-0 -z-10">
          {/* Primary intense core gradient */}
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[70%] opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{
              background: 'radial-gradient(ellipse at center, #003366 0%, rgba(0, 51, 102, 0.8) 20%, rgba(0, 51, 102, 0.4) 40%, rgba(0, 51, 102, 0.1) 60%, transparent 80%)',
              filter: 'blur(4px)'
            }}
          />
          {/* Secondary softer gradient for depth */}
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[70%]"
            style={{
              background: 'radial-gradient(ellipse at center, #003366 0%, rgba(0, 51, 102, 0.6) 30%, rgba(0, 51, 102, 0.2) 50%, rgba(0, 51, 102, 0.05) 70%, transparent 90%)',
              filter: 'blur(3px)'
            }}
          />
          {/* Outer glow for smooth fade */}
          <span 
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] h-[70%]"
            style={{
              background: 'radial-gradient(ellipse at center, rgba(0, 51, 102, 0.3) 0%, rgba(0, 51, 102, 0.1) 40%, transparent 100%)',
              filter: 'blur(8px)'
            }}
          />
        </span>
      </span>
    </h4>
  );

  if (isMobile) {
    return (
      <div className="bg-white/20 backdrop-blur-md rounded-2xl overflow-hidden hover:bg-white/25 transition-all duration-300 h-full">
        <div className="relative h-48">
          <img 
            src={image} 
            alt={name}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            loading="eager"
          />
          {!imageLoaded && (
            <div className="absolute inset-0 bg-white/5 animate-pulse" />
          )}
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
          <div className="absolute bottom-4 left-4 right-4">
            <h3 className="text-xl font-bold text-white mb-1">{testimonial.title}</h3>
            <p className="text-white">{testimonial.followers}</p>
          </div>
        </div>

        <div className="p-6 space-y-4">
          <div>
            <HighlightedHeading>{t.successStories.details.situation}</HighlightedHeading>
            <p className="text-white/90 text-sm mt-2">{testimonial.situation}</p>
          </div>

          <div>
            <HighlightedHeading>{t.successStories.details.result}</HighlightedHeading>
            <p className="text-white/90 text-sm mt-2">{testimonial.result}</p>
          </div>

          {audioUrl && (
            <div className="mt-4">
              <AudioPlayer audioUrl={audioUrl} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white/20 backdrop-blur-md rounded-2xl overflow-hidden hover:bg-white/25 transition-all duration-300">
      <div className="grid md:grid-cols-2 gap-8">
        {/* Left Content */}
        <div className="p-8">
          <h3 className="text-2xl font-bold text-white mb-6">{testimonial.title}</h3>
          
          <div className="flex items-center space-x-3 mb-8">
            <div>
              <p className="text-white font-medium">{name}</p>
              <p className="text-white">{testimonial.followers}</p>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <HighlightedHeading>{t.successStories.details.situation}</HighlightedHeading>
              <p className="text-white/90 mt-2">{testimonial.situation}</p>
            </div>

            <div>
              <HighlightedHeading>{t.successStories.details.goal}</HighlightedHeading>
              <p className="text-white/90 mt-2">{testimonial.goal}</p>
            </div>

            <div>
              <HighlightedHeading>{t.successStories.details.result}</HighlightedHeading>
              <p className="text-white/90 mt-2">{testimonial.result}</p>
            </div>
          </div>
        </div>

        {/* Right Content */}
        <div className="relative">
          <div className="h-full">
            <img 
              src={image} 
              alt={name}
              className={`w-full h-full object-cover transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              loading="eager"
            />
            {!imageLoaded && (
              <div className="absolute inset-0 bg-white/5 animate-pulse" />
            )}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black opacity-75" />
          </div>

          {/* Audio Player */}
          {audioUrl && (
            <div className="absolute bottom-8 left-8 right-8">
              <AudioPlayer audioUrl={audioUrl} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}