import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

export function ComparisonCTA() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="text-center mt-8">
      <button 
        onClick={() => navigate('/creator-quiz')}
        className="group inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] transition-all duration-300 transform hover:scale-[1.02]"
      >
        <span>Contact MatchMates Today</span>
        <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-2 transition-transform duration-300" />
      </button>
    </div>
  );
}