import React from 'react';

interface TimeRangeSelectorProps {
  range: 'day' | 'week' | 'year';
  onChange: (range: 'day' | 'week' | 'year') => void;
}

export function TimeRangeSelector({ range, onChange }: TimeRangeSelectorProps) {
  return (
    <div className="inline-flex rounded-lg border border-gray-200 bg-white p-1">
      <button
        onClick={() => onChange('day')}
        className={`inline-flex items-center px-4 py-2 rounded-lg ${
          range === 'day'
            ? 'bg-indigo-600 text-white'
            : 'hover:bg-gray-50'
        }`}
      >
        Day
      </button>
      <button
        onClick={() => onChange('week')}
        className={`inline-flex items-center px-4 py-2 rounded-lg ${
          range === 'week'
            ? 'bg-indigo-600 text-white'
            : 'hover:bg-gray-50'
        }`}
      >
        Week
      </button>
      <button
        onClick={() => onChange('year')}
        className={`inline-flex items-center px-4 py-2 rounded-lg ${
          range === 'year'
            ? 'bg-indigo-600 text-white'
            : 'hover:bg-gray-50'
        }`}
      >
        Year
      </button>
    </div>
  );
}