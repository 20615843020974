import React from 'react';
import { ArrowRight } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

export function AgencyApplication() {
  const { t } = useTranslation();

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white/10 backdrop-blur-md rounded-2xl p-8 md:p-12 border border-white/20">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">
              {t.agencies.application.title}
            </h2>
            <p className="text-white/80">
              {t.agencies.application.subtitle}
            </p>
          </div>

          <form className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              {/* Agency Name */}
              <div>
                <label className="block text-white mb-2">
                  {t.agencies.application.form.agencyName.label}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
                  placeholder={t.agencies.application.form.agencyName.placeholder}
                />
              </div>

              {/* Contact Person */}
              <div>
                <label className="block text-white mb-2">
                  {t.agencies.application.form.contactPerson.label}
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
                  placeholder={t.agencies.application.form.contactPerson.placeholder}
                />
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {/* Email */}
              <div>
                <label className="block text-white mb-2">
                  {t.agencies.application.form.email.label}
                </label>
                <input
                  type="email"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
                  placeholder={t.agencies.application.form.email.placeholder}
                />
              </div>

              {/* Phone */}
              <div>
                <label className="block text-white mb-2">
                  {t.agencies.application.form.phone.label}
                </label>
                <input
                  type="tel"
                  className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
                  placeholder={t.agencies.application.form.phone.placeholder}
                />
              </div>
            </div>

            {/* Description */}
            <div>
              <label className="block text-white mb-2">
                {t.agencies.application.form.description.label}
              </label>
              <textarea
                className="w-full px-4 py-3 rounded-lg bg-white/5 border border-white/20 text-white focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all h-32 resize-none"
                placeholder={t.agencies.application.form.description.placeholder}
              />
            </div>

            {/* Confirmation Checkboxes */}
            <div className="space-y-4">
              <div className="flex items-center">
                <input type="checkbox" id="info" className="mr-3" />
                <label htmlFor="info" className="text-white/80">
                  {t.agencies.application.form.confirmation.info}
                </label>
              </div>
              <div className="flex items-center">
                <input type="checkbox" id="privacy" className="mr-3" />
                <label htmlFor="privacy" className="text-white/80">
                  {t.agencies.application.form.confirmation.privacy}
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button 
                type="submit"
                className="group inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] transition-all duration-300 transform hover:scale-[1.02]"
              >
                <span>{t.agencies.application.form.submit}</span>
                <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-2 transition-transform duration-300" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}