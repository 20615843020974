import React, { useState, useRef, useEffect } from 'react';
import { Search, ChevronDown } from 'lucide-react';
import { countries } from '../../../constants/countries';

interface CountryCodeSelectProps {
  value: string;
  onChange: (code: string) => void;
}

export function CountryCodeSelect({ value, onChange }: CountryCodeSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Filter countries based on search
  const filteredCountries = countries.filter(country => 
    country.name.toLowerCase().includes(search.toLowerCase()) ||
    country.prefix.includes(search)
  );

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedCountry = countries.find(c => c.prefix === value) || countries[0];

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-3 bg-white/5 border border-white/20 rounded-lg text-white hover:bg-white/10 hover:border-[#FFC0CB]/50 focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20 transition-all"
      >
        <img
          src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
          alt={selectedCountry.name}
          className="w-5 h-auto"
        />
        <span>{selectedCountry.prefix}</span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-1 w-64 bg-[#1E3A8A] border border-white/20 rounded-lg shadow-lg">
          {/* Search input */}
          <div className="p-2 border-b border-white/10">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-white/60" />
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search country..."
                className="w-full pl-9 pr-4 py-2 bg-white/5 border border-white/20 rounded-md text-white placeholder-white/60 focus:outline-none focus:border-[#FFC0CB] focus:ring-2 focus:ring-[#FFC0CB]/20"
              />
            </div>
          </div>

          {/* Countries list */}
          <div className="max-h-60 overflow-y-auto">
            {filteredCountries.map((country) => (
              <button
                key={country.code}
                type="button"
                onClick={() => {
                  onChange(country.prefix);
                  setIsOpen(false);
                  setSearch('');
                }}
                className="w-full flex items-center space-x-3 px-4 py-2 hover:bg-[#FFC0CB]/20 transition-colors"
              >
                <img
                  src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                  alt={country.name}
                  className="w-5 h-auto"
                />
                <span className="text-white">{country.name}</span>
                <span className="text-white/60 ml-auto">{country.prefix}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}