import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FunnelIntro } from './FunnelIntro';
import { FunnelStep } from './FunnelStep';
import { existingCreatorPath, newCreatorPath, commonFinalSteps } from './data/funnelPaths';
import { useFunnelNavigation } from '../../hooks/useFunnelNavigation';
import { submitFunnelData } from '../../lib/api';
import type { FunnelAnswer, ContactInfo, FunnelSubmission } from '../../types/funnel';

export function QuestionFunnel() {
  const [selectedPath, setSelectedPath] = useState<'existing' | 'new' | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleComplete = async (answers: FunnelAnswer[]) => {
    if (!selectedPath) return;

    try {
      const contactAnswer = answers[answers.length - 1].answer as Record<string, string>;
      const contactInfo: ContactInfo = {
        name: contactAnswer.name,
        email: contactAnswer.email,
        phone: contactAnswer.phone,
        country: contactAnswer.country,
        contactMethod: contactAnswer.contact_method
      };

      const submission: FunnelSubmission = {
        pathType: selectedPath,
        answers,
        contactInfo
      };

      const success = await submitFunnelData(submission);
      
      if (!success) {
        throw new Error('Failed to submit funnel data');
      }

      // Redirect to success page instead of showing completion state
      navigate('/success');
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('There was an error submitting your form. Please try again.');
    }
  };

  const getCurrentPath = () => {
    if (!selectedPath) return null;
    const mainPath = selectedPath === 'existing' ? existingCreatorPath : newCreatorPath;
    return {
      ...mainPath,
      steps: [...mainPath.steps, ...commonFinalSteps.steps]
    };
  };

  const path = getCurrentPath();
  const { currentStep, handleAnswer, goBack, isSubmitting } = useFunnelNavigation({
    totalSteps: path?.steps.length || 0,
    onComplete: handleComplete
  });

  if (!selectedPath) {
    return <FunnelIntro onSelect={setSelectedPath} />;
  }

  if (!path) return null;

  return (
    <>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}
      <FunnelStep
        step={path.steps[currentStep]}
        currentStep={currentStep}
        totalSteps={path.steps.length}
        onAnswer={handleAnswer}
        onBack={currentStep > 0 ? goBack : undefined}
        isSubmitting={isSubmitting}
      />
    </>
  );
}