import React from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../constants/images';
import { backgrounds } from '../constants/backgrounds';
import { useTranslation } from '../hooks/useTranslation';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={`${backgrounds.footer} py-24`}>
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-12">
          {/* Brand Column */}
          <div className="bg-white/5 backdrop-blur-md rounded-2xl p-8 border border-white/20">
            <Link to="/" className="flex items-center space-x-2 mb-6">
              <img 
                src={IMAGES.logo}
                alt="MatchMates"
                className="h-8 w-auto"
              />
            </Link>
            <p className="text-white/80">
              {t.footer.description}
            </p>
          </div>
          
          {/* Navigation */}
          <div className="bg-white/5 backdrop-blur-md rounded-2xl p-8 border border-white/20">
            <h3 className="font-semibold text-lg mb-4 text-white">{t.footer.navigation}</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-white/80 hover:text-white transition-colors">{t.nav.aboutUs}</Link></li>
              <li><Link to="/" className="text-white/80 hover:text-white transition-colors">{t.nav.forCreators}</Link></li>
              <li><Link to="/agencies" className="text-white/80 hover:text-white transition-colors">{t.nav.forAgencies}</Link></li>
              <li><Link to="/blog" className="text-white/80 hover:text-white transition-colors">{t.nav.blog}</Link></li>
            </ul>
          </div>
          
          {/* Legal */}
          <div className="bg-white/5 backdrop-blur-md rounded-2xl p-8 border border-white/20">
            <h3 className="font-semibold text-lg mb-4 text-white">{t.footer.legal}</h3>
            <ul className="space-y-2">
              <li><Link to="/transparency" className="text-white/80 hover:text-white transition-colors">{t.footer.links.transparency}</Link></li>
              <li><Link to="/privacy" className="text-white/80 hover:text-white transition-colors">{t.footer.links.privacy}</Link></li>
              <li><Link to="/terms" className="text-white/80 hover:text-white transition-colors">{t.footer.links.terms}</Link></li>
              <li><Link to="/cookies" className="text-white/80 hover:text-white transition-colors">{t.footer.links.cookies}</Link></li>
            </ul>
          </div>
          
          {/* Contact */}
          <div className="bg-white/5 backdrop-blur-md rounded-2xl p-8 border border-white/20">
            <h3 className="font-semibold text-lg mb-4 text-white">{t.footer.contact}</h3>
            <div className="space-y-4">
              <ul className="space-y-2">
                <li>
                  <Link to="/creator-quiz" className="text-white/80 hover:text-white transition-colors">
                    {t.footer.contact}
                  </Link>
                </li>
                <li>
                  <Link to="/imprint" className="text-white/80 hover:text-white transition-colors">
                    {t.footer.links.imprint}
                  </Link>
                </li>
              </ul>
              <Link 
                to="/creator-quiz"
                className="inline-flex items-center space-x-2 bg-white/10 hover:bg-white/20 text-white px-6 py-3 rounded-full border border-white/20 transition-all"
              >
                <span>{t.common.startNow}</span>
              </Link>
            </div>
          </div>
        </div>
        
        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-white/10 text-center">
          <p className="text-white/80">
            {t.footer.copyright.replace('{year}', new Date().getFullYear().toString())}
          </p>
        </div>
      </div>
    </footer>
  );
}