import React, { useState, useEffect } from 'react';
import { ArrowLeft, Clock, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';

interface BlogTemplateProps {
  title: string;
  content: string;
  date: string;
  readTime: number;
  category?: string;
  image?: string;
  children?: React.ReactNode;
}

export function BlogTemplate({ 
  title, 
  content, 
  date, 
  readTime, 
  category,
  image,
  children 
}: BlogTemplateProps) {
  const { language } = useLanguage();
  const [readingProgress, setReadingProgress] = useState(0);
  const [isSharing, setIsSharing] = useState(false);

  useEffect(() => {
    const updateReadingProgress = () => {
      const element = document.documentElement;
      const totalHeight = element.scrollHeight - element.clientHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setReadingProgress(progress);
    };

    window.addEventListener('scroll', updateReadingProgress);
    return () => window.removeEventListener('scroll', updateReadingProgress);
  }, []);

  const handleShare = async () => {
    if (isSharing) return;

    try {
      setIsSharing(true);
      
      if (navigator.share) {
        await navigator.share({
          title,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error('Error sharing:', error);
      }
    } finally {
      setIsSharing(false);
    }
  };

  const formatContent = (content: string) => {
    const lines = content.split('\n');
    let inList = false;
    let listType = '';
    let inQuote = false;

    return lines.map((line, index) => {
      if (line.startsWith('# ')) {
        inList = false;
        inQuote = false;
        return `
          <h1 class="text-4xl font-bold mb-8 mt-12 text-transparent bg-clip-text bg-gradient-to-r from-[#003366] to-[#FFC0CB] leading-tight">
            ${line.slice(2)}
          </h1>
        `;
      }
      if (line.startsWith('## ')) {
        inList = false;
        inQuote = false;
        return `
          <h2 class="text-3xl font-bold mb-6 mt-10 text-transparent bg-clip-text bg-gradient-to-r from-[#003366] to-[#FFC0CB] leading-tight">
            ${line.slice(3)}
          </h2>
        `;
      }
      if (line.startsWith('### ')) {
        inList = false;
        inQuote = false;
        return `
          <h3 class="text-2xl font-bold mb-4 mt-8 text-transparent bg-clip-text bg-gradient-to-r from-[#003366] to-[#FFC0CB] leading-tight">
            ${line.slice(4)}
          </h3>
        `;
      }

      if (line.startsWith('- ')) {
        if (!inList) {
          inList = true;
          listType = 'ul';
          return `<ul class="space-y-3 my-6 list-none"><li class="flex items-start"><span class="inline-block w-2 h-2 rounded-full bg-[#FFC0CB] mt-2 mr-3 flex-shrink-0"></span><span>${line.slice(2)}</span></li>`;
        }
        return `<li class="flex items-start"><span class="inline-block w-2 h-2 rounded-full bg-[#FFC0CB] mt-2 mr-3 flex-shrink-0"></span><span>${line.slice(2)}</span></li>`;
      }

      if (line.startsWith('1. ') || /^\d+\.\s/.test(line)) {
        if (!inList) {
          inList = true;
          listType = 'ol';
          return `<ol class="space-y-3 my-6 list-none counter-reset-item"><li class="flex items-start"><span class="flex-shrink-0 w-6 h-6 rounded-full bg-[#003366] text-white flex items-center justify-center text-sm mr-3 font-medium">1</span><span>${line.replace(/^\d+\.\s/, '')}</span></li>`;
        }
        const num = inList ? index + 1 : 1;
        return `<li class="flex items-start"><span class="flex-shrink-0 w-6 h-6 rounded-full bg-[#003366] text-white flex items-center justify-center text-sm mr-3 font-medium">${num}</span><span>${line.replace(/^\d+\.\s/, '')}</span></li>`;
      }

      if (line.startsWith('> ')) {
        if (!inQuote) {
          inQuote = true;
          return `<blockquote class="my-8 pl-6 border-l-4 border-gradient-to-b from-[#003366] to-[#FFC0CB] py-2"><p class="text-gray-600 italic">${line.slice(2)}`;
        }
        return line.slice(2);
      }
      if (inQuote && !line.startsWith('> ')) {
        inQuote = false;
        return `</p></blockquote><p class="text-gray-700 leading-relaxed mb-6">${line}</p>`;
      }

      if (inList && !line.startsWith('- ') && !line.startsWith('1. ') && !/^\d+\.\s/.test(line)) {
        inList = false;
        const closingTag = listType === 'ul' ? '</ul>' : '</ol>';
        return closingTag + (line.trim() ? `<p class="text-gray-700 leading-relaxed mb-6">${line}</p>` : '<div class="h-6"></div>');
      }

      if (line.trim() === '') {
        return inList ? '' : '<div class="h-6"></div>';
      }

      return inList ? line : `<p class="text-gray-700 leading-relaxed mb-6 text-lg">${line}</p>`;
    }).join('\n');
  };

  return (
    <main className="min-h-screen bg-gradient-to-br from-[#003366] to-[#FFC0CB]">
      <Helmet>
        <title>{title} | MatchMates Blog</title>
        <meta name="description" content={content.substring(0, 160)} />
        {image && <meta property="og:image" content={image} />}
        <meta name="article:published_time" content={date} />
        <meta name="article:section" content={category || 'Blog'} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      
      <div className="container mx-auto px-4 pt-24 pb-12">
        {/* Back button with increased top margin */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8 mt-16" // Added top margin
        >
          <Link 
            to={`${language === 'de' ? '/de' : ''}/blog`}
            className="inline-flex items-center text-white hover:text-[#FFC0CB] transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            {language === 'de' ? 'Zurück zum Blog' : 'Back to Blog'}
          </Link>
        </motion.div>

        {/* Article container */}
        <motion.article 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden relative"
        >
          {/* Reading Progress Bar - Moved to top of white container */}
          <div 
            className="absolute top-0 left-0 w-full h-1 bg-gray-100"
            style={{ transform: `translateZ(0)` }}
          >
            <div 
              className="h-full bg-gradient-to-r from-[#003366] to-[#FFC0CB] transition-all duration-150"
              style={{ width: `${readingProgress}%` }}
            />
          </div>

          {/* Featured image */}
          {image && (
            <div className="relative h-[400px] w-full">
              <img 
                src={image}
                alt={title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
            </div>
          )}

          {/* Content wrapper */}
          <div className="p-8 md:p-12 pt-6">
            {/* Article header */}
            <header className="mb-12">
              <h1 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#003366] to-[#FFC0CB] mb-6 leading-tight">
                {title}
              </h1>
              <div className="flex flex-wrap items-center gap-4 text-gray-600">
                <time className="font-medium">{date}</time>
                <span>•</span>
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-[#FFC0CB]" />
                  <span>{readTime} {language === 'de' ? 'Min. Lesezeit' : 'min read'}</span>
                </div>
                {category && (
                  <>
                    <span>•</span>
                    <span className="px-3 py-1 bg-[#003366]/5 rounded-full text-[#003366] font-medium">
                      {category}
                    </span>
                  </>
                )}
              </div>
            </header>

            {/* Article content */}
            <div 
              className="blog-content prose prose-lg prose-slate max-w-none"
              dangerouslySetInnerHTML={{ __html: formatContent(content) }}
            />

            {/* Share section */}
            <div className="mt-12 pt-8 border-t border-gray-100">
              <div className="flex items-center justify-between">
                <div className="text-gray-600">
                  {language === 'de' ? 'Artikel teilen' : 'Share this article'}
                </div>
                <div className="flex items-center gap-4">
                  <button 
                    onClick={handleShare}
                    disabled={isSharing}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors disabled:opacity-50"
                  >
                    <Share2 className="w-5 h-5 text-gray-600" />
                  </button>
                </div>
              </div>
            </div>

            {/* Related posts */}
            {children}
          </div>
        </motion.article>
      </div>
    </main>
  );
}