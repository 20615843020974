import React from 'react';
import { AdminLayout } from '../../components/admin/layout/AdminLayout';
import { useSubmissionStats } from '../../hooks/useSubmissionStats';

export default function DashboardPage() {
  const { total, pending, completed, loading } = useSubmissionStats();

  return (
    <AdminLayout>
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-900">Dashboard</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Quick Stats */}
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Total Submissions</h3>
            {loading ? (
              <div className="animate-pulse h-8 bg-gray-200 rounded w-20" />
            ) : (
              <p className="text-3xl font-bold text-indigo-600">{total}</p>
            )}
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Pending Review</h3>
            {loading ? (
              <div className="animate-pulse h-8 bg-gray-200 rounded w-20" />
            ) : (
              <p className="text-3xl font-bold text-yellow-600">{pending}</p>
            )}
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Completed</h3>
            {loading ? (
              <div className="animate-pulse h-8 bg-gray-200 rounded w-20" />
            ) : (
              <p className="text-3xl font-bold text-green-600">{completed}</p>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}