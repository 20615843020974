import React from 'react';
import { Star, ArrowRight } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

export function AgencyHero() {
  const { t } = useTranslation();

  return (
    <div className="min-h-[90vh] flex items-center pt-32 pb-16">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          {/* Badge */}
          <div className="inline-flex items-center space-x-2 bg-white/10 backdrop-blur-md rounded-full px-6 py-3 mb-8 border border-white/20">
            <Star className="w-5 h-5 text-[#FFC0CB]" />
            <span className="text-white/90">{t.agencies.hero.badge}</span>
          </div>

          {/* Title */}
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6">
            {t.agencies.hero.title}
          </h1>

          {/* Subtitle */}
          <p className="text-xl text-white/90 mb-12">
            {t.agencies.hero.subtitle}
          </p>

          {/* CTA Button */}
          <button 
            className="group inline-flex items-center px-8 py-4 text-white font-semibold rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] transition-all duration-300 transform hover:scale-[1.02]"
          >
            <span>Apply Now</span>
            <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-2 transition-transform duration-300" />
          </button>
        </div>
      </div>
    </div>
  );
}