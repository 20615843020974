import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { useFunnelValidation } from '../../hooks/useFunnelValidation';
import { gtagReportConversion } from '../../lib/analytics/gtagHelper';
import type { FunnelStep as FunnelStepType } from '../../types/funnel';
import { FunnelProgress } from './FunnelProgress';
import { ChoiceStep } from './steps/ChoiceStep';
import { ContactStep } from './steps/ContactStep';
import { SocialStep } from './steps/SocialStep';
import { TextStep } from './steps/TextStep';

interface FunnelStepProps {
  step: FunnelStepType;
  currentStep: number;
  totalSteps: number;
  onAnswer: (answer: any) => void;
  onBack?: () => void;
  isSubmitting?: boolean;
}

export function FunnelStep({
  step,
  currentStep,
  totalSteps,
  onAnswer,
  onBack,
  isSubmitting = false
}: FunnelStepProps) {
  const { errors, validateFields } = useFunnelValidation(step);

  const handleSubmit = (data: any) => {
    if (validateFields(data)) {
      // If this is the final step (contact form), trigger conversion
      if (step.type === 'contact') {
        gtagReportConversion();
      }
      onAnswer({ stepId: step.id, answer: data });
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4 md:px-0">
      <div className="min-h-[calc(100vh-4rem)] flex flex-col">
        <FunnelProgress currentStep={currentStep} totalSteps={totalSteps} />

        {onBack && currentStep > 0 && (
          <button
            onClick={onBack}
            className="mb-4 text-white/80 hover:text-white flex items-center"
            aria-label="Go back"
          >
            <ChevronLeft className="w-5 h-5 mr-1" />
            Back
          </button>
        )}

        <div className="flex-1 bg-white/10 backdrop-blur-md rounded-2xl p-4 md:p-8 border border-white/20">
          <h2 className="text-xl md:text-2xl font-bold text-white mb-2">{step.title}</h2>
          {step.subtitle && (
            <p className="text-white/80 mb-6 text-sm md:text-base">{step.subtitle}</p>
          )}

          {step.type === 'choice' && (
            <ChoiceStep
              step={step}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}

          {step.type === 'contact' && (
            <ContactStep
              step={step}
              onSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          )}

          {step.type === 'social' && (
            <SocialStep
              step={step}
              onSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          )}

          {step.type === 'text' && (
            <TextStep
              step={step}
              onSubmit={handleSubmit}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </div>
    </div>
  );
}