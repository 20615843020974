import React from 'react';
import { DivideIcon as LucideIcon } from 'lucide-react';
import { useTranslation } from '../../hooks/useTranslation';

interface ComparisonSideProps {
  title: string;
  items: Array<{
    icon: LucideIcon;
    key: string;
  }>;
  variant: 'positive' | 'negative';
}

export function ComparisonSide({ title, items, variant }: ComparisonSideProps) {
  const { t } = useTranslation();
  const bgClass = variant === 'positive' 
    ? 'bg-[#FFC0CB]'
    : 'bg-[#1E3A8A]';

  const titleColor = variant === 'positive'
    ? 'text-[#1E3A8A]'
    : 'text-[#FFC0CB]';

  const textShadowClass = variant === 'positive'
    ? 'text-shadow-white'
    : '';

  return (
    <div className={`p-6 rounded-2xl transition-all duration-700 ${bgClass} bg-opacity-95 shadow-lg`}>
      <h3 className={`text-xl font-bold mb-6 ${titleColor}`}>
        {title}
      </h3>
      <ul className="space-y-5">
        {items.map((item) => (
          <li key={item.key} className="flex items-start space-x-4">
            <div className="p-2 rounded-full flex-shrink-0 bg-white/20 backdrop-blur-sm">
              <item.icon className="w-5 h-5 text-white" />
            </div>
            <div className="flex-1 min-w-0">
              <h4 className={`font-semibold mb-1.5 text-white text-base ${textShadowClass} drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]`}>
                {t.comparison[variant === 'positive' ? 'benefits' : 'challenges'].items[item.key].title}
              </h4>
              <p className={`text-white/90 text-sm leading-relaxed ${textShadowClass} drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)]`}>
                {t.comparison[variant === 'positive' ? 'benefits' : 'challenges'].items[item.key].description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}