import React from 'react';
import { Loader2, Save } from 'lucide-react';

interface SaveButtonProps {
  isSaving: boolean;
}

export function SaveButton({ isSaving }: SaveButtonProps) {
  return (
    <button
      type="submit"
      disabled={isSaving}
      className="flex items-center justify-center w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
    >
      {isSaving ? (
        <>
          <Loader2 className="w-5 h-5 mr-2 animate-spin" />
          Saving...
        </>
      ) : (
        <>
          <Save className="w-5 h-5 mr-2" />
          Save SEO Settings
        </>
      )}
    </button>
  );
}