import React from 'react';
import { SEOConfig } from '../../components/admin/SEOConfig';
import { AdminAuth } from '../../components/admin/AdminAuth';

export default function SEOPage() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  if (!isAuthenticated) {
    return <AdminAuth onAuth={() => setIsAuthenticated(true)} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">SEO Management</h1>
          <p className="mt-2 text-sm text-gray-600">
            Configure SEO settings for better visibility on search engines and social media.
          </p>
        </div>
        
        <SEOConfig />
      </div>
    </div>
  );
}