import React from 'react';

interface FormFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  helperText?: string;
  type?: 'text' | 'url';
  multiline?: boolean;
}

export function FormField({
  label,
  value,
  onChange,
  placeholder,
  helperText,
  type = 'text',
  multiline = false
}: FormFieldProps) {
  const inputProps = {
    value: value || '',
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e.target.value),
    className: "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500",
    placeholder,
    type
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {multiline ? (
        <textarea
          {...inputProps}
          rows={3}
        />
      ) : (
        <input {...inputProps} />
      )}
      {helperText && (
        <p className="mt-1 text-sm text-gray-500">{helperText}</p>
      )}
    </div>
  );
}