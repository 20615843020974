// Singleton to manage audio playback
class AudioManager {
  private static instance: AudioManager;
  private currentlyPlaying: HTMLAudioElement | null = null;

  private constructor() {}

  public static getInstance(): AudioManager {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
    }
    return AudioManager.instance;
  }

  public setPlaying(audio: HTMLAudioElement | null) {
    // Stop current audio if it exists and is different from the new one
    if (this.currentlyPlaying && this.currentlyPlaying !== audio) {
      this.currentlyPlaying.pause();
      this.currentlyPlaying.currentTime = 0;
    }
    this.currentlyPlaying = audio;
  }

  public stopAll() {
    if (this.currentlyPlaying) {
      this.currentlyPlaying.pause();
      this.currentlyPlaying.currentTime = 0;
      this.currentlyPlaying = null;
    }
  }
}

export const audioManager = AudioManager.getInstance();