import React from 'react';
import { supabase } from '../../../lib/supabase';

interface StatusSelectProps {
  id: string;
  currentStatus: string;
  onStatusChange: (newStatus: string) => void;
}

export function StatusSelect({ id, currentStatus, onStatusChange }: StatusSelectProps) {
  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = e.target.value;
    
    try {
      // First get the current status history
      const { data: currentData, error: fetchError } = await supabase
        .from('funnel_submissions')
        .select('status_history')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Prepare the new status history
      const currentHistory = currentData?.status_history || [];
      const newHistory = [
        ...currentHistory,
        {
          status: newStatus,
          timestamp: new Date().toISOString()
        }
      ];

      // Update with the new status and history
      const { error: updateError } = await supabase
        .from('funnel_submissions')
        .update({ 
          status: newStatus,
          status_history: newHistory
        })
        .eq('id', id);

      if (updateError) throw updateError;
      onStatusChange(newStatus);
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status');
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-800 bg-yellow-50';
      case 'contacted':
        return 'text-blue-800 bg-blue-50';
      case 'completed':
        return 'text-green-800 bg-green-50';
      case 'no_response':
        return 'text-gray-800 bg-gray-50';
      case 'bad_lead':
        return 'text-red-800 bg-red-50';
      case 'call_set':
        return 'text-purple-800 bg-purple-50';
      case '1st_followup':
        return 'text-indigo-800 bg-indigo-50';
      case '2nd_followup':
        return 'text-pink-800 bg-pink-50';
      case '3rd_followup':
        return 'text-orange-800 bg-orange-50';
      default:
        return 'text-gray-800 bg-gray-50';
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <select
        value={currentStatus}
        onChange={handleChange}
        className={`rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-medium ${getStatusColor(currentStatus)}`}
      >
        <option value="pending" className="text-yellow-800 bg-yellow-50">Pending</option>
        <option value="contacted" className="text-blue-800 bg-blue-50">Contacted</option>
        <option value="no_response" className="text-gray-800 bg-gray-50">No Response</option>
        <option value="bad_lead" className="text-red-800 bg-red-50">Bad Lead</option>
        <option value="call_set" className="text-purple-800 bg-purple-50">Call Set</option>
        <option value="no_show" className="text-red-800 bg-red-50">No Show</option>
        <option value="1st_followup" className="text-indigo-800 bg-indigo-50">1. Follow Up</option>
        <option value="2nd_followup" className="text-pink-800 bg-pink-50">2. Follow Up</option>
        <option value="3rd_followup" className="text-orange-800 bg-orange-50">3. Follow Up</option>
        <option value="completed" className="text-green-800 bg-green-50">Completed</option>
      </select>
    </div>
  );
}