import { 
  DollarSign, 
  Users, 
  Clock, 
  FileCheck, 
  Lightbulb, 
  Shield 
} from 'lucide-react';

export const qualificationData = [
  {
    icon: DollarSign,
    key: 'revenue',
    highlight: true
  },
  {
    icon: Users,
    key: 'performer',
    highlight: true
  },
  {
    icon: Clock,
    key: 'experience',
    highlight: true
  },
  {
    icon: FileCheck,
    key: 'documentation',
    highlight: true
  },
  {
    icon: Lightbulb,
    key: 'value',
    highlight: true
  },
  {
    icon: Shield,
    key: 'quality',
    highlight: true
  }
];

export const processSteps = [
  {
    icon: "📝",
    key: 'application'
  },
  {
    icon: "📋",
    key: 'review'
  },
  {
    icon: "🔍",
    key: 'audit'
  },
  {
    icon: "💡",
    key: 'usp'
  },
  {
    icon: "🚀",
    key: 'decision'
  }
];