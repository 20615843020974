import React from 'react';
import { Users, TrendingUp, Award, Shield } from 'lucide-react';
import Card from '../ui/Card';
import { useTranslation } from '../../hooks/useTranslation';

const benefits = [
  {
    icon: Users,
    key: 'matches'
  },
  {
    icon: TrendingUp,
    key: 'partnerships'
  },
  {
    icon: Award,
    key: 'reputation'
  },
  {
    icon: Shield,
    key: 'support'
  }
];

export function AgencyBenefits() {
  const { t } = useTranslation();

  return (
    <section className="py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <span className="inline-block px-4 py-2 rounded-full text-white border border-[#FFC0CB] text-sm font-medium mb-4">
            {t.agencies.benefits.badge}
          </span>
          <h2 className="text-3xl font-bold text-white mb-4">
            {t.agencies.benefits.title}
          </h2>
          <p className="text-white/80 max-w-3xl mx-auto">
            {t.agencies.benefits.subtitle}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {benefits.map((benefit, index) => (
            <Card key={index} className="relative group">
              <div className="bg-[#FFC0CB] p-3 rounded-full w-14 h-14 flex items-center justify-center mb-6">
                <benefit.icon className="w-7 h-7 text-white" />
              </div>
              <h3 className="text-lg font-semibold text-white mb-3">
                {t.agencies.benefits.items[benefit.key].title}
              </h3>
              <p className="text-white/80">
                {t.agencies.benefits.items[benefit.key].description}
              </p>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
}