import React, { useRef } from 'react';
import { Users, Shield, AlertTriangle, ArrowRight } from 'lucide-react';
import { useTranslation } from '../hooks/useTranslation';
import { useNavigate } from 'react-router-dom';

const features = [
  {
    icon: Users,
    key: 'matching'
  },
  {
    icon: Shield,
    key: 'protection'
  },
  {
    icon: AlertTriangle,
    key: 'security'
  }
];

export default function Features() {
  const { t } = useTranslation();
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate();

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6 max-w-[1200px]">
        {/* Title with brush stroke effect */}
        <div className="relative flex justify-center mb-12">
          {/* Brush stroke background */}
          <div 
            className="absolute -z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[140%] max-w-[500px]"
            style={{
              height: '130%',
              background: 'linear-gradient(90deg, rgba(255,192,203,0) 0%, rgba(255,192,203,0.3) 20%, rgba(255,192,203,0.4) 50%, rgba(255,192,203,0.3) 80%, rgba(255,192,203,0) 100%)',
              filter: 'blur(20px)',
              transform: 'translate(-50%, -50%) rotate(-2deg)',
              borderRadius: '60% 40% 50% 45%',
            }}
          />
          {/* Additional decorative brush strokes */}
          <div 
            className="absolute -z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[130%] max-w-[480px]"
            style={{
              height: '120%',
              background: 'linear-gradient(90deg, rgba(255,192,203,0) 0%, rgba(255,192,203,0.2) 30%, rgba(255,192,203,0.3) 50%, rgba(255,192,203,0.2) 70%, rgba(255,192,203,0) 100%)',
              filter: 'blur(15px)',
              transform: 'translate(-50%, -50%) rotate(3deg)',
              borderRadius: '45% 55% 40% 50%',
            }}
          />
          
          <h2 className="text-[32px] font-bold text-center text-[#1E3A8A] relative">
            {t.features.title}
          </h2>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Left Column - Image with Frame */}
          <div className="relative">
            <div className="relative rounded-2xl overflow-hidden bg-gradient-to-r from-[#003366] to-[#FFC0CB] p-1">
              <div className="relative rounded-xl overflow-hidden">
                <img
                  src="https://imgur.com/iWIdtA3.png"
                  alt={t.features.title}
                  className="w-full h-auto"
                  loading="eager"
                />
              </div>
              {/* Decorative elements */}
              <div className="absolute top-4 right-4 w-4 h-4 rounded-full bg-[#FFC0CB] animate-pulse"></div>
              <div className="absolute bottom-4 left-4 w-4 h-4 rounded-full bg-[#003366] animate-pulse" style={{ animationDelay: '1s' }}></div>
            </div>
          </div>

          {/* Right Column - Content */}
          <div className="lg:sticky lg:top-8 space-y-8">
            {/* Text Sections */}
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold text-[#003366] mb-2">The Creator's Dilemma</h3>
                <p className="text-gray-600">
                  Many creators struggle with unreliable agencies, hidden fees, and broken promises. 
                  The search for trustworthy partners often ends in disappointment and lost time.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-bold text-[#003366] mb-2">The Solution</h3>
                <p className="text-gray-600">
                  Through rigorous vetting and AI-powered matching, we connect creators with verified 
                  agencies that truly understand their vision and can deliver real results.
                </p>
              </div>
            </div>

            {/* Mobile CTA */}
            <div className="lg:hidden">
              <button
                onClick={() => navigate('/creator-quiz')}
                className="w-full flex items-center justify-between px-6 py-4 bg-[#003366] text-white rounded-xl hover:bg-[#002347] transition-colors"
              >
                <span className="font-semibold">Find your Agency</span>
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>

            {/* Feature Boxes - Desktop Only */}
            <div className="hidden lg:grid grid-cols-3 gap-4">
              {features.map((feature, index) => (
                <div
                  key={feature.key}
                  ref={el => cardsRef.current[index] = el}
                  className="relative p-4 rounded-xl overflow-hidden transition-all duration-300 hover:scale-105"
                  style={{
                    background: 'linear-gradient(135deg, #003366 0%, #FFC0CB 100%)'
                  }}
                >
                  {/* Icon Container */}
                  <div 
                    className="w-12 h-12 rounded-xl bg-[#FFC0CB] hover:bg-[#FFB0BE] flex items-center justify-center mb-3 transition-all duration-300"
                  >
                    <feature.icon className="w-6 h-6 text-[#003366]" />
                  </div>
                  
                  {/* Title */}
                  <h3 className="font-semibold text-white">
                    {t.features[feature.key].title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}