export interface Country {
  name: string;
  code: string;
  prefix: string;
}

export const countries: Country[] = [
  // Most common countries first
  { name: 'United States', code: 'US', prefix: '+1' },
  { name: 'Germany', code: 'DE', prefix: '+49' },
  { name: 'United Kingdom', code: 'GB', prefix: '+44' },
  { name: 'France', code: 'FR', prefix: '+33' },
  { name: 'Spain', code: 'ES', prefix: '+34' },
  { name: 'Italy', code: 'IT', prefix: '+39' },
  { name: 'Canada', code: 'CA', prefix: '+1' },
  { name: 'Australia', code: 'AU', prefix: '+61' },
  { name: 'Brazil', code: 'BR', prefix: '+55' },
  { name: 'Japan', code: 'JP', prefix: '+81' },
  
  // Rest of Europe
  { name: 'Austria', code: 'AT', prefix: '+43' },
  { name: 'Belgium', code: 'BE', prefix: '+32' },
  { name: 'Bulgaria', code: 'BG', prefix: '+359' },
  { name: 'Croatia', code: 'HR', prefix: '+385' },
  { name: 'Cyprus', code: 'CY', prefix: '+357' },
  { name: 'Czech Republic', code: 'CZ', prefix: '+420' },
  { name: 'Denmark', code: 'DK', prefix: '+45' },
  { name: 'Estonia', code: 'EE', prefix: '+372' },
  { name: 'Finland', code: 'FI', prefix: '+358' },
  { name: 'Greece', code: 'GR', prefix: '+30' },
  { name: 'Hungary', code: 'HU', prefix: '+36' },
  { name: 'Ireland', code: 'IE', prefix: '+353' },
  { name: 'Latvia', code: 'LV', prefix: '+371' },
  { name: 'Lithuania', code: 'LT', prefix: '+370' },
  { name: 'Luxembourg', code: 'LU', prefix: '+352' },
  { name: 'Malta', code: 'MT', prefix: '+356' },
  { name: 'Netherlands', code: 'NL', prefix: '+31' },
  { name: 'Norway', code: 'NO', prefix: '+47' },
  { name: 'Poland', code: 'PL', prefix: '+48' },
  { name: 'Portugal', code: 'PT', prefix: '+351' },
  { name: 'Romania', code: 'RO', prefix: '+40' },
  { name: 'Slovakia', code: 'SK', prefix: '+421' },
  { name: 'Slovenia', code: 'SI', prefix: '+386' },
  { name: 'Sweden', code: 'SE', prefix: '+46' },
  { name: 'Switzerland', code: 'CH', prefix: '+41' },

  // Asia
  { name: 'China', code: 'CN', prefix: '+86' },
  { name: 'Hong Kong', code: 'HK', prefix: '+852' },
  { name: 'India', code: 'IN', prefix: '+91' },
  { name: 'Indonesia', code: 'ID', prefix: '+62' },
  { name: 'Israel', code: 'IL', prefix: '+972' },
  { name: 'Malaysia', code: 'MY', prefix: '+60' },
  { name: 'Philippines', code: 'PH', prefix: '+63' },
  { name: 'Singapore', code: 'SG', prefix: '+65' },
  { name: 'South Korea', code: 'KR', prefix: '+82' },
  { name: 'Taiwan', code: 'TW', prefix: '+886' },
  { name: 'Thailand', code: 'TH', prefix: '+66' },
  { name: 'Turkey', code: 'TR', prefix: '+90' },
  { name: 'Vietnam', code: 'VN', prefix: '+84' },

  // Americas
  { name: 'Argentina', code: 'AR', prefix: '+54' },
  { name: 'Chile', code: 'CL', prefix: '+56' },
  { name: 'Colombia', code: 'CO', prefix: '+57' },
  { name: 'Mexico', code: 'MX', prefix: '+52' },
  { name: 'Peru', code: 'PE', prefix: '+51' },
  { name: 'Venezuela', code: 'VE', prefix: '+58' },

  // Oceania
  { name: 'New Zealand', code: 'NZ', prefix: '+64' },

  // Africa
  { name: 'Egypt', code: 'EG', prefix: '+20' },
  { name: 'Kenya', code: 'KE', prefix: '+254' },
  { name: 'Morocco', code: 'MA', prefix: '+212' },
  { name: 'Nigeria', code: 'NG', prefix: '+234' },
  { name: 'South Africa', code: 'ZA', prefix: '+27' },

  // Middle East
  { name: 'Saudi Arabia', code: 'SA', prefix: '+966' },
  { name: 'United Arab Emirates', code: 'AE', prefix: '+971' },
  { name: 'Qatar', code: 'QA', prefix: '+974' },
  { name: 'Kuwait', code: 'KW', prefix: '+965' },
  { name: 'Bahrain', code: 'BH', prefix: '+973' },
  { name: 'Oman', code: 'OM', prefix: '+968' }
];