import React from 'react';
import { LegalTemplate } from '../../components/legal/LegalTemplate';
import { useLanguage } from '../../contexts/LanguageContext';

export default function TransparencyPage() {
  const { language } = useLanguage();

  const content = {
    en: `
     <h1>Transparency Note – MatchMates</h1>
  <p><strong>Date:</strong> 04.06.2024</p>

  <section id="introduction">
    <h2>1. Introduction to Our Transparency Note</h2>
    <p>
      Welcome to our Transparency Note. At MatchMates, we believe that trust is the foundation of every successful relationship between creators and agencies. Our commitment to trust begins with complete transparency in all aspects of our operations.
    </p>
    <p>
      Our platform is designed to connect digital content creators with the most reliable and reputable agencies. We understand that navigating the world of online creation can be complex and challenging. That’s why we are committed to being your trusted partner – ensuring that every agency we connect you with has been thoroughly vetted and proven to uphold the highest standards of professionalism and ethics.
    </p>
    <p>
      In this document, we disclose the processes behind our operations, from how we select agencies to how we handle your data. Our goal is to ensure that you can fully understand and feel confident in how we operate. By sharing our practices, we aim to reinforce the trust you place in us and demonstrate our dedication to supporting your success in a secure and supportive environment.
    </p>
  </section>

  <section id="agency-selection">
    <h2>2. Our Agency Selection Process</h2>
    <h3>Overview</h3>
    <p>
      The integrity and reliability of the agencies we partner with are paramount. Our rigorous selection process is designed to ensure that only the most reputable and capable agencies are part of our network.
    </p>
    <h3>Criteria Details</h3>
    <ul>
      <li>
        <strong>Reputation:</strong> We begin by investigating each agency's reputation through user reviews, industry recommendations, and feedback from previous collaborations. We monitor social media and other digital platforms to gather comprehensive insights.
      </li>
      <li>
        <strong>Experience:</strong> An agency’s track record is crucial. We evaluate their longevity, client diversity, and documented success stories, ensuring they can adapt to changing market dynamics while delivering exceptional service.
      </li>
      <li>
        <strong>Compliance and Ethics:</strong> Adherence to legal standards and ethical business practices is non-negotiable. We verify that agencies comply with all applicable laws—including labor, intellectual property, and data protection regulations—while maintaining transparency and fairness.
      </li>
    </ul>
    <h3>Selection Steps</h3>
    <ul>
      <li>
        <strong>Initial Screening:</strong> We verify the basic credentials of each agency, including business registration, operational history, and financial stability.
      </li>
      <li>
        <strong>Interviews and Assessments:</strong> Agencies passing the initial screening undergo in-depth interviews and assessments to understand their business models, strategies, and values.
      </li>
      <li>
        <strong>Final Evaluation:</strong> All collected data is consolidated into a final evaluation to make an informed decision about an agency’s suitability for our platform.
      </li>
    </ul>
  </section>

  <section id="supporting-creators">
    <h2>3. Supporting Creators</h2>
    <p>
      Our commitment to supporting creators goes beyond simply connecting them with agencies. We offer a comprehensive support system designed to empower creators, foster growth, and protect your interests in the dynamic digital landscape.
    </p>
    <h3>Resources and Tools</h3>
    <ul>
      <li><strong>Educational Resources:</strong> Access webinars, tutorials, and articles covering marketing strategies, content optimization, and financial management.</li>
      <li><strong>Analytics Tools:</strong> Utilize advanced tools to gain insights into content performance, audience demographics, and engagement patterns.</li>
      <li><strong>Content Protection:</strong> Benefit from technologies and legal support that protect your intellectual property and prevent unauthorized use.</li>
      <li><strong>Collaborative Tools:</strong> Leverage platforms that facilitate seamless communication and collaboration with agencies.</li>
    </ul>
    <h3>Support System</h3>
    <ul>
      <li>
        <strong>Help Desk:</strong> Our dedicated support team is available via email to assist with any inquiries or issues.
      </li>
      <li>
        <strong>Advisory Services:</strong> Expert advisors provide personalized guidance on career development, contractual matters, and strategic planning.
      </li>
      <li>
        <strong>Feedback System:</strong> Your feedback is invaluable. It is systematically analyzed to inform service enhancements and policy updates.
      </li>
    </ul>
  </section>

  <section id="operational-transparency">
    <h2>4. Operational Transparency</h2>
    <h3>Business Model</h3>
    <p>
      Our business model is simple and transparent:
    </p>
    <ul>
      <li><strong>Free for Creators:</strong> Our platform is completely free for creators. There are no hidden fees, subscriptions, or charges.</li>
      <li><strong>Agencies Pay to Participate:</strong> Agencies pay a listing fee or a success-based commission to be featured on our platform, ensuring only serious and professional partners participate.</li>
      <li><strong>Performance-Based Fees:</strong> In some cases, agencies may pay a commission upon successful collaboration or when specific milestones are reached, aligning our success with yours.</li>
    </ul>
    <h3>Advertising and Promotions</h3>
    <ul>
      <li><strong>No Creator-Side Advertising Fees:</strong> Creators are never charged for visibility or access to agencies. Promoted listings are clearly marked.</li>
      <li><strong>Affiliate Partnerships:</strong> We partner with third-party services that add value to your career. All affiliate promotions are fully disclosed.</li>
      <li><strong>Sponsored Listings:</strong> Agencies can sponsor their listings for enhanced visibility, but these are always labeled as “Sponsored” and do not affect our quality control processes.</li>
    </ul>
    <h3>Data Management</h3>
    <ul>
      <li>
        <strong>Data Collection:</strong> We collect only the information necessary to match creators with agencies and improve our platform, such as your name, email, and preferences.
      </li>
      <li>
        <strong>Data Usage:</strong> Your data is used solely for personalized matchmaking, platform improvements, and essential communications.
      </li>
      <li>
        <strong>Data Protection:</strong> We implement robust security measures, including encryption and regular audits, to safeguard your information. For more details, please refer to our Privacy Policy.
      </li>
    </ul>
  </section>

  <section id="ethical-practices">
    <h2>5. Ethical Practices</h2>
    <h3>Compliance</h3>
    <ul>
      <li>
        <strong>Legal Standards:</strong> All agencies must comply with local and international laws, including labor laws, fair contract practices, and intellectual property protections.
      </li>
      <li>
        <strong>Data Protection:</strong> We adhere strictly to global privacy standards, such as the GDPR, ensuring responsible data processing.
      </li>
      <li>
        <strong>Fair Business Practices:</strong> We enforce a zero-tolerance policy for unethical practices, ensuring all operations meet high ethical standards.
      </li>
    </ul>
    <h3>Community Engagement</h3>
    <ul>
      <li><strong>Feedback-Driven Development:</strong> We actively incorporate creator feedback to enhance our platform.</li>
      <li><strong>Educational Initiatives:</strong> We offer free workshops, webinars, and guides to support your success.</li>
      <li><strong>Support for Emerging Talent:</strong> We provide equal access to top-tier agencies regardless of experience or follower count.</li>
      <li><strong>Ethical Partnerships:</strong> All partnerships are vetted to ensure alignment with our values of fairness and respect.</li>
    </ul>
    <h3>Environmental Responsibility</h3>
    <ul>
      <li><strong>Green Hosting Providers:</strong> We partner with eco-friendly hosting providers that utilize renewable energy.</li>
      <li><strong>Paperless Operations:</strong> Our processes are digital-first, reducing paper consumption.</li>
      <li><strong>Sustainability Initiatives:</strong> We encourage environmentally responsible practices across our community.</li>
    </ul>
  </section>

  <section id="updates">
    <h2>6. Updates and Revisions</h2>
    <p>
      This entry is updated whenever there are changes in our practices. We continuously refine our processes to reflect operational improvements and regulatory updates.
    </p>
  </section>

  <section id="contact">
    <h2>7. Contact and Feedback</h2>
    <p>
      We value open communication and are always ready to address your questions or concerns regarding our transparency practices.
    </p>
    <ul>
      <li><strong>Email:</strong> <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a></li>
    </ul>
  </section>

  <section id="legal">
    <h2>8. Legal and Documentation</h2>
    <ul>
      <li>
        <strong>Data Privacy and Protection:</strong> We strictly adhere to global privacy frameworks, including the GDPR. Your data is processed and protected responsibly.
      </li>
      <li>
        <strong>Business Integrity:</strong> We maintain fair and transparent processes across all aspects of our operations.
      </li>
    </ul>
    <p>
      For more detailed information on our policies or for any specific inquiries, please contact our support team via the email provided above.
    </p>
  </section>

  <footer>
    <p>&copy; [2024] MatchMates. All rights reserved.</p>
  </footer>
    `,
    de: `
<h1>Transparenzhinweis – MatchMates</h1>
<p><strong>Datum:</strong> 04.06.2024</p>

<section id="introduction">
  <h2>1. Einführung in unseren Transparenzhinweis</h2>
  <p>
    Willkommen zu unserem Transparenzhinweis. Bei MatchMates sind wir überzeugt, dass Vertrauen die Grundlage jeder erfolgreichen Beziehung zwischen Kreativen und Agenturen ist. Unser Engagement für Vertrauen beginnt mit vollständiger Transparenz in allen Aspekten unserer Arbeitsweise.
  </p>
  <p>
    Unsere Plattform ist darauf ausgelegt, digitale Content-Ersteller mit den zuverlässigsten und angesehensten Agenturen zu verbinden. Wir wissen, dass es herausfordernd sein kann, sich in der Welt der Online-Kreation zurechtzufinden. Deshalb haben wir uns dazu verpflichtet, Ihr vertrauenswürdiger Partner zu sein – und sicherzustellen, dass jede Agentur, mit der wir Sie verbinden, gründlich geprüft wurde und nachweislich die höchsten Standards an Professionalität und Ethik erfüllt.
  </p>
  <p>
    In diesem Dokument legen wir die Prozesse offen, die hinter unserem Betrieb stehen – von der Auswahl der Agenturen bis hin zum Umgang mit Ihren Daten. Unser Ziel ist es, sicherzustellen, dass Sie unsere Arbeitsweise vollständig nachvollziehen können und Vertrauen in unsere Prozesse haben. Indem wir unsere Praktiken offenlegen, möchten wir das Vertrauen, das Sie in uns setzen, weiter stärken und unser Engagement für Ihren Erfolg in einer sicheren und unterstützenden Umgebung unter Beweis stellen.
  </p>
</section>

<section id="agency-selection">
  <h2>2. Unser Auswahlprozess für Agenturen</h2>
  <h3>Übersicht</h3>
  <p>
    Die Integrität und Zuverlässigkeit der Agenturen, mit denen wir zusammenarbeiten, haben für uns oberste Priorität. Unser strenger Auswahlprozess ist darauf ausgelegt, sicherzustellen, dass nur die angesehensten und leistungsfähigsten Agenturen in unser Netzwerk aufgenommen werden.
  </p>
  <h3>Kriterien im Detail</h3>
  <ul>
    <li>
      <strong>Reputation:</strong> Wir beginnen mit der Überprüfung der Reputation jeder Agentur durch Nutzerbewertungen, Branchenempfehlungen und Rückmeldungen aus früheren Zusammenarbeiten. Wir beobachten soziale Medien und andere digitale Plattformen, um umfassende Einblicke zu gewinnen.
    </li>
    <li>
      <strong>Erfahrung:</strong> Die Erfolgsbilanz einer Agentur ist entscheidend. Wir bewerten ihre Langlebigkeit, die Vielfalt ihrer Kunden und dokumentierte Erfolgsgeschichten, um sicherzustellen, dass sie sich an wechselnde Marktbedingungen anpassen können und dabei außergewöhnlichen Service bieten.
    </li>
    <li>
      <strong>Compliance und Ethik:</strong> Die Einhaltung gesetzlicher Standards und ethischer Geschäftspraktiken ist unerlässlich. Wir überprüfen, ob die Agenturen alle geltenden Gesetze – einschließlich Arbeitsrecht, Urheberrecht und Datenschutzvorschriften – einhalten und gleichzeitig Transparenz und Fairness wahren.
    </li>
  </ul>
  <h3>Auswahlschritte</h3>
  <ul>
    <li>
      <strong>Erstprüfung:</strong> Wir überprüfen die grundlegenden Qualifikationen jeder Agentur, einschließlich Geschäftsanmeldung, Betriebshistorie und finanzieller Stabilität.
    </li>
    <li>
      <strong>Interviews und Bewertungen:</strong> Agenturen, die die Erstprüfung bestehen, werden in ausführliche Interviews und Bewertungen einbezogen, um ihr Geschäftsmodell, ihre Strategien und Werte zu verstehen.
    </li>
    <li>
      <strong>Abschließende Bewertung:</strong> Alle gesammelten Daten werden in eine abschließende Bewertung zusammengeführt, um eine fundierte Entscheidung über die Eignung einer Agentur für unsere Plattform zu treffen.
    </li>
  </ul>
</section>

<section id="supporting-creators">
  <h2>3. Unterstützung für Kreative</h2>
  <p>
    Unser Engagement, Kreative zu unterstützen, geht über die bloße Verbindung mit Agenturen hinaus. Wir bieten ein umfassendes Unterstützungssystem, das darauf ausgelegt ist, Kreative zu stärken, Wachstum zu fördern und Ihre Interessen in der dynamischen digitalen Landschaft zu schützen.
  </p>
  <h3>Ressourcen und Werkzeuge</h3>
  <ul>
    <li><strong>Bildungsressourcen:</strong> Zugang zu Webinaren, Tutorials und Artikeln, die Marketingstrategien, Content-Optimierung und Finanzmanagement abdecken.</li>
    <li><strong>Analyse-Tools:</strong> Nutzen Sie fortschrittliche Werkzeuge, um Einblicke in die Performance von Inhalten, Publikumsdemografien und Interaktionsmuster zu erhalten.</li>
    <li><strong>Content-Schutz:</strong> Profitieren Sie von Technologien und rechtlicher Unterstützung, die Ihr geistiges Eigentum schützen und eine unbefugte Nutzung verhindern.</li>
    <li><strong>Kollaborative Werkzeuge:</strong> Nutzen Sie Plattformen, die eine nahtlose Kommunikation und Zusammenarbeit mit Agenturen ermöglichen.</li>
  </ul>
  <h3>Unterstützungssystem</h3>
  <ul>
    <li>
      <strong>Helpdesk:</strong> Unser engagiertes Support-Team steht per E-Mail zur Verfügung, um bei Fragen oder Problemen zu helfen.
    </li>
    <li>
      <strong>Beratungsdienstleistungen:</strong> Experten bieten persönliche Beratung zu Karriereentwicklung, Vertragsangelegenheiten und strategischer Planung.
    </li>
    <li>
      <strong>Feedback-System:</strong> Ihr Feedback ist von unschätzbarem Wert. Es wird systematisch analysiert, um Verbesserungen unserer Dienstleistungen und Aktualisierungen unserer Richtlinien zu ermöglichen.
    </li>
  </ul>
</section>

<section id="operational-transparency">
  <h2>4. Operative Transparenz</h2>
  <h3>Geschäftsmodell</h3>
  <p>
    Unser Geschäftsmodell ist einfach und transparent:
  </p>
  <ul>
    <li><strong>Kostenlos für Kreative:</strong> Unsere Plattform ist für Kreative völlig kostenlos. Es gibt keine versteckten Gebühren, Abonnements oder Kosten.</li>
    <li><strong>Agenturen zahlen für die Teilnahme:</strong> Agenturen zahlen eine Listungsgebühr oder eine erfolgsbasierte Provision, um auf unserer Plattform gelistet zu werden, wodurch sichergestellt wird, dass nur ernsthafte und professionelle Partner teilnehmen.</li>
    <li><strong>Leistungsabhängige Gebühren:</strong> In einigen Fällen zahlen Agenturen eine Provision bei erfolgreicher Zusammenarbeit oder wenn bestimmte Meilensteine erreicht werden, sodass unser Erfolg mit Ihrem Erfolg in Einklang steht.
    </li>
  </ul>
  <h3>Werbung und Promotion</h3>
  <ul>
    <li><strong>Keine Werbegebühren für Kreative:</strong> Kreative werden nie für Sichtbarkeit oder den Zugang zu Agenturen belastet. Hervorgehobene Einträge sind deutlich gekennzeichnet.</li>
    <li><strong>Affiliate-Partnerschaften:</strong> Wir kooperieren mit Drittanbietern, die Ihrer Karriere einen Mehrwert bieten. Alle Affiliate-Promotionen werden vollständig offengelegt.</li>
    <li><strong>Gesponserte Einträge:</strong> Agenturen können ihre Einträge für eine verbesserte Sichtbarkeit sponsern, jedoch sind diese stets als „Gesponsert“ gekennzeichnet und beeinflussen nicht unsere Qualitätskontrollprozesse.</li>
  </ul>
  <h3>Datenmanagement</h3>
  <ul>
    <li>
      <strong>Datenerfassung:</strong> Wir erfassen nur die Informationen, die notwendig sind, um Kreative mit Agenturen zusammenzubringen und unsere Plattform zu verbessern, wie z.B. Ihren Namen, Ihre E-Mail-Adresse und Ihre Präferenzen.
    </li>
    <li>
      <strong>Datennutzung:</strong> Ihre Daten werden ausschließlich für personalisierte Vermittlung, Plattformverbesserungen und wichtige Mitteilungen verwendet.
    </li>
    <li>
      <strong>Datenschutz:</strong> Wir setzen robuste Sicherheitsmaßnahmen ein, einschließlich Verschlüsselung und regelmäßiger Audits, um Ihre Informationen zu schützen. Weitere Details entnehmen Sie bitte unserer Datenschutzerklärung.
    </li>
  </ul>
</section>

<section id="ethical-practices">
  <h2>5. Ethische Praktiken</h2>
  <h3>Compliance</h3>
  <ul>
    <li>
      <strong>Gesetzliche Standards:</strong> Alle Agenturen müssen die lokalen und internationalen Gesetze einhalten, einschließlich Arbeitsgesetze, faire Vertragspraktiken und Schutz des geistigen Eigentums.
    </li>
    <li>
      <strong>Datenschutz:</strong> Wir halten uns strikt an globale Datenschutzstandards, wie z.B. die DSGVO, um eine verantwortungsvolle Datenverarbeitung zu gewährleisten.
    </li>
    <li>
      <strong>Faire Geschäftspraktiken:</strong> Wir setzen eine Null-Toleranz-Politik gegenüber unethischen Praktiken durch, um sicherzustellen, dass alle Abläufe hohen ethischen Standards entsprechen.
    </li>
  </ul>
  <h3>Einbindung der Community</h3>
  <ul>
    <li><strong>Feedback-gesteuerte Entwicklung:</strong> Wir integrieren aktiv das Feedback von Kreativen, um unsere Plattform zu verbessern.</li>
    <li><strong>Bildungsinitiativen:</strong> Wir bieten kostenlose Workshops, Webinare und Leitfäden an, um Ihren Erfolg zu unterstützen.</li>
    <li><strong>Unterstützung aufstrebender Talente:</strong> Wir ermöglichen allen Kreativen, unabhängig von Erfahrung oder Followerzahl, den gleichen Zugang zu erstklassigen Agenturen.</li>
    <li><strong>Ethische Partnerschaften:</strong> Alle Partnerschaften werden geprüft, um sicherzustellen, dass sie unseren Werten von Fairness und Respekt entsprechen.
    </li>
  </ul>
  <h3>Umweltverantwortung</h3>
  <ul>
    <li><strong>Umweltfreundliche Hosting-Anbieter:</strong> Wir arbeiten mit Hosting-Anbietern zusammen, die erneuerbare Energien nutzen.</li>
    <li><strong>Papierlose Abläufe:</strong> Unsere Prozesse sind digital ausgerichtet, was den Papierverbrauch reduziert.</li>
    <li><strong>Nachhaltigkeitsinitiativen:</strong> Wir fördern umweltbewusste Praktiken in unserer gesamten Community.</li>
  </ul>
</section>

<section id="updates">
  <h2>6. Aktualisierungen und Überarbeitungen</h2>
  <p>
    Dieser Eintrag wird aktualisiert, sobald Änderungen in unseren Praktiken vorgenommen werden. Wir verfeinern kontinuierlich unsere Prozesse, um betriebliche Verbesserungen und regulatorische Aktualisierungen widerzuspiegeln.
  </p>
</section>

<section id="contact">
  <h2>7. Kontakt und Feedback</h2>
  <p>
    Wir schätzen offene Kommunikation und stehen jederzeit bereit, Ihre Fragen oder Bedenken bezüglich unserer Transparenzpraktiken zu beantworten.
  </p>
  <ul>
    <li><strong>E-Mail:</strong> <a href="mailto:ofice@match-mates.com">ofice@match-mates.com</a></li>
  </ul>
</section>

<section id="legal">
  <h2>8. Rechtliches und Dokumentation</h2>
  <ul>
    <li>
      <strong>Datenschutz und -sicherheit:</strong> Wir halten uns strikt an globale Datenschutzvorgaben, einschließlich der DSGVO. Ihre Daten werden verantwortungsvoll verarbeitet und geschützt.
    </li>
    <li>
      <strong>Geschäftsintegrität:</strong> Wir gewährleisten faire und transparente Abläufe in allen Bereichen unseres Betriebs.
    </li>
  </ul>
  <p>
    Für detailliertere Informationen zu unseren Richtlinien oder bei spezifischen Anfragen kontaktieren Sie bitte unser Support-Team über die oben angegebene E-Mail-Adresse.
  </p>
</section>

<footer>
  <p>&copy; [2024] MatchMates. Alle Rechte vorbehalten.</p>
</footer>
    `
  };

  return (
    <LegalTemplate
      title="Transparency Policy"
      content={content[language]}
    />
  );
}
