import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AboutHero } from '../components/about/AboutHero';
import { AboutMission } from '../components/about/AboutMission';
import { AboutTeam } from '../components/about/AboutTeam';
import { AboutValues } from '../components/about/AboutValues';
import { backgrounds } from '../constants/backgrounds';
import { useTranslation } from '../hooks/useTranslation';
import { Helmet } from 'react-helmet-async';

export default function AboutPage() {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{t.nav.aboutUs} | MatchMates</title>
        <meta name="description" content={t.about.hero.subtitle} />
      </Helmet>

      <Header />
      <main>
        <section className={backgrounds.hero}>
          <AboutHero />
        </section>
        <section className={backgrounds.features}>
          <AboutMission />
        </section>
        <section className={backgrounds.testimonials}>
          <AboutValues />
        </section>
        <section className={backgrounds.detailedTestimonials}>
          <AboutTeam />
        </section>
      </main>
      <Footer />
    </div>
  );
}