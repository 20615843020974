import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Loader2 } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { SEOBlogPost } from '../components/blog/SEOBlogPost';
import { RelatedPosts } from '../components/blog/RelatedPosts';
import { backgrounds } from '../constants/backgrounds';
import { supabase } from '../lib/supabase';
import { incrementPostViews } from '../lib/blog';
import type { BlogPost } from '../components/blog/types';
import { BlogTemplate } from '../components/blog/BlogTemplate';

export default function BlogPostPage() {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      console.log('Loading blog post with slug:', slug);
      loadPost();
    }
  }, [slug]);

  async function loadPost() {
    try {
      setLoading(true);
      setError(null);

      console.log('Fetching post data from Supabase...');
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .eq('published', true)
        .single();

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      if (!data) {
        console.log('No post found with slug:', slug);
        throw new Error('Post not found');
      }

      console.log('Post found:', data.title);
      setPost(data);

      // Load related posts
      const { data: related, error: relatedError } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('published', true)
        .neq('id', data.id)
        .eq('category', data.category)
        .limit(3);

      if (relatedError) {
        console.error('Error loading related posts:', relatedError);
      } else {
        setRelatedPosts(related || []);
      }

      // Increment view count after successful load
      if (data.id) {
        await incrementPostViews(data.id);
      }
    } catch (error) {
      console.error('Error in loadPost:', error);
      setError(error instanceof Error ? error.message : 'Failed to load blog post');
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className={`${backgrounds.hero} flex-grow pt-32 pb-16`}>
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto flex items-center justify-center pt-12">
              <Loader2 className="w-8 h-8 animate-spin text-white" />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className={`${backgrounds.hero} flex-grow pt-32 pb-16`}>
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-3xl font-bold text-white mb-4">Post Not Found</h1>
              <p className="text-white/80 mb-8">The blog post you're looking for doesn't exist or has been removed.</p>
              <button
                onClick={() => navigate('/blog')}
                className="inline-flex items-center text-white hover:text-white/80"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Back to Blog
              </button>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <BlogTemplate
        title={post.title}
        content={post.content}
        date={new Date(post.created_at).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}
        readTime={post.read_time}
        category={post.category}
      >
        <SEOBlogPost post={post}>
        {relatedPosts.length > 0 && (
          <RelatedPosts currentPost={post} posts={relatedPosts} />
        )}
        </SEOBlogPost>
      </BlogTemplate>
      <Footer />
    </div>
  );
}