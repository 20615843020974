import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import { AgencyHero } from '../components/agencies/AgencyHero';
import { AgencyQualifications } from '../components/agencies/AgencyQualifications';
import { AgencyProcess } from '../components/agencies/AgencyProcess';
import { AgencyBenefits } from '../components/agencies/AgencyBenefits';
import { AgencyApplication } from '../components/agencies/AgencyApplication';
import Footer from '../components/Footer';
import { backgrounds } from '../constants/backgrounds';
import { useTranslation } from '../hooks/useTranslation';

export default function AgenciesPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t.agencies.hero.title} | MatchMates</title>
        <meta name="description" content={t.agencies.hero.subtitle} />
        
        {/* Open Graph */}
        <meta property="og:title" content={`${t.agencies.hero.title} | MatchMates`} />
        <meta property="og:description" content={t.agencies.hero.subtitle} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://imgur.com/wPK4GUs.png" />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        <main>
          {/* Hero Section */}
          <section className={backgrounds.hero}>
            <AgencyHero />
          </section>

          {/* Qualifications Section */}
          <section className="relative py-24 overflow-hidden">
            {/* Background with gradient overlay */}
            <div className="absolute inset-0 w-full h-full">
              <div className="relative h-full w-full overflow-hidden">
                <img 
                  src="https://imgur.com/wPK4GUs.png" 
                  alt=""
                  className="w-full h-full object-cover opacity-25"
                  loading="eager"
                  style={{ 
                    objectPosition: 'center',
                    transform: 'translateZ(0)',
                    willChange: 'transform'
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-br from-[#003366] to-[#FFC0CB] opacity-50" />
              </div>
            </div>
            <div className="relative z-10">
              <AgencyQualifications />
            </div>
          </section>

          {/* Process Section */}
          <section className={`${backgrounds.process} relative`}>
            <AgencyProcess />
          </section>

          {/* Benefits Section */}
          <section className="relative py-24 overflow-hidden">
            {/* Background with gradient overlay */}
            <div className="absolute inset-0 w-full h-full">
              <div className="relative h-full w-full overflow-hidden">
                <img 
                  src="https://imgur.com/L6sdryU.png" 
                  alt=""
                  className="w-full h-full object-cover opacity-25"
                  loading="eager"
                  style={{ 
                    objectPosition: 'center',
                    transform: 'translateZ(0)',
                    willChange: 'transform'
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-br from-[#003366] to-[#FFC0CB] opacity-50" />
              </div>
            </div>
            <div className="relative z-10">
              <AgencyBenefits />
            </div>
          </section>

          {/* Application Section */}
          <section className={backgrounds.detailedTestimonials}>
            <AgencyApplication />
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}