import { Language } from '../../translations';

export function getLanguagePath(language: Language): string {
  return language === 'de' ? '/de' : '';
}

export function getLanguageFromPath(pathname: string): Language {
  return pathname.startsWith('/de/') || pathname === '/de' ? 'de' : 'en';
}

export function removeLanguageFromPath(pathname: string): string {
  return pathname.replace(/^\/de/, '');
}

export function addLanguageToPath(pathname: string, language: Language): string {
  const cleanPath = removeLanguageFromPath(pathname);
  return `${getLanguagePath(language)}${cleanPath}`;
}