import React, { useState, useEffect } from 'react';
import { supabase } from '../../../lib/supabase';

export function IPExclusion() {
  const [excludedIPs, setExcludedIPs] = useState<string[]>([]);
  const [newIP, setNewIP] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    loadExcludedIPs();
  }, []);

  async function loadExcludedIPs() {
    const { data, error } = await supabase
      .from('excluded_ips')
      .select('ip_address');
    
    if (!error && data) {
      setExcludedIPs(data.map(item => item.ip_address));
    }
  }

  async function addIP(e: React.FormEvent) {
    e.preventDefault();
    setError('');

    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (!ipRegex.test(newIP)) {
      setError('Please enter a valid IP address');
      return;
    }

    const { error } = await supabase
      .from('excluded_ips')
      .insert([{ ip_address: newIP }]);

    if (error) {
      setError('Failed to add IP address');
      return;
    }

    setExcludedIPs([...excludedIPs, newIP]);
    setNewIP('');
  }

  async function removeIP(ip: string) {
    const { error } = await supabase
      .from('excluded_ips')
      .delete()
      .eq('ip_address', ip);

    if (!error) {
      setExcludedIPs(excludedIPs.filter(item => item !== ip));
    }
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-semibold mb-4">IP Address Exclusion</h3>

      <form onSubmit={addIP} className="mb-6">
        <div className="flex space-x-4">
          <input
            type="text"
            value={newIP}
            onChange={(e) => setNewIP(e.target.value)}
            placeholder="Enter IP address"
            className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            Add IP
          </button>
        </div>
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </form>

      <div className="space-y-2">
        {excludedIPs.map(ip => (
          <div key={ip} className="flex justify-between items-center p-3 bg-gray-50 rounded-md">
            <span>{ip}</span>
            <button
              onClick={() => removeIP(ip)}
              className="text-red-600 hover:text-red-800"
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}