import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import type { BlogPost } from './types';

interface SEOBlogPostProps {
  post: BlogPost;
  children: React.ReactNode;
}

export function SEOBlogPost({ post, children }: SEOBlogPostProps) {
  const location = useLocation();
  const canonicalUrl = `https://match-mates.com${location.pathname}`;
  
  // Prepare structured data
  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: post.title,
    description: post.excerpt,
    image: post.image_url,
    datePublished: post.published_at || post.created_at,
    dateModified: post.updated_at,
    author: {
      '@type': 'Organization',
      name: 'MatchMates',
      url: 'https://match-mates.com'
    },
    publisher: {
      '@type': 'Organization',
      name: 'MatchMates',
      logo: {
        '@type': 'ImageObject',
        url: 'https://i.imgur.com/OqWYju6.png'
      }
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': canonicalUrl
    }
  };

  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{post.meta_title || post.title} | MatchMates Blog</title>
        <meta name="description" content={post.meta_description || post.excerpt} />
        <meta name="keywords" content={post.meta_keywords} />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content={post.og_title || post.title} />
        <meta property="og:description" content={post.og_description || post.excerpt} />
        <meta property="og:image" content={post.og_image || post.image_url} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:image" content={post.image_url} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>

      <article className="prose prose-lg prose-slate max-w-none">
        <h1 className="text-4xl font-bold mb-6">{post.title}</h1>
        
        {/* Article metadata */}
        <div className="flex items-center space-x-4 text-gray-600 mb-8">
          <time dateTime={post.published_at || post.created_at}>
            {new Date(post.published_at || post.created_at).toLocaleDateString()}
          </time>
          <span>•</span>
          <span>{post.read_time} min read</span>
          {post.category && (
            <>
              <span>•</span>
              <span>{post.category}</span>
            </>
          )}
        </div>

        {/* Featured image with lazy loading */}
        {post.image_url && (
          <figure className="my-8">
            <img
              src={post.image_url}
              alt={post.title}
              className="w-full h-auto rounded-xl"
              loading="lazy"
              width="1200"
              height="630"
            />
          </figure>
        )}

        {/* Article content */}
        <div className="article-content">
          {children}
        </div>
      </article>
    </>
  );
}