import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, AlertCircle } from 'lucide-react';
import { audioManager } from '../../lib/audio/audioManager';

interface AudioPlayerProps {
  audioUrl: string;
}

export function AudioPlayer({ audioUrl }: AudioPlayerProps) {
  // Only render if there's an audio URL
  if (!audioUrl) return null;

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const playPromiseRef = useRef<Promise<void> | null>(null);

  // Reset audio player when URL changes
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
      setIsPlaying(false);
      setCurrentTime(0);
      setIsLoading(true);
      setError(null);
    }
  }, [audioUrl]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const setAudioData = () => {
      setDuration(audio.duration);
      setIsLoading(false);
      setError(null);
    };

    const setAudioTime = () => setCurrentTime(audio.currentTime);
    
    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
      if (audio) audio.currentTime = 0;
      audioManager.setPlaying(null);
    };

    const handleError = () => {
      setIsLoading(false);
      setIsPlaying(false);
      setError('Failed to load audio file');
      audioManager.setPlaying(null);
    };

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, [audioUrl]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current && isPlaying) {
        audioRef.current.pause();
        audioManager.setPlaying(null);
      }
    };
  }, [isPlaying]);

  const togglePlay = async () => {
    if (!audioRef.current || isLoading) return;

    try {
      if (isPlaying) {
        if (playPromiseRef.current) {
          await playPromiseRef.current;
        }
        audioRef.current.pause();
        audioManager.setPlaying(null);
        setIsPlaying(false);
      } else {
        audioManager.setPlaying(audioRef.current);
        playPromiseRef.current = audioRef.current.play();
        await playPromiseRef.current;
        setIsPlaying(true);
      }
    } catch (err) {
      setError('Failed to play audio');
      setIsPlaying(false);
      audioManager.setPlaying(null);
    } finally {
      playPromiseRef.current = null;
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const progress = (currentTime / duration) * 100 || 0;

  if (error) {
    return (
      <div className="w-full bg-red-50/10 backdrop-blur-md rounded-xl p-4 border border-red-500/20">
        <div className="flex items-center text-red-400 space-x-2">
          <AlertCircle className="w-5 h-5" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full bg-white/5 backdrop-blur-md rounded-xl p-4 hover:bg-white/10 transition-all">
      <audio ref={audioRef} preload="auto">
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <div className="flex items-center space-x-4">
        <button
          onClick={togglePlay}
          disabled={isLoading}
          className={`group relative w-14 h-14 rounded-full flex items-center justify-center transition-all duration-300 ${
            isPlaying 
              ? 'bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] animate-pulse' 
              : 'bg-white/10 hover:bg-white/20'
          }`}
        >
          {/* Gradient glow effect */}
          <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0] opacity-0 group-hover:opacity-20 transition-opacity blur-md" />
          
          {/* Icon */}
          {isPlaying ? (
            <Pause className="w-6 h-6 text-white relative z-10" />
          ) : (
            <Play className="w-6 h-6 text-white relative z-10" />
          )}
        </button>
        
        <div className="flex-1 space-y-2">
          <div className={`text-white font-medium transition-opacity ${
            isPlaying ? 'opacity-100' : 'opacity-80'
          }`}>
            Play Memo
          </div>
          
          <div className="h-1.5 bg-white/10 rounded-full overflow-hidden">
            <div 
              className={`h-full transition-all duration-100 ${
                isPlaying 
                  ? 'bg-gradient-to-r from-[#FFC0CB] to-[#FFADC0]' 
                  : 'bg-white/20'
              }`}
              style={{ width: `${progress}%` }}
            />
          </div>
          
          <div className="flex justify-between text-sm text-white/60">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}