import { TESTIMONIAL_IMAGES } from '../../constants/testimonialImages';

export interface CarouselTestimonial {
  name: string;
  image: string;
  achievementKey: string;
}

export const carouselTestimonials: CarouselTestimonial[] = [
  {
    name: 'Emily',
    image: TESTIMONIAL_IMAGES.LAURA,
    achievementKey: 'emily'
  },
  {
    name: 'Hannah',
    image: TESTIMONIAL_IMAGES.ANNA,
    achievementKey: 'hannah'
  },
  {
    name: 'Anna',
    image: TESTIMONIAL_IMAGES.ANNA,
    achievementKey: 'anna'
  },
  {
    name: 'Sarah',
    image: TESTIMONIAL_IMAGES.LAURA,
    achievementKey: 'sarah'
  },
  {
    name: 'Laura',
    image: TESTIMONIAL_IMAGES.LAURA,
    achievementKey: 'laura'
  },
  {
    name: 'Julia',
    image: TESTIMONIAL_IMAGES.SOPHIA,
    achievementKey: 'julia'
  }
];