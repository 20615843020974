import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface DailyVisitorsProps {
  data: any[];
  timeRange: string;
}

export function DailyVisitors({ data, timeRange }: DailyVisitorsProps) {
  // Group sessions by day
  const dailyData = data?.reduce((acc, session) => {
    const date = new Date(session.start_time).toLocaleDateString();
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.entries(dailyData || {}).map(([date, count]) => ({
    date,
    visitors: count
  }));

  // Calculate total visitors
  const totalVisitors = chartData.reduce((sum, day) => sum + day.visitors, 0);
  const averageVisitors = Math.round(totalVisitors / (chartData.length || 1));

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-semibold">Nutzer Statistiken</h3>
        <div className="flex space-x-6">
          <div>
            <p className="text-sm text-gray-500">Gesamt Besucher</p>
            <p className="text-2xl font-bold text-indigo-600">{totalVisitors}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Durchschnitt pro Tag</p>
            <p className="text-2xl font-bold text-indigo-600">{averageVisitors}</p>
          </div>
        </div>
      </div>
      
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="visitors" 
              stroke="#8884d8" 
              name="Besucher"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}