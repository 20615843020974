import React from 'react';
import { FormField } from './FormField';
import type { SEOSettings } from './types';

interface OpenGraphSectionProps {
  settings: SEOSettings;
  onChange: (field: keyof SEOSettings, value: string) => void;
}

export function OpenGraphSection({ settings, onChange }: OpenGraphSectionProps) {
  return (
    <section className="space-y-4">
      <h3 className="text-lg font-semibold">Open Graph (Facebook/LinkedIn)</h3>
      
      <FormField
        label="OG Title"
        value={settings.og_title}
        onChange={(value) => onChange('og_title', value)}
      />

      <FormField
        label="OG Description"
        value={settings.og_description}
        onChange={(value) => onChange('og_description', value)}
        multiline
      />

      <FormField
        label="OG Image URL"
        value={settings.og_image}
        onChange={(value) => onChange('og_image', value)}
        type="url"
      />
    </section>
  );
}