import { useState, useEffect } from 'react';
import { cacheManager } from '../cacheManager';

export function useCachedImage(src: string) {
  const [loaded, setLoaded] = useState(false);
  const cacheKey = `img_${src}`;

  useEffect(() => {
    const cachedImage = cacheManager.get<string>(cacheKey);
    
    if (cachedImage) {
      setLoaded(true);
      return;
    }

    const img = new Image();
    img.onload = () => {
      cacheManager.set(cacheKey, src, 30 * 60 * 1000); // Cache for 30 minutes
      setLoaded(true);
    };
    img.src = src;
  }, [src]);

  return loaded;
}