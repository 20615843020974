import { supabase } from './supabase';

let googleAdsId: string | null = null;

export async function initializeGoogleTag() {
  try {
    // First check if we already have the ID cached
    if (googleAdsId) {
      return googleAdsId;
    }

    // Try to load from site settings
    const { data, error } = await supabase
      .from('site_settings')
      .select('google_tag_id')
      .single();

    if (error) {
      // Log warning but don't throw error
      console.warn('Unable to load Google Ads ID:', error);
      return null;
    }
    
    if (data?.google_tag_id) {
      googleAdsId = data.google_tag_id;
      window.googleAdsId = googleAdsId;
      
      // Initialize gtag with the loaded ID
      if (window.gtag) {
        window.gtag('js', new Date());
        window.gtag('config', googleAdsId, {
          send_page_view: true
        });
      }

      return googleAdsId;
    }

    return null;
  } catch (error) {
    // Log warning but don't throw error
    console.warn('Error loading Google Ads ID:', error);
    return null;
  }
}

// Page view tracking helper
export function trackPageView(path: string) {
  if (window.gtag && window.googleAdsId) {
    window.gtag('config', window.googleAdsId, {
      page_path: path
    });
  }
}

// Conversion tracking helper with proper configuration
export function trackConversion(conversionId: string, callback?: () => void) {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: conversionId,
      event_callback: callback,
      value: 1,
      currency: 'EUR',
      transaction_id: Date.now().toString()
    });
  } else {
    console.warn('Google Ads tracking is not available');
    callback?.();
  }
}

// Add type definitions
declare global {
  interface Window {
    googleAdsId?: string;
    dataLayer?: any[];
    gtag?: (
      command: string,
      action: string,
      params?: {
        send_to?: string;
        value?: number;
        currency?: string;
        page_path?: string;
        send_page_view?: boolean;
        event_callback?: () => void;
        transaction_id?: string;
      }
    ) => void;
  }
}