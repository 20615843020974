import { FunnelPath } from '../../../types/funnel';
import { existingCreatorPath } from './existingCreatorPath';
import { newCreatorPath } from './newCreatorPath';

export { existingCreatorPath, newCreatorPath };

export const commonFinalSteps: FunnelPath = {
  id: 'common',
  title: 'Final Steps',
  steps: [
    {
      id: 'content_boundaries',
      type: 'choice',
      title: 'How do you feel about creating explicit content?',
      subtitle: 'Your boundaries matter! We\'ll find a solution that respects them.',
      question: 'Select your comfort level:',
      options: [
        { label: 'I enjoy creating this type of content', value: 'comfortable' },
        { label: 'I\'m unsure, but I might be open to it', value: 'unsure' },
        { label: 'I prefer to focus on lingerie and bikini photos', value: 'modest' }
      ]
    },
    {
      id: 'income_goals',
      type: 'choice',
      title: 'What\'s your income goal for OnlyFans?',
      subtitle: 'With the right strategy, there\'s no limit to what you can achieve!',
      question: 'Select your target monthly income:',
      options: [
        { label: '$1,000 – $5,000', value: '1k_5k' },
        { label: '$5,000 – $15,000', value: '5k_15k' },
        { label: '$15,000 – $50,000', value: '15k_50k' },
        { label: 'Over $50,000', value: 'over_50k' }
      ]
    },
    {
      id: 'contact',
      type: 'contact',
      title: 'What\'s the best way to contact you?',
      subtitle: 'Awesome, you\'re all set! Let\'s make your journey to success as smooth as possible. How can we reach you to discuss your personalized plan?',
      question: 'Share your contact details:',
      fields: [
        {
          name: 'name',
          type: 'text',
          label: 'Name',
          required: true
        },
        {
          name: 'contact_method',
          type: 'select',
          label: 'Select preferred contact method',
          options: [
            { label: 'WhatsApp', value: 'whatsapp' },
            { label: 'Telegram', value: 'telegram' },
            { label: 'iMessage', value: 'imessage' }
          ],
          required: true
        },
        {
          name: 'phone',
          type: 'tel',
          label: 'Phone Number',
          required: true
        },
        {
          name: 'telegram',
          type: 'text',
          label: 'Telegram Username',
          required: false,
          placeholder: '@username'
        }
      ]
    }
  ]
};